import SearchViewController from './boom-search-view-controller';

function SearchState($stateProvider) {

  $stateProvider.state('search', {
    parent: 'language',
    url: '/search?q',
    controller: SearchViewController,
    controllerAs: 'vm',
    templateUrl: 'views/search/search-view.html'
  });

}

SearchState.$inject = ['$stateProvider'];

export default SearchState;
