import RedirectController from './redirect-controller';

function AccountState($stateProvider) {
  $stateProvider.state('account', {
    parent: 'language',
    controller: RedirectController,
    controllerAs: 'vm',
    url: '/account',
    template: '<div></div>',
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });
}

function BoomProfileState($stateProvider) {
  $stateProvider.state('account.profile', {
    parent: 'language',
    url: '',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    params: {
      destinationState: 'home',
      destinationParams: null
    },
    segment: {
      doNotReport: true
    }
  });
}

function RegisterState($stateProvider) {
  $stateProvider.state('register', {
    parent: 'language',
    controller: RedirectController,
    abstract: true,
    controllerAs: 'vm',
    url: '?{pid:int}&code',
    template: '<div></div>',
    ageGate: {
      header: 'subscribe',
      fullscreen: false
    },
    params: {
      destinationState: 'home',
      destinationParams: null
    },
    segment: {
      doNotReport: true
    }
  });
}

function SignupState($stateProvider) {
  $stateProvider.state('signup', {
    url: '/signup',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    parent: 'register',
    params: {
      premiumLevel: null
    },
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });
}

function SubscribeState($stateProvider) {
  $stateProvider.state('subscribe', {
    url: '/subscribe',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    parent: 'register',
    userRequired: true,
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });
}

function UpsellState($stateProvider) {
  $stateProvider.state('upsell', {
    parent: 'language',
    controller: RedirectController,
    controllerAs: 'vm',
    url: '/premium',
    template: '<div></div>',
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });
}

function GiftState($stateProvider) {
  $stateProvider.state('gifting', {
    parent: 'language',
    url: '/gift',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    ignoreMobileWall: true,
    ageGate: {
      header: 'gift',
      fullscreen: false
    },
    segment: {
      doNotReport: true
    }
  });
}

function GiftPurchaseState($stateProvider) {

  $stateProvider.state('giftpurchase', {
    url: '/giftpurchase',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    params: {
      giftProduct: null
    },
    userRequired: false,
    ignoreMobileWall: true,
    ageGate: {
      header: 'gift',
      fullscreen: false
    },
    segment: {
      doNotReport: true
    }
  });

}

function MobileState($stateProvider) {
  $stateProvider.state('mobile', {
    parent: 'language',
    url: '/mobile',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    ignoreMobileWall: true,
    segment: {
      doNotReport: true
    }
  });
}

function ProductsState($stateProvider) {

  $stateProvider.state('products', {
    url: '/products',
    controller: RedirectController,
    controllerAs: 'vm',
    template: '<div></div>',
    params: {
      giftProduct: null
    },
    userRequired: false,
    ignoreMobileWall: true,
    ageGate: {
      header: 'gift',
      fullscreen: false
    },
    segment: {
      doNotReport: true
    }
  });
}

function SunsetState($stateProvider) {
  $stateProvider.state('sunset', {
    parent: 'language',
    controller: RedirectController,
    controllerAs: 'vm',
    url: '/sunset',
    template: '<div></div>',
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });
}

function ThatsAllFolksState($stateProvider) {
  $stateProvider.state('thatsallfolks', {
    parent: 'language',
    controller: RedirectController,
    controllerAs: 'vm',
    url: '/thatsallfolks',
    template: '<div></div>',
    segment: {
      doNotReport: true
    },
    ignoreMobileWall: true
  });
}

function RedirectedStates($stateProvider, $urlRouterProvider) {
  const redirectStateConfigs = [
    AccountState,
    BoomProfileState,
    RegisterState,
    SignupState,
    SubscribeState,
    UpsellState,
    GiftState,
    GiftPurchaseState,
    MobileState,
    ProductsState,
    SunsetState,
    ThatsAllFolksState
  ];

  redirectStateConfigs.forEach((state) => {
    state($stateProvider, $urlRouterProvider);
  });
}

RedirectedStates.$inject = ['$stateProvider', '$urlRouterProvider'];

export default RedirectedStates;
