import { resolve } from "path";

/**
 *  @ngdoc service
 *  @name Boom.Service:CollectionUpsellService
 *  @description
 *  @requires $rootScope
 *  @requires platformjs.claims
 *    Communicates between the views and modals to show appropriate modal
 */
class CollectionUpsellService {
  static get $inject() {
    return ['$rootScope', 'platformjs.claims', 'platformjs.collection', 'platformjs.premiumProfile', 'platformjs.user.profile', 'UrlLanguage'];
  }

  constructor($rootScope, ClaimsService, CollectionService, PremiumProfileService, UserProfileService, UrlLanguage) {
    this.$rootScope = $rootScope;
    this.ClaimsService = ClaimsService;
    this.CollectionService = CollectionService;
    this.PremiumProfileService = PremiumProfileService;
    this.UserProfileService = UserProfileService;
    this.UrlLanguage = UrlLanguage;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Service:CollectionUpsellService#$listenForUpsellTrigger
   *  @methodOf Boom.Service:CollectionUpsellService
   *  @description
   *    Listens for upsells and transmits the appropriate data, depending on the context of the upsell
   */
  listenForUpsellTrigger(collection) {
    this.$rootScope.$on('rootScope:upsellTriggered', (event, data) => {
      if (data && data.isFilm) {
        this.$rootScope.$emit('rootScope:openUpsell', data);
      } else if (collection) {
        this.$rootScope.$emit('rootScope:openUpsell', collection);
      } else {
        this.$rootScope.$emit('rootScope:openUpsell', data);
      }
    });
  }

  isEnglishActiveLanguage() {
    return this.UrlLanguage.activeLanguage === 'en';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Service:CollectionUpsellService#$checkEnableUpsell
   *  @methodOf Boom.Service:CollectionUpsellService
   *  @description
   *    Checks Upsell Flag
   */
  checkEnableUpsell() {
    const premiumClaim = this.ClaimsService.getClaim('premium');

    if (premiumClaim !== undefined) {
      return Promise.resolve(premiumClaim.enableUpsell);
    }

    return this.ClaimsService.get()
      .then(response => response.premium.enableUpsell);
  }

  checkForTrial() {
    return this.PremiumProfileService.get()
      .then(response => response.freeTrial.hadFreeTrial);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Service:CollectionUpsellService#$triggerUpsell
   *  @methodOf Boom.Service:CollectionUpsellService
   *  @description
   *    Triggers Upsell modal from banner
   */
  triggerUpsell(content) {
    this.$rootScope.$emit('rootScope:upsellTriggered', content);
  }

  createUpsell(collection) {
    this.$rootScope.$emit('rootScope:createUpsell', collection);
  }

  toggleBanner(data) {
    this.$rootScope.$emit('rootScope:closeBanner', data);
  }


  loadDefaultUpsell() {
    var config = {
      id: 'c247'
    };

    return this.CollectionService.get(config)
      .then(response => response);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Service:CollectionUpsellService#$generateImageUrl
   *  @methodOf Boom.Service:CollectionUpsellService
   *  @description
   *    Generates upsell modal and banner images from franchise slug of collections
   */
  generateImageUrl(franchiseSlug) {
    let parsedFranchise = '';
    if (franchiseSlug) {
      const franchise = franchiseSlug.substring(0, franchiseSlug.indexOf('-franchise'));
      let dashIndex = -1;
      for (let i = 0; i < franchise.length; i++) {
        let next = '';
        if (franchise[i] === '-') {
          dashIndex = i + 1;
        } else if (dashIndex === i) {
          next = franchise[i].toUpperCase();
        } else {
          next = franchise[i];
        }
        parsedFranchise += next;
      }
    }
    if (parsedFranchise.length < 1) {
      parsedFranchise = 'bugsBunny';
    }
    return parsedFranchise;
  }

}

export default CollectionUpsellService;
