import BoomUpsellBannerController from './boom-upsell-banner-controller';

const BoomUpsellBannerComponent = {
  bindings: {
    series: '<',
    episode: '<',
    franchise: '<',
    collection: '<',
    enabled: '='
  },
  controllerAs: 'vm',
  controller: BoomUpsellBannerController,
  templateUrl: 'components/boom-upsell-banner/boom-upsell-banner.html'
};
export default BoomUpsellBannerComponent;
