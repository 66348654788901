import VolumeNavigatorController from './volume-navigator-controller';

const VolumeNavigatorComponent = {
  controllerAs: 'vm',
  controller: VolumeNavigatorController,
  bindings: {
    seasons: '<',
    onSeasonChange: '&',
    activeSeasonNumber: '<',
    stateInfo: '<'
  },
  templateUrl: 'components/volume-navigator/volume-navigator.html',
  transclude: true
};

export default VolumeNavigatorComponent;
