import FranchiseController from './franchise-view-controller';
import FranchiseSubCollectionController from './franchise-sub-collection-view-controller';
import SeriesViewController from './series-view-controller';

function FranchiseState($stateProvider) {
  $stateProvider
    .state('franchise', {
      parent: 'language',
      controller: FranchiseController,
      controllerAs: 'vm',
      url: '/shows/{slug}',
      templateUrl: 'views/franchise/franchise-view.html',
      params: {
        slug: {
          value: '',
          squash: true
        },
        volumeGuid: {
          value: null,
          squash: true
        }
      }
    })
    .state('franchise.series', {
      url: '/series/:collectionSlug/volume-:seasonNumber',
      controller: SeriesViewController,
      controllerAs: 'vm',
      templateUrl: 'views/franchise/series-view.html',
      data: {
        parentCollectionItems: [],
        parentCollection: []
      },
      segment: {
        skip: true
      }
    })
    .state('franchise.multiSeries', {
      url: '/series/:collectionSlug/:subFranchise/:seriesId/volume-:seasonNumber',
      controller: SeriesViewController,
      controllerAs: 'vm',
      templateUrl: 'views/franchise/series-view.html',
      data: {
        parentCollectionItems: [],
        parentCollection: []
      },
      segment: {
        skip: true
      }
    })
    .state('franchise.subcollection', {
      url: '/:collectionType/:collectionSlug/page-:page',
      controller: FranchiseSubCollectionController,
      controllerAs: 'vm',
      templateUrl: 'views/franchise/franchise-sub-collection.html',
      data: {
        parentCollectionItems: [],
        parentCollection: []
      },
      segment: {
        skip: true
      },
      params: {
        seriesId: {
          value: null,
          squash: true
        },
        seasonNumber: {
          value: 1,
          squash: true
        }
      }
    });
}

FranchiseState.$inject = ['$stateProvider'];

export default FranchiseState;
