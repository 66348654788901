/* Import Seed Components */

import BoomCarouselComponent from 'components/carousel/boom-carousel-component';
import ErrorListComponent from 'components/error-list/error-list-component';
import HistoryItemComponent from 'components/history-item/boom-history-item-component';
import LoginButtonComponent from 'components/login-button/login-button-component';
import LoginFormComponent from 'components/login-form/boom-login-form-component';
import CloseOverlayComponent from 'components/overlay/close-overlay-component';
import OpenOverlayComponent from 'components/overlay/open-overlay-component';
import BoomPaginationComponent from 'components/pagination/boom-pagination-component';
import ProductSelectionComponent from 'components/product-selection/boom-product-selection-component';
import SearchOverlayComponent from 'components/search-overlay/search-overlay-component';
import ThumbnailComponent from 'components/thumbnail/thumbnail-component';
import WatchMastheadComponent from 'components/watch-masthead/boom-watch-masthead-component';

/* Import Custom Components */

import BoomBrowseContentComponent from 'components/boom-browse-content/boom-browse-content-component';
import BoomLogoutButtonComponent from 'components/boom-logout-button/boom-logout-button-component';
import BoomSubCollectionButtonsComponent from 'components/boom-sub-collection-buttons/boom-sub-collection-buttons-component';
import SwiftypeComponent from 'components/swiftype/swiftype-component';
import SwiftypeAutocompleteComponent from 'components/swiftype/autocomplete/swiftype-autocomplete-component';
import SwiftypeThumbnailComponent from 'components/swiftype/thumbnail/swiftype-thumbnail-component';
import SwiftypeTopSearchComponent from 'components/swiftype/top-search/swiftype-top-search-component';
import BoomGiftingComponent from 'components/boom-gifting/boom-gifting-component';
import BoomRedemptionFormComponent from 'components/boom-redemption-form/boom-redemption-form-component';
import VolumeNavigatorComponent from 'components/volume-navigator/volume-navigator-component';
import VolumeDropdownComponent from 'components/volume-dropdown/volume-dropdown-component';
import AgreementModalService from 'components/tos-pp-banner/tos-pp-banner-component';
import OpenAppButtonComponent from 'components/open-app-button/open-app-button-component';
import ForgotPasswordFormComponent from 'components/boom-forgot-password-form/forgot-password-form-component';
import BoomErrorListComponent from 'components/boom-error-list/boom-error-list-component';
import BoomCollectionUpsellComponent from 'components/boom-collection-upsell/boom-collection-upsell-component';
import BoomUpsellBannerComponent from 'components/boom-upsell-banner/boom-upsell-banner-component';
import BoomUpsellModalComponent from 'components/boom-upsell-modal/boom-upsell-modal-component';
import BoomUpsellComponent from 'components/boom-upsell/boom-upsell-component';

/* Boom Components Components */
import MainHeaderComponent from '../bower_components/boom-components/src/components/main-header/main-header-component.module.js';
import LanguageSelectorComponent from '../bower_components/boom-components/src/components/language-selector/language-selector-component.module.js';
import MainFooterComponent from '../bower_components/boom-components/src/components/main-footer/main-footer-component.module.js';

const ComponentsModule = angular.module('boom-watch.components', [])

  /* Register Seed Components */
  .component('browseContent', BoomBrowseContentComponent)
  .component('carousel', BoomCarouselComponent)
  .component('errorList', ErrorListComponent)
  .component('forgotPasswordForm', ForgotPasswordFormComponent)
  .component('historyItem', HistoryItemComponent)
  .component('loginButton', LoginButtonComponent)
  .component('loginForm', LoginFormComponent)
  .component('closeOverlay', CloseOverlayComponent)
  .component('openOverlay', OpenOverlayComponent)
  .component('pagination', BoomPaginationComponent)
  .component('productSelection', ProductSelectionComponent)
  .component('searchOverlay', SearchOverlayComponent)
  .component('thumbnail', ThumbnailComponent)
  .component('watchMasthead', WatchMastheadComponent)

  /* Register Boom Components Components */
  .component('mainHeader', MainHeaderComponent)
  .component('languageSelector', LanguageSelectorComponent)
  .component('mainFooter', MainFooterComponent)

  /* Register Custom Components */
  .component('logoutButton', BoomLogoutButtonComponent)
  .component('subCollectionButtons', BoomSubCollectionButtonsComponent)
  .component('swiftype', SwiftypeComponent)
  .component('swiftypeAutocomplete', SwiftypeAutocompleteComponent)
  .component('swiftypeThumbnail', SwiftypeThumbnailComponent)
  .component('swiftypeTopSearch', SwiftypeTopSearchComponent)
  .component('gifting', BoomGiftingComponent)
  .component('redemptionForm', BoomRedemptionFormComponent)
  .component('volumeNavigator', VolumeNavigatorComponent)
  .component('volumeDropdown', VolumeDropdownComponent)
  .component('tosPpBanner', AgreementModalService)
  .component('openAppButton', OpenAppButtonComponent)
  .component('boomErrorList', BoomErrorListComponent)
  .component('collectionUpsell', BoomCollectionUpsellComponent)
  .component('upsellBanner', BoomUpsellBannerComponent)
  .component('upsellModal', BoomUpsellModalComponent)
  .component('upsell', BoomUpsellComponent);

export default ComponentsModule;
