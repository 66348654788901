/* Import Seed Providers */

import StateAuthenticationProvider from "components/state-authentication/state-authentication-provider";
import UrlLanguageProvider from "components/url-language/url-language-provider";

/* Import Custom Providers */

/* Import Seed Services */

import GoogleAnalyticsService from "services/google-analytics/google-analytics-service";
import OverlayService from "components/overlay/overlay-service";
import ProgressBarService from "components/progress-bar/progress-bar-service";
import StateAuthenticationService from "components/state-authentication/state-authentication-service";
import SubscribeConfigService from "components/subscribe-config/subscribe-config-service";
import UserAgentService from "services/user-agent/user-agent-service";
import AgreementModalService from "components/tos-pp-banner/tos-pp-banner-service";

/* Import Custom Services */
import SwiftypeService from "components/swiftype/swiftype-service";

import CollectionUpsellService from "services/boom-collection-upsell-service/boom-collection-upsell-service";
import SunsetCheckService from "services/sunset-check-service";

/* Import Custom Factories */
import PlaylistFactory from "factories/playlist-factory/playlist-factory";
import FranchiseFactory from "factories/franchise-factory/franchise-factory";

const ServicesModule = angular
  .module("boom-watch.services", [])
  /* Register Seed Providers */

  .provider("StateAuthentication", StateAuthenticationProvider)
  .provider("UrlLanguage", UrlLanguageProvider)

  /* Register Custom Providers */

  /* Register Seed Services */

  .service("GoogleAnalyticsService", GoogleAnalyticsService)
  .service("OverlayService", OverlayService)
  .service("ProgressBarService", ProgressBarService)
  .service("StateAuthenticationService", StateAuthenticationService)
  .service("SubscribeConfigService", SubscribeConfigService)
  .service("UserAgentService", UserAgentService)
  .service("SwiftypeService", SwiftypeService)
  .service("AgreementModalService", AgreementModalService)

  /* Register Custom Services */
  .service("CollectionUpsellService", CollectionUpsellService)
  .service("SunsetCheckService", SunsetCheckService)

  .service("detectjs", [
    "$window",
    ($window) => {
      return $window.detect;
    },
  ])

  /* Register Custom Factories */
  .factory("PlaylistFactory", PlaylistFactory)
  .factory("FranchiseFactory", FranchiseFactory);

export default ServicesModule;
