/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomCollectionUpsellController
*  @module Boom
*  @requires $rootScope
*  @requires UrlLanguage
*  @description
*    Responsible for subscribing a user.
*/
class BoomUpsellController {
  static get $inject() {
    return ['$http', '$rootScope', '$state', 'platformjs.collection', 'platformjs.series', 'CollectionUpsellService'];
  }

  /* istanbul ignore next */
  constructor($http, $rootScope, $state, CollectionService, SeriesService, CollectionUpsellService) {
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.CollectionService = CollectionService;
    this.SeriesService = SeriesService;
    this.CollectionUpsellService = CollectionUpsellService;

    this.isFilm = false;
    this.loading = true;
  }

  $onInit() {
    this.isEn = this.CollectionUpsellService.isEnglishActiveLanguage();

    this.CollectionUpsellService.loadDefaultUpsell()
      .then(response => {
        this.default = response;
      })
      .then(() => this.fetchUpsell());


    this.CollectionUpsellService.checkEnableUpsell()
      .then(response => {
        this.enableUpsell = response;
      });

    this.CollectionUpsellService.checkForTrial()
      .then(response => {
        this.hadFreeTrial = response;
      });

    this.getDefaultUpsellCollection();
  }

  getDefaultUpsellCollection() {
    return this.CollectionService.get({
      id: `c247`
    })
      .then(collection => this.defaultUpsellCollection = collection);
  }

  setModalImg() {
    let bannerImg = ``;

    if (this.collection) {
      bannerImg = this.collection.metadata.images.upsell_desktop_full.url;
      this.loading = false;
    } else {
      bannerImg = this.defaultUpsellCollection.metadata.images.upsell_desktop_full.url;
      this.loading = false;
    }

    return bannerImg;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#fetchDefaultBanner
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Generates a modal with the data or fetches default data and then creates modal
   */
  fetchDefaultBanner() {
    this.CollectionUpsellService.loadDefaultUpsell()
      .then(response => {
        this.default = response;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#generateModal
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Generates modal using return data nad current language
   */
  generateModal(data) {
    this.loading = true;
    if (data && data.isFilm || data && data.thumbnail_type === 'movies') {
      this.resetAttributes();
      this.imageUrl = data.images.box.url;
      this.upsellSubtitleLong = this.upsellSubtitleShort;
      this.upsellTitle = 'Watch this movie now!';
      this.isFilm = true;
      this.loading = false;

    } else if (data && data.metadata && data.metadata.upsellCta && data.slug) {
      this.imageUrl = this.CollectionUpsellService.generateImageUrl(data.slug);
      this.upsellTitle = (this.isEn) ? data.metadata.upsellTitle : data.metadata.upsellTitleEs;
      this.upsellCta = (this.isEn) ? data.metadata.upsellCta : data.metadata.upsellCtaEs;
      this.upsellPricing = (this.isEn) ? data.metadata.upsellPricing : data.metadata.upsellPricingEs;
      this.upsellSubtitleLong = (this.isEn) ? data.metadata.upsellSubtitleLong : data.metadata.upsellSubtitleLongEs;
      this.upsellCaption = (this.isEn) ? data.metadata.upsellCaption : data.metadata.upsellCaptionEs;
      this.isFilm = false;
      this.loading = false;

    } else {
      this.resetAttributes();
      this.loading = false;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#resetAttributes
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Resets to default attributes
   */

  resetAttributes() {
    let response = this.default;
    if (response) {
      this.upsellTitle = (this.isEn) ? response.metadata.upsellCaption : response.metadata.upsellCaptionEs;
      this.upsellCta = (this.isEn) ? response.metadata.upsellCta : response.metadata.upsellCtaEs;
      this.upsellPricing = (this.isEn) ? response.metadata.upsellPricing : response.metadata.upsellPricingEs;
      this.upsellSubtitleLong = (this.isEn) ? response.metadata.upsellSubtitleLong : response.metadata.upsellSubtitleLongEs;
      this.upsellSubtitleShort = (this.isEn) ? response.metadata.upsellSubtitleShort : response.metadata.upsellSubtitleShortEs;
      this.isFilm = false;
      this.upsellCaption = '';
      this.imageUrl = 'bugsBunny';
    }
  }

  fetchUpsell() {
    let seriesId = this.$state.params.seriesId;
    let episodeNumber = this.$state.params.episodeNumber;

    this.SeriesService.get(seriesId, episodeNumber, {}, false)
      .then(response => {
        if (response.isFilm) {
          this.loading = false;
          this.generateModal(response);

        } else {
          let franchiseId = response.metadata.reporting_franchise_id;
          this.CollectionService.get({
            id: 'c' + franchiseId
          })
            .then(response => {
              this.generateModal(response);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
  }
}

export default BoomUpsellController;
