import SwiftypeAutocompleteComponentController from './swiftype-autocomplete-controller';

const SwiftypeAutocompleteComponent = {
  controller: SwiftypeAutocompleteComponentController,
  bindings: {
    query: '=',
    minLength: '<',
    onSearch: '&',
    onReset: '&'
  },
  controllerAs: 'vm',
  templateUrl: 'components/swiftype/autocomplete/swiftype-autocomplete.html'
};

export default SwiftypeAutocompleteComponent;
