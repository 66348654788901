export default class RedirectController {
  static get $inject() {
    return ['$state', '$location', '$window', 'platform', 'platformjs.metadata', '$timeout'];
  }
  constructor($state, $location, $window, platform, MetaDataService, $timeout) {
    this.$location = $location;
    this.$window = $window;
    this.$timeout = $timeout;
    this.MetaDataService = MetaDataService;
    this.timeoutLength = 2000;

    const relativePath = $state.href($state.current.name, $state.params, {
      absolute: false
    });
    this.path = `${$location.protocol()}://${platform.secureDomain.url}${relativePath}`;
  }

  $onInit() {
    this.go()
    this.MetaDataService.setData({
      prerenderStatusCode: 301,
      prerenderHeader: `Location: ${this.path}`
    });
  }

  go($event) {
    //if the link on the page was clicked, prevent the default link behavior so we can do the redirect manually
    if ($event) {
      $event.preventDefault();
    }
    //manually redirec the user because we don't want this page to end up in the browser's history
    this.$window.location.replace(this.path);
  }
}
