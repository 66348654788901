/**
 *  @ngdoc controller
 *  @name Boom.Controllers:SearchViewController
 *  @module Boom
 *  @description
 *    Responsible for displaying Search View.
 */
class SearchViewController {

  static get $inject() {
    return ['$stateParams', 'platform', 'UrlLanguage', 'CollectionUpsellService'];
  }

  constructor($stateParams, platform, UrlLanguage, CollectionUpsellService) {

    this.$stateParams = $stateParams;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Boom.Controllers:SearchViewController
     *  @returns {array} Error list
     *  @description
     *    List of current errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name loading
     *  @propertyOf Boom.Controllers:SearchViewController
     *  @returns {boolean} Current loading state
     *  @description
     *    Current loading state
     */
    this.loading = false;

    /**
     *  @ngdoc property
     *  @name swiftypeConfig
     *  @propertyOf Boom.Controllers:SearchViewController
     *  @returns {object} swiptType Configuration
     *  @description
     *    Current swiptType Configuration
     */
    this.swiftypeConfig = {
      engine_key: platform.swiftype.engineKey,
      document_types: ['episode', 'movies'],
      min_length: 2,
      filters: {
        movies: {
          is_episodic: 'False',
          approved: 'True'
        },
        episode: {
          is_approved: 'True',
          series_is_approved: 'True',
          series_type: 'drama'
        }
      }
    };

    this.UrlLanguage = UrlLanguage;
    this.CollectionUpsellService = CollectionUpsellService;

  }

  $onInit() {

    this.query = this.$stateParams.q;
    this.CollectionUpsellService.listenForUpsellTrigger();

  }
}

export default SearchViewController;
