import Error404ViewController from 'views/404/error-404-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:Error404View
 *  @module Boom
 *  @requires platformjs.metadata
 *  @description
 *    Responsible for managing the 404 page error view.
 */
class BoomError404ViewController extends Error404ViewController {
  static get $inject() {
    return [
      'platformjs.metadata'
    ]
  }
  constructor(MetaDataService) {
    super();

    /* istanbul ignore next */
    this.MetaDataService = MetaDataService;
  }

  $onInit() {
    this.MetaDataService.setData({
      title: 'File Not Found',
      prerenderStatusCode: 404
    })
  }
}

export default BoomError404ViewController;
