/**
 *  @ngdoc controller
 *  @name Seed.Controllers:BrowseContent
 *  @module Seed
 *  @requires platformjs.collection
 *  @requires platformjs.collections2
 *  @description
 *    Component that can load collection items or a list of collections
 */
class BrowseContentController {
  static get $inject() {
    return ['platformjs.collection', 'platformjs.collections2'];
  }

  constructor(CollectionService, CollectionsFactory) {
    this.CollectionService = CollectionService;
    this.CollectionsFactory = CollectionsFactory;
    /**
     *  @ngdoc property
     *  @name content
     *  @propertyOf Seed.Controllers:BrowseContent
     *  @description
     *  @returns {object} Currently displayed content
     */
    this.content = null;

    /**
     *  @ngdoc property
     *  @name page
     *  @propertyOf Seed.Controllers:BrowseContent
     *  @description
     *  @returns {integer} Current page
     */
    this.page = this.page || 1;

    this.onStart = this.onStart || angular.noop;
    this.onError = this.onError || angular.noop;
    this.onSuccess = this.onSuccess || angular.noop;
    this.onComplete = this.onComplete || angular.noop;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#$onChanges
   *  @methodOf Seed.Controllers:BrowseContent
   *  @description
   *    Called on change of binded variables. Loads data depending on values of variables.
   */
  $onChanges() {

    this.onStart();

    this.content = null;

    this.loadContent();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#changePage
   *  @methodOf Seed.Controllers:BrowseContent
   *  @description
   *    Sets {@link Seed.Controllers:BrowseContent#page page} to new page and reloads queue.
   */
  changePage(page) {

    this.page = page;
    this.loadContent();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#$onChanges
   *  @methodOf Seed.Controllers:BrowseContent
   *  @param {string} type Content type
   *  @description
   *    Returns the correct method for loading data determined by passed type.
   */
  load(type) {

    if (type === 'list') {
      return this.loadList();
    } else {
      return this.loadItems();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#loadContent
   *  @methodOf Seed.Controllers:BrowseContent
   *  @description
   *    Loads data to display within browse content
   */
  loadContent() {
    this.load(this.type)
      .then((content) => this.showLoadSuccess(content))
      .catch((errors) => this.showLoadErrors(errors))
      .finally(() => this.showLoadComplete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#loadList
   *  @methodOf Seed.Controllers:BrowseContent
   *  @description
   *    Calls `CollectionsFactory.list`.
   */
  loadList() {

    var params = {
      tag: 'type',
      value: this.slug
    };

    return this.CollectionsFactory(params).list({
      page: this.page
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#loadItems
   *  @methodOf Seed.Controllers:BrowseContent
   *  @description
   *    Calls `CollectionService.getItems`.
   */
  loadItems() {

    var params = {
      page: this.page,
      slug: this.slug
    };

    return this.CollectionService.getItems(params);

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#showLoadComplete
   *  @methodOf Seed.Controllers:BrowseContent
   *  @description
   *    Triggers `onComplete`
   */
  showLoadComplete() {

    this.onComplete();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#showLoadComplete
   *  @methodOf Seed.Controllers:BrowseContent
   *  @param {array} errors An array of errors.
   *  @description
   *    Triggers `onError` and passes errors.
   */
  showLoadErrors(errors) {

    this.onError({
      errors: errors
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:BrowseContent#showLoadComplete
   *  @methodOf Seed.Controllers:BrowseContent
   *  @param {object} object Content object
   *  @description
   *    Triggers `onSuccess` and passes content.
   */
  showLoadSuccess(content) {

    // normalize items location
    content.items = content.collections || content.collectionItems;
    this.content = content;

    this.onSuccess({
      content: content
    });

  }
}



export default BrowseContentController;
