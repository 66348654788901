import CollectionViewController from 'views/collection/collection-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:PlaylistView
 *  @module Boom
 *  @requires angular.$stateParams
 *  @requires angular.$state
 *  @requires platformjs.collection
 *  @requires ProgressBarService
 *  @requires platformjs.metadata
 *  @requires PlaylistFactory
 *  @description
 *    Responsible for displaying playlist information.
 */
class PlaylistViewController extends CollectionViewController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      '$stateParams',
      '$state',
      'platformjs.collection',
      'ProgressBarService',
      'platformjs.metadata',
      'CollectionUpsellService'
    ];
  }

  /* istanbul ignore next */
  constructor(
    $stateParams,
    $state,
    CollectionService,
    ProgressBarService,
    MetadataService,
    CollectionUpsellService
  ) {

    super($stateParams,
      $state,
      CollectionService,
      ProgressBarService);

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.CollectionService = CollectionService;
    this.ProgressBarService = ProgressBarService;
    this.MetadataService = MetadataService;
    this.CollectionUpsellService = CollectionUpsellService;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#$onInit
   *  @methodOf Boom.Controllers:PlaylistView
   *  @description
   *    Makes initial request for a collection object to display.
   */
  $onInit() {

    this.loading = true;

    this.page = this.$stateParams.page || 1;

    this.logoURL = '';

    this.bgImageStyleObject = {};

    this.ProgressBarService.start();
    this.CollectionUpsellService.checkEnableUpsell().then(response => {
      this.upsell = response;

      this.loadCollection('playlist', this.$stateParams.slug)
        .then(collection => this.showCollectionSuccess(collection))
        .catch(errors => this.showCollectionFailure(errors))
        .finally(() => this.ProgressBarService.complete());

      this.loadItems()
        .then(response => this.loadItemsSuccess(response))
        .catch(errors => this.loadItemsFailure(errors))
        .finally(() => this.loadItemsComplete());
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#loadItems
   *  @methodOf Boom.Controllers:PlaylistView
   *  @description
   *    Calls `CollectionService.getItems`.
   */
  loadItems() {

    let params = {
      page: this.page,
      slug: this.$stateParams.slug
    };

    this.loading = true;

    return this.CollectionService.getItems(params);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#loadItemsSuccess
   *  @methodOf Boom.Controllers:PlaylistView
   *  @description
   *    Render collection items, update pagination info.
   */
  loadItemsSuccess(response) {

    this.page = response.page;
    this.pages = response.numberOfPages;
    this.episodes = this.injectCollectionSlug(response.collectionItems);

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#loadItemsFailure
   *  @methodOf Boom.Controllers:PlaylistView
   *  @description
   *    Can not find collection items, display error messages.
   */
  loadItemsFailure(errors) {

    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#loadItemsComplete
   *  @methodOf Boom.Controllers:PlaylistView
   *  @description
   *    Requests complete, render on the page.
   */
  loadItemsComplete() {
    this.loading = false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#changePage
   *  @methodOf Boom.Controllers:PlaylistView
   *  @description
   *    Requests the content by specified page number.
   */
  changePage(page) {
    this.page = page;
    this.loadItems()
      .finally(() => this.loadItemsComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#$injectCollectionSlug
   *  @methodOf Boom.Controllers:PlaylistView
   *  @param {array} episodes list
   *  @description
   *    Attach collection slug to the episode object.
   */
  injectCollectionSlug(episodes) {
    episodes.forEach((element) => {
      if (element && element.item) {
        element.item.slug = this.$stateParams.slug;
      }
    });
    return episodes;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistView#$showCollectionSuccess
   *  @methodOf Boom.Controllers:PlaylistView
   *  @param {object} collection Collection object
   *  @description
   *    Called on the success of the initial request for a collection.
   */
  showCollectionSuccess(collection) {

    /* istanbul ignore next */
    super.showCollection(collection);

    const urlBase = `https://imgix-media.wbdnbo.net/asset/collection/${collection.id}/${collection.assetKey}/`;

    this.logoURL = urlBase + 'logo.png';

    const heroURL = urlBase + 'hero.jpg';

    this.bgImageStyleObject = {
      'background-image': `url('${heroURL}')`
    };

    this.updateMetadata();
    this.CollectionUpsellService.listenForUpsellTrigger();
  }

  /*
  *  @ngdoc method
  *  @name Boom.Controllers:PlaylistView#updateMetadata
  *  @methodOf Boom.Controllers:PlaylistView
  *  @description
  *    Updates the metadata tags.
  */
  updateMetadata() {
    let description;
    let image;
    let title = this.collection.title;

    if (this.collection.metadata && this.collection.metadata.description && this.collection.metadata.description.long) {
      description = this.collection.metadata.description.long;
    } else {
      description = title;
    }

    if (this.collection.metadata && this.collection.metadata.images && this.collection.metadata.images.playlist) {
      image = this.collection.metadata.images.playlist.url;
    }

    title += ' | Boomerang.com';

    this.MetadataService.setData({
      title,
      image,
      description
    });

  }

}

export default PlaylistViewController;
