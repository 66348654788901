import { modifyUrlWithLocalization } from '../../localized-images';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:ThumbnailComponentController
 *  @module Boom
 *  @requires $state
 *  @requires UrlLanguage
 *  @requires CollectionUpsellService
 *  @requires platformjs.collection
 *  @requires platformjs.claims
 *  @requires platformjs.user.account
 *  @description
 *    Responsible for managing a thumbnail component
 */
export default class ThumbnailComponentController {
  static get $inject() {
    return ['$state', 'UrlLanguage', 'CollectionUpsellService', 'platformjs.collection', 'platformjs.claims', 'platformjs.user.account'];
  }
  constructor($state, UrlLanguage, CollectionUpsellService, CollectionService, ClaimsService, UserAccountService) {
    this.$state = $state;
    this.UrlLanguage = UrlLanguage;
    this.CollectionUpsellService = CollectionUpsellService;
    this.CollectionService = CollectionService;
    this.ClaimsService = ClaimsService;
    this.UserAccountService = UserAccountService;
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThumbnailComponentController#$onInit
   * @methodOf Boom.Controllers:ThumbnailComponentController
   * @description
   *   sets the doNotSell value
   */
  $onInit() {
    const premiumClaim = this.ClaimsService.getClaim('premium');
    this.doNotUpsell = premiumClaim.enableUpsell !== undefined && !premiumClaim.enableUpsell;
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThumbnailComponentController#parseSlug
   * @methodOf Boom.Controllers:ThumbnailComponentController
   * @description
   *   removes '-franchise' from the response
   */
  parseSlug(slug) {
    const franchiseTag = '-franchise';
    return slug.indexOf(franchiseTag) > -1 ? slug.substring(0, slug.indexOf(franchiseTag)) : '';
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThumbnailComponentController#goToSeries
   * @methodOf Boom.Controllers:ThumbnailComponentController
   * @description
   *   Goes to $state using franchise array
   */
  goToSeries(series) {
    if (series.metadata.reporting_franchise_id) {
      let id = 'c' + series.metadata.reporting_franchise_id;
      this.CollectionService.get({
        id: id
      })
        .then((response) => {
          this.slug = this.parseSlug(response.slug);
          this.$state.go('franchise.multiSeries', {
            slug: this.slug + '-franchise',
            collectionSlug: this.slug + '-series',
            seasonNumber: '1',
            subFranchise: series.slug,
            seriesId: series.id
          });
        })
        .catch(error => {
          this.$state.go('home');
        });
    } else {
      this.$state.go('home');
    }

  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThumbnailComponentController#getDuration
   * @methodOf Boom.Controllers:ThumbnailComponentController
   * @description
   *   Returns parsed content runtime
   */
  getDuration(duration) {
    let episodeDuration = '';
    if (duration) {
      const hours = parseInt(duration.substring(0, 2));
      const minutes = duration.substring(3, 5);
      const seconds = duration.substring(6, 8);

      if (hours > 0) {
        episodeDuration = `${hours}h `;
      }

      episodeDuration += `${minutes}m`;

      if (seconds != '00') {
        episodeDuration += ` ${seconds}s`;
      }
    }
    return episodeDuration;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ThumbnailComponentController#historyProgress
   *  @methodOf Boom.Controllers:ThumbnailComponentController
   *  @param {object} history The return object from the episode service
   *  @description
   *    Parse episode object for progress
   */
  historyProgress(history) {
    return history ? history.percentageWatched : 0;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ThumbnailComponentController#getLocalizedImage
   *  @methodOf Boom.Controllers:ThumbnailComponentController
   *  @param {string} image The url for the image to localize
   *  @description
   *    Localizes an image url
   */
  getLocalizedImage(image) {
    return modifyUrlWithLocalization(image, this.UrlLanguage.activeLanguage);
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThumbnailComponentController#premiumUpsell
   * @methodOf Boom.Controllers:ThumbnailComponentController
   * @description
   *   Determines upsell behavior based on content settings and user subscription
   */
  premiumUpsell(content, slug, page) {
    if (this.contentIsFreeOrUnwalled(content)) {
      let paramSeriesId;

      if (content.is_episodic) {
        paramSeriesId = content.external_id;
      } else {
        paramSeriesId = content.seriesId || content.id;
      }

      const params = {
        seriesId: paramSeriesId,
        episodeNumber: content.number || '1',
        slug: slug,
        page: page
      }
      this.$state.go('watch.episode', params);
    } else if (this.doNotUpsell) {
      if (
        !this.UserAccountService.isRegistered()
      ) {
        return this.$state.go('login');
      }
      return this.$state.go('sunset');
    } else {
      this.CollectionUpsellService.triggerUpsell(content);
    }
  }

  contentIsFreeOrUnwalled(content) {
    const userType = this.UserAccountService.getType().toLowerCase();

    return (
      content.is_free ||
      (
      content.programSchedule &&
      content.programSchedule.userTypeHasAccess(userType)
      ) ||
      (
      content._rawProperties &&
      content._rawProperties.first_episode_video &&
      content._rawProperties.first_episode_video.wall_restriction === null
      ) ||
      (
      content.video &&
      content.video.wall &&
      content.video.wall.restriction === null
      )
    );
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:ThumbnailComponentController#isRestricted
   * @methodOf Boom.Controllers:ThumbnailComponentController
   * @description
   *   Checks for paywall restriction
   */
  isRestricted(restriction) {
    return (restriction === 'paywall');
  }
}
