/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomSubCollectionButtonsController
*  @module Boom
*  @description
*    Responsible for showing sub-collection buttons
*/
class BoomSubCollectionButtonsController {
  static get $inject() {
    /* istanbul ignore next */
    return ['$stateParams'];
  }
  /* istanbul ignore next */
  constructor($stateParams) {
    this.$stateParams = $stateParams;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomSubCollectionButtonsController#isSeries
   *  @methodOf Boom.Controllers:BoomSubCollectionButtonsController
   *  @description
   *    Checks $stateParams to check for series to hide collectionType navigation tabs (i.e. Series, Movies, Playlist)
   */
  isSeries() {
    return this.$stateParams.collectionType !== undefined;
  }

}

export default BoomSubCollectionButtonsController;
