import CarouselController from 'components/carousel/carousel-controller';
import { modifyUrlWithLocalization } from '../../localized-images';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomCarouselController
*  @module Boom
*  @description
*    The carousel controller
*/
class BoomCarouselController extends CarouselController {
  static get $inject() {
    return [
      '$window',
      '$timeout',
      '$element',
      '$rootScope',
      'UrlLanguage',
      'CollectionUpsellService'
    ];
  }

  /* istanbul ignore next */
  constructor($window, $timeout, $element, $rootScope, UrlLanguage, CollectionUpsellService) {
    super($window, $timeout, $element, $rootScope);
    this.UrlLanguage = UrlLanguage;
    this.CollectionUpsellService = CollectionUpsellService;
    this.CollectionUpsellService.checkEnableUpsell().then(response => {
      this.upsell = response;
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCarouselController#getFullUrl
   *  @methodOf Boom.Controllers:BoomCarouselController
   *  @description
   *    The url that is currently in the marketing type databags is `boomerang.com/testbracket`
   *    If u dump that in a href attribute it's going to just concat it onto the url,
   *    so instead we prepend `//`, but we don't want to break if they use a full url or
   *    a relative url at some point.
   */
  getFullUrl(url) {
    if (url.indexOf('http') !== 0 && url.indexOf('/') !== 0) {
      url = `//${url}`;
    }
    return url;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCarouselController#getLocalizedImage
   *  @methodOf Boom.Controllers:BoomCarouselController
   *  @param {string} image The url for the image to localize
   *  @description
   *    Localizes an image url
   */
  getLocalizedImage(image) {
    return modifyUrlWithLocalization(image, this.UrlLanguage.activeLanguage);
  }
}

export default BoomCarouselController;
