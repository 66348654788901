/**
 *  @name getRange
 *  @params {interger} Start of range
 *  @params {interger} End of range
 *  @returns {array} range of digits in an array
 *  @description
 *    Helper method to get a range of digits in an array
 *  @example
 *    getRange(3, 6)    // [3, 4, 5, 6]
**/
const getRange = (start, end) => {
  let output = [];

  if (!start || !end) {
    return output;
  }

  for (let i = start; i <= end; i++) {
    output.push(i);
  }

  return output;
};

const formatDateString = (date) => {
  if (date) {
    let dates = date.split('-');
    const year = dates.shift();
    dates.push(year);
    return dates.join('/');
  }
  return '';
};


export { getRange, formatDateString };
