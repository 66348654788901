import ThumbnailController from './thumbnail-controller';

const ThumbnailComponent = {
  bindings: {
    series: '<',
    collection: '<',
    franchise: '<',
    episode: '<',
    movie: '<',
    playlist: '<',
    slug: '<',
    page: '<',
    replace: '<',
    franchisePage: '<',
    isPlaying: '<',
    thumbnailTag: '<',
    upsell: '<'
  },
  controller: ThumbnailController,
  controllerAs: 'vm',
  templateUrl: ['$element', '$attrs', function($element, $attrs) {
    if ($attrs.collection) {
      return 'components/thumbnail/collection-thumbnail.html';
    } else if ($attrs.episode) {
      return 'components/thumbnail/episode-thumbnail.html';
    } else if ($attrs.movie) {
      return 'components/thumbnail/movie-thumbnail.html';
    } else if ($attrs.playlist) {
      return 'components/thumbnail/playlist-thumbnail.html';
    } else {
      return 'components/thumbnail/series-thumbnail.html';
    }
  }]
};

export default ThumbnailComponent;
