/**
 *  @ngdoc object
 *  @name Seed.Components:openOverlay
 *  @module Seed
 *  @description
 *    Used to open a targetted overlay.
 */
import OpenOverlayController from './open-overlay-controller';

const OpenOverlayComponent = {
  bindings: {
    name: '<'
  },
  controller: OpenOverlayController,
  controllerAs: 'vm',
  templateUrl: 'components/overlay/open-overlay.html',
  transclude: true
};

export default OpenOverlayComponent;
