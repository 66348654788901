import BoomHistoryItemController from './boom-history-item-controller';

const BoomHistoryItemComponent = {
  bindings: {
    destinationState: '<',
    destinationParams: '<',
    seriesId: '<',
    seriesTitle: '<',
    episodeNumber: '<',
    episodeTemplate: '<',
    seriesTemplate: '<',
    episodeTitle: '<',
    isFilm: '<'
  },
  controller: BoomHistoryItemController,
  controllerAs: 'vm',
  templateUrl: ['$element', '$attrs', function($element, $attrs) {
    if ($attrs.episodeTemplate) {
      return 'components/history-item/history-item-episode.html';
    } else if ($attrs.seriesTemplate) {
      return 'components/history-item/history-item-series.html';
    } else {
      return 'components/history-item/history-item-episode.html';
    }
  }]
};

export default BoomHistoryItemComponent;
