/**
 *  @ngdoc controller
 *  @name Boom.Controllers:VolumeNavigatorController
 *  @module Boom
 *  @description
 *    Responsible paginating between seasons in a series.
 */
class VolumeNavigatorController {

  /**
   * @ngdoc method
   * @name Boom.Controllers:VolumeNavigatorController#getActiveSeasonIndex
   * @methodOf Boom.Controllers:VolumeNavigatorController
   * @description
   *   Returns which season index is currently the activeSeasonNumber
   */
  getActiveSeasonIndex() {
    let activeSeasonIndex = 1;

    if (this.seasons) {
      activeSeasonIndex = this.seasons.findIndex((a, b) => a.seasonNumber === this.activeSeasonNumber);
    }
    return activeSeasonIndex;
  }

  /**
     * @ngdoc method
     * @name Boom.Controllers:VolumeNavigatorController#onPrevious
     * @methodOf Boom.Controllers:VolumeNavigatorController
     * @description
     *  Decrements the activeSeasonNumber
     */
  onPrevious() {
    const activeSeasonIndex = this.getActiveSeasonIndex();
    this.onSeasonChange({
      activeSeason: this.seasons[activeSeasonIndex - 1]
    });
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:VolumeNavigatorController#onNext
   * @methodOf Boom.Controllers:VolumeNavigatorController
   * @description
   *  Increments the activeSeasonNumber
   */
  onNext() {
    const activeSeasonIndex = this.getActiveSeasonIndex();
    this.onSeasonChange({
      activeSeason: this.seasons[activeSeasonIndex + 1]
    });
  }
}

export default VolumeNavigatorController;
