import { default as BuildPathFilterClass } from 'filters/build-path/build-path-filter';

ConfigFunction.$inject = [
  'platformProvider',
  'videoProvider',
  'platformjs.metadataProvider',
  'platformjs.imagetypesProvider',
  '$locationProvider',
  '$urlMatcherFactoryProvider',
  '$translateProvider',
  '$windowProvider',
  '$compileProvider',
  '$httpProvider',
  '$cookiesProvider'
];

function ConfigFunction(
  platformProvider,
  videoProvider,
  metadataProvider,
  imageProvider,
  $locationProvider,
  $urlMatcherFactoryProvider,
  $translateProvider,
  $windowProvider,
  $compileProvider,
  $httpProvider,
  $cookiesProvider
) {

  platformProvider.config.application.id = 'www';
  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.strictMode(false);

  let $window = $windowProvider.$get();

  angular.merge(platformProvider.config, $window.appConfig);

  platformProvider.config.application.defaultSubtitleFormat = 'srt';
  platformProvider.config.application.cache = {
    on: true,
    size: 5
  };

  // DRM does not work on Chrome 58 or less.  IE not supported at all.  Only need to specify IE 11 cause VP will detect
  // that MediaSource is not available
  const supportedChromeVersion = 59;
  const unsupportedBrowsers = ['IE 11'];
  for (let i = 1; i < supportedChromeVersion; i++) {
    unsupportedBrowsers.push(`Chrome ${i}`);
  }

  /**
   * Use cookies instead of local storage so that we can wildcard cross-domain
   * This will allow for the use to be shared between the www and watch sites
   *
   * TODO: Need to invalidate between dev. envs (qa/dev) as this config means
   * they will also share cookies, and the app will think the user is logged in.
   * See: https://dramafever.atlassian.net/browse/BOOM-1259
   */
  platformProvider.config.application.storage = 'cookie';
  $cookiesProvider.defaults.domain = platformProvider.config.application.cookieDomain;

  //Disable debug mode
  if (platformProvider.config.build && platformProvider.config.build.id !== 'development') {
    $compileProvider.debugInfoEnabled(false);
  }

  var videoAppSettings = {
    audio: {
      defaultLanguage: 'en',
    },
    baseUrl: platformProvider.config.application.url,
    debug: '',
    platform: 'html5',
    version: '',
    settings: {
      'rewindInterval': 30,
      'fastforwardInterval': 30
    },
    cdn: 'cloudfront',
    drm: true,
    useUUID: true,
    jwt: true,
    enablePermissionCheck: true,
    enableBitrateSwitching: true,
    youbora: {},
    unsupportedBrowsers: unsupportedBrowsers,
    subtitles: {
      languages: ['en', 'es', 'pt', 'off'],
      fullLanguages: {
        'en': 'English',
        'es': 'Español',
        'pt': 'Português',
        'off': 'Off'
      },
      sideload: true
    }
  };

  videoProvider.setAppSettings(videoAppSettings);

  metadataProvider.setDefaults({
    image: 'images/social/default_share.jpg',
    title: 'Boomerang | Full Episodes of Your Family’s Favorite Cartoons',
    description: 'Boomerang has full episodes of all your favorite cartoons all in one place! Your family will love watching classic cartoon shows like Looney Tunes, Tom and Jerry, The Flintstones, Yogi Bear, and so many more.'
  });

  //we have to import/instantiate manuallly because filters can't be injected into config blocks
  const buildPathFilter = new BuildPathFilterClass(platformProvider.config, $window);
  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: buildPathFilter('i18n/{part}/{lang}.json')
  });

  const jsonLdObject = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    '@id': 'https://www.boomerang.com',
    'name': 'Boomerang',
    'url': 'https://www.boomerang.com',
    'logo': `${buildPathFilter('images/social/default_share.jpg', true)}`,
    'contactPoint': {
      '@type': 'ContactPoint',
      'contactType': 'customer service',
      'name': 'Boomerang Support',
      'areaServed': 'US',
      'url': 'https://boomerang.zendesk.com/',
      'availableLanguage': ['English', 'Spanish']
    },
    'sameAs': ['https://watch.boomerang.com', 'https://twitter.com/boomerangtoons', 'https://www.facebook.com/BoomerangToons/', 'https://www.instagram.com/boomerangtoons/', 'https://www.youtube.com/c/Boomerang/']
  };

  metadataProvider.setDefaults({
    'json-ld': JSON.stringify(jsonLdObject)
  });

  //Add custom image types
  imageProvider.addType({
    assetType: 'collection',
    imageType: 'playlist',
    resolvePath: (image) => {
      if (image.assetKey) {
        return `${image.artSourceUrl}asset/collection/${image.id}/${image.assetKey}/playlists.jpg`;
      } else {
        return `${image.artSourceUrl}art-collections/playlists/${image.id}.jpg`;
      }
    },
    size: {
      width: 100,
      height: 100
    }
  });

  imageProvider.addType({
    assetType: 'collection',
    imageType: 'box',
    resolvePath: (image) => {
      if (image.assetKey) {
        return `${image.artSourceUrl}asset/collection/${image.id}/${image.assetKey}/box.png`;
      } else {
        return `${image.artSourceUrl}art-collections/box/${image.id}.png`;
      }
    },
    size: {
      width: 100,
      height: 100
    }
  });

  imageProvider.addType({
    assetType: 'collection',
    imageType: 'logo',
    resolvePath: (image) => {
      if (image.assetKey) {
        return `${image.artSourceUrl}asset/collection/${image.id}/${image.assetKey}/logo.png`;
      } else {
        return `${image.artSourceUrl}art-collections/logo/${image.id}.png`;
      }
    },
    size: {
      width: 100,
      height: 100
    }
  });

  imageProvider.addType({
    assetType: 'collection',
    imageType: 'franchise-box',
    resolvePath: (image) => {
      if (image.assetKey) {
        return `${image.artSourceUrl}asset/collection/${image.id}/${image.assetKey}/franchise-box.png`;
      } else {
        return `${image.artSourceUrl}art-collections/${image.imageType}/${image.id}.png`;
      }
    },
    size: {
      width: 100,
      height: 100
    }
  });

  imageProvider.addType({
    assetType: 'collection',
    imageType: 'upsell_desktop',
    resolvePath: (image) => {
      if (image.assetKey) {
        return `${image.artSourceUrl}asset/collection/${image.id}/${image.assetKey}/upsell_desktop.png`;
      } else {
        return `${image.artSourceUrl}art-collections/upsell_desktop/${image.id}.png`;
      }
    },
    size: {
      width: 700,
      height: 290
    }
  });

  imageProvider.addType({
    assetType: 'collection',
    imageType: 'upsell_desktop_full',
    resolvePath: (image) => {
      if (image.assetKey) {
        return `${image.artSourceUrl}asset/collection/${image.id}/${image.assetKey}/upsell_desktop_full.png`;
      } else {
        return `${image.artSourceUrl}art-collections/upsell_desktop_full/${image.id}.png`;
      }
    },
    size: {
      width: 1040,
      height: 1000
    }
  });

  //we have to import/instantiate manuallly because filters can't be injected into config blocks
  let BuildPathFilter = new BuildPathFilterClass(platformProvider.config, $window);

  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: BuildPathFilter('i18n/{part}/{lang}.json'),
    $http: {
      transformResponse: appendTransform($httpProvider.defaults.transformResponse, removeEmptyTranslations)
    }
  });

  $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
}

function appendTransform(defaults, transform) {
  // We can't guarantee that the default transformation is an array
  defaults = angular.isArray(defaults) ? defaults : [defaults];
  // Append the new transformation to the defaults
  return defaults.concat(transform);
}

/*
 we want to remove any translations with empty values so that angular translate
 will fall back to the english version.  However, we want to keep them in the .json files so
 the translation team can see everything that is still yet-to-be-translated
 so instead we provide a $http interceptor for to the loader and strip off all the
 empty properties before they get passed through.
*/
function removeEmptyTranslations(translations) {
  var newTranslations = {};
  for (var key in translations) {
    if (translations[key]) { //check to make sure there is a translation
      newTranslations[key] = translations[key];
    }
  }
  return newTranslations;
}

export default ConfigFunction;
export { appendTransform, removeEmptyTranslations };
