/* @ngInject */
PlaylistFactory.$inject = ['platformjs.collections2'];

function PlaylistFactory(CollectionsFactory) {

  const Playlist = () => {
    const playlist = new CollectionsFactory({
      tag: 'type',
      value: 'playlist'
    });

    return {
      list: playlist.list
    };
  };

  return Playlist;

}

export default PlaylistFactory;
