import MoviesViewController from './movies-view-controller';

function MoviesState($stateProvider) {

  $stateProvider.state('movies', {
    parent: 'language',
    url: '/movies?page',
    controller: MoviesViewController,
    controllerAs: 'vm',
    templateUrl: 'views/movies/movies-view.html',
    segment: {
      name: 'All Movies Page'
    }
  });

}

MoviesState.$inject = ['$stateProvider'];

export default MoviesState;
