import BoomPaginationController from './boom-pagination-controller';

const BoomPaginationComponent = {
  transclude: true,
  bindings: {
    onChange: '&',
    page: '<',
    pages: '<',
    reload: '=',
    scrollTo: '<'
  },
  controller: BoomPaginationController,
  controllerAs: 'vm',
  templateUrl: 'components/pagination/pagination.html'
};

export default BoomPaginationComponent;
