/**
*  @ngdoc controller
*  @name Seed.Controllers:HistoryView
*  @module Seed
*  @description
*    Responsible for managing the history view.
*/
class HistoryViewController {
  static get $inject() {
    return ['platformjs.user.history'];
  }
  constructor(UserHistoryService) {
    this.UserHistoryService = UserHistoryService;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:HistoryView
     *  @description
     *  @returns {array} Current history errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name historyItems
     *  @propertyOf Seed.Controllers:HistoryView
     *  @description
     *  @returns {array} Previously watched episodes.
     */
    this.historyItems = null;

    /**
     *  @ngdoc property
     *  @name loading
     *  @propertyOf Seed.Controllers:HistoryView
     *  @description
     *  @returns {boolean} Current loading state.
     */
    this.loading = false;
  }
  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryView#complete
   *  @methodOf Seed.Controllers:HistoryView
   *  @description
   *    Called on history complete.
   *    Sets {@link Seed.Controllers:HistoryView#properties_loading loading} to false.
   */
  complete() {

    this.loading = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryView#initialize
   *  @methodOf Seed.Controllers:HistoryView
   *  @description
   *    Called on history load.
   *    Sets {@link Seed.Controllers:HistoryView#properties_loading loading} to true and fetches the list of content.
   */
  $onInit() {

    this.loading = true;

    this.UserHistoryService.recentlyWatched()
      .then(history => this.showSuccess(history))
      .catch(errors => this.showErrors(errors))
      .finally(() => this.complete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryView#failure
   *  @methodOf Seed.Controllers:HistoryView
   *  @param {array} errors An array of errors.
   *  @description
   *    Called on history failure.
   *    Sets {@link Seed.Controllers:HistoryViewController#properties_errors errors} to the passed errors parameter.
   */
  showErrors(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryView#success
   *  @methodOf Seed.Controllers:HistoryView
   *  @description
   *    Called on history success.
   *    Sets list of history items.
   */
  showSuccess(history) {

    this.historyItems = history.recentlyWatched;

  }
}

export default HistoryViewController;
