import HistoryViewController from 'views/history/history-view-controller';

function BoomHistoryState($stateProvider) {

  $stateProvider.state('history', {
    parent: 'language',
    url: '/history',
    templateUrl: 'views/history/history-view.html',
    controller: HistoryViewController,
    controllerAs: 'vm',
    userRequired: true
  });

}

BoomHistoryState.$inject = ['$stateProvider'];

export default BoomHistoryState;
