/**
*  @ngdoc controller
*  @name Boom.Services:SunsetCheckService
*  @module Boom
*  @requires platformjs.claims
*  @requires $window
*  @description
*    Responsible making explicit analytics calls and automatic analytics calls.
*/
class SunsetCheckService {
  static get $inject() {
    return [
      'platformjs.claims',
      '$window',
      '$location',
      '$transitions',
      '$q'
    ];
  }

  constructor(
    ClaimsService,
    $window,
    $location,
    $transitions,
    $q
  ) {
    this.ClaimsService = ClaimsService;
    this.$window = $window;
    this.$location = $location;
    this.$transitions = $transitions;
    this.$q = $q;
    this.routesToRedirectToWatch = [
      'signup',
      'premium',
      'upsell',
      'products',
      'subscribe',
      'welcome',
      'home-upsell'
    ];

    this.thatsAllFolksAllowedRoutes = [
      'privacy',
      'terms',
      'thatsallfolks',
      'privacy-old',
      'help'
    ];

    this.sunsetRoute = `/sunset`;
    this.thatsAllFolksRoute = `/thatsallfolks`;
  }

  addTransitionHook() {
    this.$transitions.onStart({}, transition => this.checkForSunsetRedirects(transition));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Services:SunsetCheckService#checkForSunsetRedirects
   *  @methodOf Boom.Services:SunsetCheckService
   *  @requires redirectPath
   *  @description
   *    Checks the operation_nightfall, indicates if it is set,
   *      and redirects if it's set to 1
   */
  checkForSunsetRedirects(transition) {
    const to = transition.to();
    if (!to.name) {
      return;
    }

    this.doNightfallCheck = this.routesToRedirectToWatch.includes(to.name);
    this.doThatsAllFolksCheck = !this.thatsAllFolksAllowedRoutes.includes(to.name);


    this.premiumClaims = this.ClaimsService.getClaim('premium');

    if (this.premiumClaims && this.premiumClaims._rawProperties) {
      return this.checkSunsetClaims();
    }

    return this.ClaimsService.get().then(() => {
      this.premiumClaims = this.ClaimsService.getClaim('premium');
      return this.checkSunsetClaims();
    });
  }

  checkSunsetClaims() {
    return this.checkForThatsAllFolksRedirect().then(() => this.checkForNightfallRedirect()
    );
  }

  checkForThatsAllFolksRedirect() {
    if (
      this.doThatsAllFolksCheck &&
      this.premiumClaims._rawProperties.operation_thatsallfolks !== undefined &&
      parseInt(this.premiumClaims._rawProperties.operation_thatsallfolks) === 1
    ) {
      this.$window.location.replace(this.thatsAllFolksRoute);
      return this.$q.reject();
    }
    return this.$q.resolve();
  }

  checkForNightfallRedirect() {
    if (
      this.doNightfallCheck &&
      this.premiumClaims._rawProperties.operation_nightfall !== undefined &&
      parseInt(this.premiumClaims._rawProperties.operation_nightfall) === 1
    ) {
      this.$window.location.replace(this.sunsetRoute);
      return this.$q.reject();
    }
    return this.$q.resolve();
  }

}

export default SunsetCheckService;
