/**
 *  @ngdoc controller
 *  @name Boom.Controllers:VolumeDropdownController
 *  @module Boom
 *  @description
 *    Responsible toggling the volume dropdown
 */
class VolumeDropdownController {
  constructor() {
    this.isActive = false;
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:VolumeDropdownController#expandDropdown
   * @methodOf Boom.Controllers:VolumeDropdownController
   * @description
   *  Boolean toggle for isActive
   */
  expandDropdown() {
    this.isActive = !this.isActive;
  }

  /**
   * @ngdoc method
   * @name Boom.Controllers:VolumeDropdownController#makeDropdownInactive
   * @methodOf Boom.Controllers:VolumeDropdownController
   * @description
   *   sets isActive to false
   */
  makeDropdownInactive() {
    this.isActive = false;
  }
}

export default VolumeDropdownController;
