import BoomSubCollectionButtonsController from './boom-sub-collection-buttons-controller';

const BoomSubCollectionButtonsComponent = {
  bindings: {
    setActiveSubCollectionType: '=',
    collectionItems: '<',
    parentCollectionSlug: '<',
    activeSubCollectionType: '<'
  },
  controller: BoomSubCollectionButtonsController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-sub-collection-buttons/boom-sub-collection-buttons.html'
};

export default BoomSubCollectionButtonsComponent;
