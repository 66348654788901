import BrowseContentController from 'components/browse-content/browse-content-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomBrowseContent
 *  @module Seed
 *  @requires platformjs.collection
 *  @requires platformjs.collections2
 *  @description
 *    Component that can load collection items or a list of collections
 */
class BoomBrowseContentController extends BrowseContentController {
  $onInit() {
    if (typeof this.paginationReload === 'undefined') {
      this.paginationReload = true;
    }
  }
}

export default BoomBrowseContentController;
