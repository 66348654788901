import WatchViewController from 'views/watch/watch-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:WatchView
 *  @module Boom
 *  @requires angular.$state
 *  @requires platformjs.series
 *  @requires ProgressBarService
 *  @requires UserHistoryService
 *  @requires AnalyticsService
 *  @description
 *    Responsible for displaying watch information.
 */
class BoomWatchViewController extends WatchViewController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      '$rootScope',
      '$scope',
      '$state',
      '$q',
      '$window',
      'moment',
      'platformjs.collection',
      'platformjs.episode',
      'platformjs.series',
      'platformjs.user.account',
      'platformjs.user.history',
      'platformjs.subscriptionsV2',
      'video.videoplayer',
      'ProgressBarService',
      'AnalyticsService',
      'platformjs.metadata',
      'CollectionUpsellService'
    ];
  }

  /* istanbul ignore next */
  constructor(
    $rootScope,
    $scope,
    $state,
    $q,
    $window,
    moment,
    CollectionService,
    EpisodeService,
    SeriesService,
    UserAccountService,
    UserHistoryService,
    SubscriptionsServiceV2,
    VideoPlayer,
    ProgressBarService,
    AnalyticsService,
    MetadataService,
    CollectionUpsellService
  ) {

    super($rootScope, $scope, $state, $q, $window, moment, CollectionService, SeriesService, UserHistoryService, VideoPlayer, ProgressBarService);

    this.UserAccountService = UserAccountService;
    this.EpisodeService = EpisodeService;
    this.AnalyticsService = AnalyticsService;
    this.MetadataService = MetadataService;
    this.CollectionUpsellService = CollectionUpsellService;
    this.SubscriptionsServiceV2 = SubscriptionsServiceV2;

    this.autoroll = false;
    this.config = {
      useHistory: true,
      exitFullscreenOnStop: false,
      overlays: [
        {
          id: 'paywall',
          start: 'paywall',
          end: 'play',
          align: 'all'
        },
        {
          id: 'geowall',
          start: 'geoblock',
          end: 'play',
          align: 'all'
        },
        {
          id: 'drmnotsupportedwall',
          start: 'drmnotsupported',
          end: 'play',
          align: 'all'
        },
        {
          id: 'browsernotsupportedwall',
          start: 'browsernotsupported',
          end: 'play',
          align: 'all'
        },
        {
          id: 'regwall',
          start: 'regwall',
          end: 'play',
          align: 'all'
        },
      ],
      events: {
        loadedData: (details) => this.onLoadedData(details),
        ended: (details) => this.onEnd(details),
        stop: () => {
          this.autoroll = false;
        }
      }
    };

    // we want to see 12 episodes per view unless collection
    this.loadLimit = this.getSlug() ? 20 : 12;

    this.isCollectionPlaylist = !!this.getSlug();

    this.seriesPage = false;
    this.videoEnded = 'Video Ended';
    this.VideoStarted = 'Video Started';
    this.activeSeasonNumber = 1;
    this.isPremium = false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#$onInit
   *  @methodOf Boom.Controllers:WatchView
   *  @description
   *    Makes initial request for a series object to display.
   */
  $onInit() {

    this.ProgressBarService.start();

    const nextEpisodeSuccess = (episodeNumber) => {
      this.nextEpisodeNumber = episodeNumber;
    };

    if (!this.getEpisodeNumber()) {
      // we want to redirect to the episode view asap
      this.getNextEpisode(this.getSeriesId())
        .then(nextEpisodeSuccess)
        .finally(() => this.initSeries());
    } else {
      this.videoInit = true;
    }

    this.CollectionUpsellService.checkEnableUpsell().then(response => {
      this.upsell = response;
    });

    if (this.UserAccountService.isPremium()) {
      this.isPremium = true;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#onLoadedData
   *  @methodOf Boom.Controllers:WatchView
   *  @param {Object} VideoPlay details
   *  @description
   *    Calls resetUseHistory and calls method to log video started and user selection analytics.
   */
  onLoadedData(details) {
    this.resetUseHistory();
    this.logAnalyticsData(this.VideoStarted, details);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#onEnd
   *  @methodOf Boom.Controllers:WatchView
   *  @param {Object} VideoPlay details
   *  @description
   *    Calls autoplay, calls method to log video ended and user selection analytics, and sets autoroll to true.
   */
  onEnd(details) {
    this.logAnalyticsData(this.videoEnded, details);
    this.autoplay();
    /**
     *  autoroll must be set after we log to segment
     *  so that the first time we recieve an end event
     *  after loading the controller it autoroll will still be false.
     */
    this.autoroll = true;
  }

  _parseSeries(franchise) {
    return franchise.slice(0, franchise.indexOf('-franchise')) + '-series';
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#watch
   *  @methodOf Boom.Controllers:WatchView
   *  @description
   *    Makes initial request for a series object to display.
   */
  watch() {
    if (!this.getEpisodeNumber()) {
      this.VideoPlayer.stop();
    } else {
      if (this.videoInit) {
        this.$onChanges();
      } else {
        this.videoInit = true;
      }
      this.fetchEpisode();
      this.loadSeasons();
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#watchClipId
   *  @methodOf Boom.Controllers:WatchView
   *  @description
   *    Watch if clip id has changes
   */
  watchClipId() {}

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#fetchEpisode
   *  @methodOf Boom.Controllers:WatchView
   *  @description
   *    Makes initial request for an episode object.
   */
  fetchEpisode() {

    this.EpisodeService.get(this.getSeriesId(), this.$state.params.episodeNumber, false)
      .then(episode => {
        this.episode = episode;
        this.updateMetadata();
        this.activeSeasonNumber = episode.season;
        const slug = episode._rawProperties.metadata.franchise_slug;
        this.CollectionService.get({
          slug
        }).then((collection) => {
          this.collectionTitle = collection.title;
          this.CollectionUpsellService.listenForUpsellTrigger();
          this.collectionCache = collection;
        });
      })
      .catch(() => {
        if (!this.nextEpisodeNumber) {
          this.$state.go('404');
        }
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#logAnalyticsData
   *  @methodOf Boom.Controllers:WatchView
   *  @param {string} type of Segment call to make
   *  @param {Object} VideoPlay details
   *  @description
   *    Logs video events and user selections to Segment.
   */
  logAnalyticsData(name, details) {
    const sendData = () => {
      const seriesData = formatSeriesData(this.series)
      let analyticsData = getAnalyticsData(this.episode, this.autoroll, details, this.collectionTitle);

      if (name === this.videoEnded) {
        analyticsData.duration_available = this.episode.duration;
      }
      Object.assign(analyticsData, seriesData);
      this.AnalyticsService.track(name, analyticsData);
    }

    if (this.series) {
      sendData();
    } else {
      this.SeriesService.get(this.$state.params.seriesId).then((series) => {
        this.series = series;
        sendData();
      });
    }
  }

  /*
    *  @ngdoc method
    *  @name Boom.Controllers:WatchView#updateMetadata
    *  @methodOf Boom.Controllers:WatchView
    *  @description
    *    Updates the metadata tags.
    */
  updateMetadata() {
    let title;
    let description;
    let keywords;
    let image;

    if (this.episode) {
      title = this.episode.title;
      if (this.episode._rawProperties.description) {
        title += ` - ${this.episode._rawProperties.description}`;
      }
      keywords = this._generateKeywords();

      if (this.episode._rawProperties.metadata.franchise_slug) {
        let franchise = this._parseFranchise(this.episode._rawProperties.metadata.franchise_slug);

        description = `${franchise} - ` + title;
        keywords += `, ${franchise}`;

      } else {
        description = title;
      }
      title += ' | Boomerang.com';

      image = this.episode.images.thumb.url;
      this.MetadataService.setData({
        image,
        title,
        description,
        keywords
      });
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#autoplayCollection
   *  @methodOf Boom.Controllers:WatchView
   *  @description
   *   Used to autoplay through a collection
   */
  autoplayCollection() {

    let index = 0;
    let collectionPage = this.getCollectionPage();

    const success = (episodes) => {
      if (episodes.collectionItems) {
        episodes = episodes.collectionItems;
      }

      this.changeState({
        seriesId: episodes[index].item.seriesId,
        episodeNumber: episodes[index].item.number,
        slug: this.getSlug(),
        collectionPage: collectionPage
      });
    };

    // find current episode in episodes array
    // increment index by one greater since we want to find the next episode
    for (let i = 0; i < this.originalEpisodes.length; i++) {
      if (this.originalEpisodes[i].item.seriesId === parseInt(this.getSeriesId()) &&
        this.originalEpisodes[i].item.number === parseInt(this.getEpisodeNumber())) {
        index = i + 1;
        break;
      }
    }

    // if last episode in current page, need to fetch next episode
    if (index >= this.loadLimit &&
      collectionPage < this.pages) {

      index = 0;
      collectionPage += 1;

      const params = {
        page: collectionPage,
        slug: this.getSlug()
      };

      this.CollectionService.getItems(params)
        .then(collection => {
          success(collection)
        });

    } else if (collectionPage < this.pages ||
      index < this.originalEpisodes.length) {
      success(this.originalEpisodes);
    } else if (this.$rootScope.previousStateName) {
      this.watchEnd();
    }

  }

  /*
    *  @ngdoc method
    *  @name Boom.Controllers:WatchView#loadEpisodesFailure
    *  @methodOf Boom.Controllers:WatchView
    *  @description
    *    Override Failure behavior
    */
  loadEpisodesFailure() {}

  /*
    *  @ngdoc method
    *  @name Boom.Controllers:WatchView#_generateKeywords
    *  @methodOf Boom.Controllers:WatchView
    *  @description
    *    Generates keywords from the episode metadata
    */
  _generateKeywords() {
    //Not all episodes have the search-* metadata
    let searchCharacters = this.episode._rawProperties.metadata['search-characters'] || '';
    let searchKeywords = this.episode._rawProperties.metadata['search-keywords'] || '';
    return [
      ...searchCharacters.split(','),
      ...searchKeywords.split(',')
    ]
      .filter(keywordString => keywordString !== '') //Remove any empty strings
      .map(keywordString => keywordString.trim())
      .join(', '); //join it back into a string
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#_generateFranchise
   *  @methodOf Boom.Controllers:WatchView
   *  @param {string} slug Franchise slug
   *  @description
   *    Returns a sanitized display name from franchise slug
   */
  _parseFranchise(slug) {
    let tag = '-franchise';
    let franchiseNames = slug.substr(0, slug.indexOf(tag)).split('-');
    let franchise = '';

    franchiseNames.forEach(name => {
      franchise += `${name.charAt(0).toUpperCase() + name.slice(1)} `;
    });

    return franchise.trim();

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#loadSeasons
   *  @methodOf Boom.Controllers:WatchView
   *  @description
   *    Will call getSeasons to load season information.
   */
  loadSeasons() {
    let config = {
      id: this.$state.params.seriesId,
      page: 1
    };

    this.SeriesService.getSeasons(config)
      .then(this.loadSeasonsSuccess.bind(this))
      .catch(this.showSeriesFailure.bind(this));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#loadSeasonsSuccess
   *  @methodOf Boom.Controllers:WatchView
   *  @param {object} response Season object
   *  @description
   *    Called on success of loadSeasons
   *    Sets response object to seasons.
   */
  loadSeasonsSuccess(response) {
    this.seasons = response.seasons;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:WatchView#seasonChange
   *  @methodOf Boom.Controllers:WatchView
   *  @param {int} newActiveSeason integer
   *  @description
   *    Called onSeasonChange.
   *    Sets the current activeSeasonNumber to new activeSeasonNumber when onSeasonChange.
   */
  seasonChange(newActiveSeason) {
    this.activeSeasonNumber = newActiveSeason.seasonNumber;
  }
}

export default BoomWatchViewController;

function getAnalyticsData(episode, autoroll, details, collectionTitle) {

  // Needed for backward compatibility with VideoPlayer
  if (!details.audioTrack) {
    details.audioTrack = {};
  }

  if (!details.textTrack) {
    details.textTrack = {};
  }

  return {
    autoroll,
    paywall_required: episode.isPremiumRequired(),
    cc: details.textTrack.language ? true : false,
    offline: false,
    language: details.audioTrack.language || 'en',
    episode_id: episode.guid,
    episode_name: episode.title,
    show_id: episode._rawProperties.metadata.franchise_id,
    show_name: collectionTitle
  };

}

function formatSeriesData(series) {
  if (series.isFilm) {
    return {
      movie_id: series.id,
      movie_name: series.title
    };
  } else {
    return {
      series_id: series.id,
      series_name: series.title
    };
  }
}
