/**
 *  @ngdoc controller
 *  @name Seed.Utilities:ResizeTimeout
 *  @module Seed
 *  @description
 *    Runs bound listener methods on a timeout.  To improve performances
 *    instead of multiple carousels listening to the resize event.
 */
class ResizeTimeoutClass {
  constructor(window) {
    this.listeners = new Map();
    this.timeout;
    this.window = window;
    this.bindToEvent();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Utilities:ResizeTimeout#bindToEvent
   *  @methodOf Seed.Utilities:ResizeTimeout
   *  @description
   *
   *   Binds to the window resize event and runs the listeners
   *
   */
  bindToEvent() {
    this.window.addEventListener('resize', this.onResize.bind(this));
  }

  /**
   *  @ngdoc method
   *  @name Seed.Utilities:ResizeTimeout#onResize
   *  @methodOf Seed.Utilities:ResizeTimeout
   *  @description
   *
   *   Method that runs when the window is resized
   *
   */
  onResize() {
    this.window.clearTimeout(this.timeout);
    this.timeout = this.window.setTimeout(this.runListeners.bind(this), 100);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Utilities:ResizeTimeout#addListener
   *  @methodOf Seed.Utilities:ResizeTimeout
   *  @description
   *
   *   Add a listener to the Map of resize listeners
   *
   */
  addListener(callback) {
    this.listeners.set(callback, callback);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Utilities:ResizeTimeout#removeListener
   *  @methodOf Seed.Utilities:ResizeTimeout
   *  @description
   *
   *   Remove a listener from the map
   *
   */
  removeListener(callback) {
    this.listeners.delete(callback);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Utilities:ResizeTimeout#runListeners
   *  @methodOf Seed.Utilities:ResizeTimeout
   *  @description
   *
   *   Loop through the map and run the listeners
   *
   */
  runListeners() {
    this.listeners.forEach((listener) => {
      listener();
    });
  }
}

let ResizeTimeout = new ResizeTimeoutClass(window);

export { ResizeTimeout, ResizeTimeoutClass }
