import BoomCollectionUpsellController from './boom-collection-upsell-controller';

const BoomCollectionUpsellComponent = {
  bindings: {
    series: '<',
    episode: '<',
    collection: '<'
  },
  controllerAs: 'vm',
  controller: BoomCollectionUpsellController,
  templateUrl: 'components/boom-collection-upsell/boom-collection-upsell.html'
};
export default BoomCollectionUpsellComponent;
