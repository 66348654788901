/**
 *  @ngdoc controller
 *  @name Boom.Controllers:Pagination
 *  @module Boom
 *  @description
 *    Responsible for logging in a user.
 */
class BoomPaginationController {
  static get $inject() {
    return ['$state', '$stateParams', '$anchorScroll'];
  }
  /* istanbul ignore next */
  constructor($state, $stateParams, $anchorScroll) {

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$anchorScroll = $anchorScroll;

    /**
     *  @ngdoc property
     *  @name reload
     *  @type Boolean
     *  @propertyOf Boom.Controllers:Pagination
     *  @description
     *    A flag to provide different behavior of changing pages, if reload is set true, when page changes it will reload current state with the new page number.
     */
    this.reload = this.reload;

    /**
     *  @ngdoc property
     *  @name page
     *  @propertyOf Boom.Controllers:Pagination
     *  @returns {integer} Page
     *  @description
     *    Current page
     */
    this.page = this.$stateParams.page || this.page || 1;
    this.page = parseInt(this.page);
    /**
     *  @ngdoc property
     *  @name pages
     *  @propertyOf Boom.Controllers:Pagination
     *  @returns {integer} Pages
     *  @description
     *    Number of pages.
     */
    this.pages = this.pages || 1;

    /**
     *  @ngdoc property
     *  @name limit
     *  @propertyOf Boom.Controllers:Pagination
     *  @returns {integer} Size
     *  @description
     *    Number of pages to display at once between the previous and next arrows.
     */
    this.limit = 7;

    /**
     *  @ngdoc property
     *  @name pagesArray
     *  @propertyOf Boom.Controllers:Pagination
     *  @returns {array} Pages Array
     *  @description
     *    Array of pages iterated through to create pagination component.
     */
    this.pagesArray = [];

    this.getPages();

    this.scrollTo = this.scrollTo;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:Pagination#$onChanges
   *  @methodOf Boom.Controllers:Pagination
   *  @param {object} changes object
   *  @description
   *    Detects changes to number of pages and updates the pagination component
   */
  $onChanges(changes) {
    if ((changes.pages && changes.pages.currentValue !== undefined) || (changes.page && changes.page.currentValue !== undefined)) {
      this.getPages();
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:Pagination#changePage
   *  @methodOf Boom.Controllers:Pagination
   *  @param {integer} page Page number
   *  @description
   *    Specifies a page to change to
   */
  changePage(page) {
    if (page > 0 && page <= this.pages && page !== this.page) {
      this.page = page;

      // the reload is true, let's refresh the view by the new page number
      if (this.reload) {
        this.$state.go(this.$state.current, {
          page: this.page
        }, {
          reload: true
        });

      } else {
        // redraw the pagination
        this.onChange({
          page: this.page
        });

        this.getPages();
      }
    }

    this.$anchorScroll(this.scrollTo);

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:Pagination#getPages
   *  @methodOf Boom.Controllers:Pagination
   *  @description
   *    Sets {@link Boom.Controllers:Pagination#properties_pagesArray pagesArray} based on number of pages in collection.
   */
  getPages() {

    var startPage;
    var endPage;
    var temporaryPages = [];

    if (this.pages <= this.limit) {
      startPage = 1;
      endPage = this.pages;
    } else {
      if (this.page <= Math.ceil(this.limit / 2)) {
        startPage = 1;
        endPage = this.limit - 1;
      } else if (this.page + Math.floor(this.limit / 2) >= this.pages) {
        startPage = this.pages - (this.limit - 2);
        endPage = this.pages;
      } else {
        startPage = this.page - Math.floor(this.limit / 2) + 1;
        endPage = this.page + Math.floor(this.limit / 2) - 1;
      }
    }

    for (var i = startPage; i <= endPage; i++) {
      temporaryPages.push(i);
    }

    this.pagesArray = temporaryPages;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:Pagination#getPageClass
   *  @methodOf Boom.Controllers:Pagination
   *  @description
   *    Indicates whether the current page is selected.
   */
  getPageClass(index) {

    return {
      'is-active': (this.page == index)
    };

  }

}

export default BoomPaginationController;
