import LogoutButtonController from 'components/logout-button/logout-button-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomLogoutButtonController
 *  @module Boom
 *  @requires angular.$state
 *  @require $analytics
 *  @requires platformjs.user.login
 *  @requires platformjs.claims
 *  @description
 *    Manages button that logs a user out.
 */
class BoomLogoutButtonController extends LogoutButtonController {
  static get $inject() {
    return ['$state', '$analytics', 'platformjs.user.login', 'platformjs.claims'];
  }

  /* istanbul ignore next */
  constructor($state, $analytics, LoginService, ClaimsService) {
    super($state, $analytics, LoginService);
    this.ClaimsService = ClaimsService;
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomLogoutButtonController#onLogoutSuccess
  *  @methodOf Boom.Controllers:BoomLogoutButtonController
  *  @description
  *    Fires a logout analytics event then refreshes the users claims, and finally redirects to home page.
  */
  onLogoutSuccess() {
    const _this = this;

    _this.$analytics.eventTrack('Logout', {
      category: 'User'
    });

    const redirect = () => {
      _this.$state.go('home');
    };

    _this.ClaimsService.get().then(redirect, redirect);
  }
}

export default BoomLogoutButtonController;
