/**
 *  @ngdoc controller
 *  @name Seed.Controllers:SearchOverlay
 *  @module Seed
 *  @requires OverlayService
 *  @description
 *    Overlay containing search functionality.
 */
class SearchOverlayController {
  static get $inject() {
    return ['OverlayService'];
  }
  constructor(OverlayService) {
    this.OverlayService = OverlayService;
  }
  isActive() {
    return this.OverlayService.get('search');
  }

}

export default SearchOverlayController;