/**
 *  @ngdoc controller
 *  @name Seed.Controllers:Error404View
 *  @module Seed
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @description
 *    Responsible for managing the 404 page error view.
 */
class Error404ViewController {
  constructor() {
    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:Error404View
     *  @description
     *  @returns {array} Current page load errors.
     */
    this.errors = [];
  }
}

export default Error404ViewController;