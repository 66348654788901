import PlaylistViewController from './playlist-view-controller';

function PlaylistState($stateProvider) {

  $stateProvider.state('collections', {
    parent: 'language',
    url: '/playlists/{slug}?page',
    controller: PlaylistViewController,
    controllerAs: 'vm',
    templateUrl: 'views/playlist/playlist-view.html'
  });

}

PlaylistState.$inject = ['$stateProvider'];

export default PlaylistState;
