import BoomForgotPasswordViewController from './boom-forgot-password-view-controller';

function BoomForgotPasswordState($stateProvider) {

  $stateProvider.state('forgot-password', {
    parent: 'language',
    url: '/forgot-password',
    templateUrl: 'views/forgot-password/forgot-password-view.html',
    controller: BoomForgotPasswordViewController,
    controllerAs: 'vm'
  });

}

BoomForgotPasswordState.$inject = ['$stateProvider'];

export default BoomForgotPasswordState;
