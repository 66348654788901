import CollectionViewController from 'views/collection/collection-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:FranchiseSubCollectionController
 *  @module Boom
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @requires platformjs.collections
 *  @requires ProgressBarService
 *  @requires platformjs.series
 *  @requires platformjs.metadata
 *  @description
 *    Responsible for managing the collection-of-collections within a franchise view.
 */
class FranchiseSubCollectionController extends CollectionViewController {
  static get $inject() {
    return ['$stateParams', '$state', 'platformjs.collection', 'ProgressBarService', 'platformjs.series', 'platformjs.metadata', '$anchorScroll'];
  }

  /* istanbul ignore next */
  constructor($stateParams, $state, CollectionService, ProgressBarService, SeriesService, MetadataService, $anchorScroll) {
    super($stateParams, $state, CollectionService, ProgressBarService);
    this.SeriesService = SeriesService;
    this.MetadataService = MetadataService;
    this.$anchorScroll = $anchorScroll;

    this.hasLoadedItemCollection = false;
    this.page = this.$stateParams.page || 1;
    this.seasonNumber = this.$stateParams.seasonNumber || 1;
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseSubCollectionController#$onInit
   *  @methodOf Boom.Controllers:FranchiseSubCollectionController
   *  @description
   *    Makes initial request for a collection object to display.
   */
  $onInit() {
    this.ProgressBarService.start();

    if (!this.$state.get('franchise.subcollection').data.parentCollectionItems.length) {
      setTimeout(() => {
        this.$onInit();
      }, 200);
      return;
    }

    this.loadCollection('curated', this.$stateParams.collectionSlug)
      .then(collection => this.showCollection(collection))
      .catch(error => this.showCollectionFailure(error, 'Failed loading ' + this.$stateParams.collectionType))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseSubCollectionController#showCollection
   *  @methodOf Boom.Controllers:FranchiseSubCollectionController
   *  @param {object} collection Collection object
   *  @description
   *    Called on the success of the initial request for a collection.
   */
  showCollection(collection) {
    this.collection = collection;
    this.updateMetadata();

    if (!this.collection || !this.collection.slug) {
      return this.showCollectionFailure({}, 'No ' + this.$stateParams.collectionType + ' found.');
    }

    return this.CollectionService.getItems({
      slug: this.collection.slug,
      page: this.page
    }).then((collectionItems) => this.showItems(collectionItems))
      .catch(error => this.showCollectionFailure(error, 'Failed loading ' + this.$stateParams.collectionType + ' items.'));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseSubCollectionController#showItems
   *  @methodOf Boom.Controllers:FranchiseSubCollectionController
   *  @param {object} response The response from CollectionService.getItems
   *  @description
   *    Called on the success of a request for the items in a collection.
   *    Sets the items on the controller, and if the collection only has a single item
   *    will kick off loadCollection again to go through the entire chain to load the child
   */
  showItems(response) {
    if (!response || !response.collectionItems || !response.collectionItems.length) {
      return this.showCollectionFailure({}, 'No ' + this.$stateParams.collectionType + ' items found');
    }

    this.collectionItems = response.collectionItems;

    //If that collection only has one item
    //Display the contents of that item and nothing else
    if (this.collectionItems.length === 1 &&
      !this.hasLoadedItemCollection &&
      this.collectionItems[0].item &&
      this.collectionItems[0].item.slug &&
      this.$state.get('franchise.subcollection').data.parentCollectionItems.length === 1 &&
      //dont do anything if its a movies collection, just list the one movie out
      this.$stateParams.collectionType !== 'movies') {

      this.hasLoadedItemCollection = true;
      this.ProgressBarService.start();

      if (this.collectionItems[0].type === 'Collection') {
        return this.loadCollection('curated', this.collectionItems[0].item.slug)
          .then(collection => this.showCollection(collection))
          .catch(error => this.showCollectionFailure(error, 'Failed loading a playlist.'))
          .finally(() => this.ProgressBarService.complete());
      }
      if (this.collectionItems[0].type === 'Series') {
        this.$state.go('franchise.series', {
          collectionSlug: this.collection.slug,
          seasonNumber: this.seasonNumber
        }, {
          location: 'replace'
        });
      }
    }
    //Else
    //Display list of items
    //(default behavior)
    this.contentLoaded = true;
    this.$anchorScroll();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseSubCollectionController#loadFailure
   *  @methodOf Boom.Controllers:FranchiseSubCollectionController
   *  @param {object} errors Error object
   *  @description
   *    Display errors
   */
  showCollectionFailure(err, textError) {
    this.textError = textError;
    this.loadFailed = true;
  }

  /*
  *  @ngdoc method
  *  @name Boom.Controllers:FranchiseSubCollectionController#updateMetadata
  *  @methodOf Boom.Controllers:FranchiseSubCollectionController
  *  @description
  *    Updates the metadata tags.
  */
  updateMetadata() {
    let description;
    let image;
    let title = this.collection.title;
    let parentCollection = this.$state.get('franchise.subcollection').data.parentCollection;

    if (this.collection.metadata && this.collection.metadata.description && this.collection.metadata.description.long) {
      description = this.collection.metadata.description.long;
    } else {
      description = title;
    }

    if (parentCollection.metadata && parentCollection.metadata.images && parentCollection.metadata.images.logo && parentCollection.metadata.images.logo.url) {
      image = parentCollection.metadata.images.logo.url;
    }

    title += ' | Boomerang.com';

    this.MetadataService.setData({
      title,
      image,
      description
    });

  }

}

export default FranchiseSubCollectionController;
