/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomUpsellBannerController
*  @module Boom
*  @requires $rootScope
*  @requires UrlLanguage
*  @description
*    Responsible for subscribing a user.
*/
class BoomUpsellBanner {
  static get $inject() {
    return [
      '$http',
      '$rootScope',
      '$scope',
      '$state',
      '$stateParams',
      'CollectionUpsellService',
      'platformjs.user.account',
      'platformjs.collection',
      'AgreementModalService'
    ];
  }

  constructor(
    $http,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    CollectionUpsellService,
    UserAccountService,
    CollectionService,
    AgreementModalService
  ) {
    this.safeRoutes = [
      'terms',
      'privacy',
      'login'
    ];

    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.CollectionUpsellService = CollectionUpsellService;
    this.UserAccountService = UserAccountService;
    this.CollectionService = CollectionService;
    this.AgreementModalService = AgreementModalService;

    /**
     *  @ngdoc property
     *  @name hadFreeTrial
     *  @propertyOf Boom.Controllers:BoomUpsellBannerController
     *  @returns {boolean} to check for expired user
     *  @description
     *    To determine whether user should be shown the 'Expired User Flow'
     */
    this.hadFreeTrial = false;

    /**
     *  @ngdoc property
     *  @name docked
     *  @propertyOf Boom.Controllers:BoomUpsellBannerController
     *  @returns {boolean} position of scroll and docking
     *  @description
     *    To determine whether banner is docked
     */
    this.docked = true;

    /**
     *  @ngdoc property
     *  @name toggled
     *  @propertyOf Boom.Controllers:BoomUpsellBannerController
     *  @returns {boolean} checks for modal status
     *  @description
     *    To determine whether banner is opened or closed based on status of the Boom Upsell Modal Component
     */
    this.toggled = false;

    /**
     *  @ngdoc property
     *  @name loading
     *  @propertyOf Boom.Controllers:BoomUpsellBannerController
     *  @returns {boolean} checks page load status
     *  @description
     *    To determine whether page is loading
     */
    this.loading = true;

    /* istanbul ignore next */
    this.$rootScope.$on('rootScope:closeBanner', (event, data) => {
      this.toggled = data;
      this.scrolled = false;
      this.docked = false;
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$onInit
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Initializes and listens for scroll
   */
  /* istanbul ignore next */
  $onInit() {
    if (this.UserAccountService.isRegistered()) {
      this.checkForTrial();
    }

    this.checkEnableUpsell();
    this.getDefaultUpsellCollection();

    let upsellBanner = angular.element(document.querySelector('.upsell-banner'));
    let bannerHeight = upsellBanner[0].clientHeight;

    angular.element(document).bind('scroll', (e) => {

      let scrollTop = e.target.documentElement.scrollTop || e.target.scrollingElement.scrollTop;
      let clientHeight = e.target.documentElement.clientHeight || e.target.scrollingElement.clientHeight;
      let scrollBottom = scrollTop + clientHeight;
      let upsellOffsetTop = upsellBanner[0].offsetTop + bannerHeight;

      this.scrolled = scrollBottom > clientHeight && scrollBottom <= upsellOffsetTop;
      this.docked = scrollBottom > upsellOffsetTop;
      this.$scope.$apply();
    });
  }

  getDefaultUpsellCollection() {
    const isEnglish = this.CollectionUpsellService.isEnglishActiveLanguage();

    this.CollectionService.get({
      id: `c247`
    })
      .then(collection => {
        this.defaultUpsellCollection = collection
        this.upsellCaption = isEnglish ? collection.metadata.upsellCaption : collection.metadata.upsellCaptionEs;
        this.upsellTitle = isEnglish ? collection.metadata.upsellTitle : collection.metadata.upsellTitleEs;
      });
  }
  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$setBannerImg
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Sets banner image based on current user states. Defaults to 'bugsBunny' if the franchise name returns 404
   */
  setBannerImg() {
    let bannerImg = ``;

    if (this.collection && this.collection.metadata.type !== "playlist") {
      bannerImg = this.collection.metadata.images.upsell_desktop.url;
      this.loading = false;
    } else if (this.defaultUpsellCollection) {
      bannerImg = this.defaultUpsellCollection.metadata.images.upsell_desktop.url;
      this.loading = false;
    }

    return `${bannerImg}?w=350&h=145`;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$checkEnableUpsell
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Checks for upsell setting on CMS
   */
  checkEnableUpsell() {
    this.CollectionUpsellService.checkEnableUpsell()
      .then(response => {
        this.enableUpsell = response;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$fetchUserTrialStatus
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Fetches, then sets user trial status
   */
  checkForTrial() {
    this.CollectionUpsellService.checkForTrial()
      .then(response => {
        this.hadFreeTrial = response;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$triggerUpsell
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Triggers Upsell modal from bannner
   */
  triggerUpsell() {
    this.CollectionUpsellService.triggerUpsell();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$checkForFranchise
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Checks for '-franchise' slug
   */
  checkForFranchise(slug) {
    return slug.indexOf('-franchise') > -1;
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomUpsellBannerController#$showingAgreementsModal
   *  @methodOf Boom.Controllers:BoomUpsellBannerController
   *  @description
   *    Indicates if the ToS m
   */
  showingAgreementsModal() {
    if (this.UserAccountService.isAnonymous() ||
      this.safeRoutes.includes(this.$state.current.name)) {
      return false;
    }

    const hasAgreed = this.AgreementModalService.isAgreedTerms();
    this.onAgreedTo = !hasAgreed && hasAgreed !== null;
    return this.onAgreedTo;
  }
}

export default BoomUpsellBanner;
