/**
 *  @ngdoc object
 *  @name Seed.Components:closeOverlay
 *  @module Seed
 *  @description
 *    Used to close a targetted overlay.
 */
import CloseOverlayController from './close-overlay-controller';

const CloseOverlayComponent = {
  bindings: {
    name: '<'
  },
  controller: CloseOverlayController,
  controllerAs: 'vm',
  templateUrl: 'components/overlay/close-overlay.html',
  transclude: true
};

export default CloseOverlayComponent;
