/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomCollectionUpsellController
*  @module Boom
*  @requires $rootScope
*  @requires UrlLanguage
*  @description
*    Responsible for subscribing a user.
*/
class BoomUpsellModalController {
  static get $inject() {
    return ['$http', '$rootScope', 'UrlLanguage', 'CollectionUpsellService', 'platformjs.collection', 'platformjs.series', 'platformjs.user.account'];
  }

  constructor($http, $rootScope, UrlLanguage, CollectionUpsellService, CollectionService, SeriesService, UserAccountService) {
    this.$http = $http;
    this.$rootScope = $rootScope;
    this.UrlLanguage = UrlLanguage;
    this.CollectionUpsellService = CollectionUpsellService;
    this.CollectionService = CollectionService;
    this.SeriesService = SeriesService;
    this.UserAccountService = UserAccountService;

    this.modalOpen = false;
    this.isEn = this.UrlLanguage.activeLanguage === 'en';
    this.hadFreeTrial = false;
    this.isFilm = false;
    this.loading = true;

    /* istanbul ignore next */
    this.$rootScope.$on('rootScope:openUpsell', (event, data) => {
      this.generateModal(data);
      this.openModal();
    });
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomCollectionUpsellController#onInit
  *  @methodOf Boom.Controllers:BoomCollectionUpsellController
  *  @param {object} profile The user's premium profile
  *  @description
  *    Calls for trial and fetches default collection upsell params onInit
  */
  $onInit() {
    if (this.UserAccountService.isRegistered()) {
      this.checkForTrial();
    }
    if (!this.UserAccountService.isPremium()) {
      this.fetchDefaultUpsell();
    }

    this.getDefaultUpsellCollection();
  }

  $onChanges() {
    if (this.series && this.isCollectionPlaylist === false) {
      this.CollectionService.get({
        id: `c${this.series.metadata.reporting_franchise_id}`
      })
        .then(collection => this.seriesCollection = collection);
    }
  }

  getDefaultUpsellCollection() {
    return this.CollectionService.get({
      id: `c247`
    })
      .then(collection => this.defaultUpsellCollection = collection);
  }

  setModalImg() {
    let bannerImg = ``;
    if (this.seriesCollection) {
      bannerImg = this.seriesCollection.metadata.images.upsell_desktop_full.url;
      this.loading = false;
    } else if (this.collection && this.collection.metadata.type !== "playlist") {
      bannerImg = this.collection.metadata.images.upsell_desktop_full.url;
      this.loading = false;
    } else {
      bannerImg = this.defaultUpsellCollection.metadata.images.upsell_desktop_full.url;
      this.loading = false;
    }

    return `${bannerImg}?w=539&h=518`;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#generateModal
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Generates modal using return data nad current language
   */
  generateModal(data) {
    this.loading = true;
    if (this.isCollectionPlaylist) {
      let defaultData = this.defaultUpsellCollection;
      this.upsellTitle = (this.isEn) ? defaultData.metadata.upsellTitle : defaultData.metadata.upsellTitleEs;
      this.upsellCta = (this.isEn) ? defaultData.metadata.upsellCta : defaultData.metadata.upsellCtaEs;
      this.upsellPricing = (this.isEn) ? defaultData.metadata.upsellPricing : defaultData.metadata.upsellPricingEs;
      this.upsellSubtitleLong = (this.isEn) ? defaultData.metadata.upsellSubtitleLong : defaultData.metadata.upsellSubtitleLongEs;
      this.upsellCaption = (this.isEn) ? defaultData.metadata.upsellCaption : defaultData.metadata.upsellCaptionEs;
      this.isFilm = false;
      this.loading = false;
    } else if (data && data.isFilm || data && data.thumbnail_type === 'movies') {
      this.resetAttributes();
      this.imageUrl = data.images.box.url;
      this.upsellSubtitleLong = this.upsellSubtitleShort;
      this.upsellTitle = 'Watch this movie now!';
      this.isFilm = true;
      this.loading = false;
    } else if (data && data.metadata && data.metadata.upsellCta && data.slug) {
      this.imageUrl = this.CollectionUpsellService.generateImageUrl(data.slug);
      this.upsellTitle = (this.isEn) ? data.metadata.upsellTitle : data.metadata.upsellTitleEs;
      this.upsellCta = (this.isEn) ? data.metadata.upsellCta : data.metadata.upsellCtaEs;
      this.upsellPricing = (this.isEn) ? data.metadata.upsellPricing : data.metadata.upsellPricingEs;
      this.upsellSubtitleLong = (this.isEn) ? data.metadata.upsellSubtitleLong : data.metadata.upsellSubtitleLongEs;
      this.upsellCaption = (this.isEn) ? data.metadata.upsellCaption : data.metadata.upsellCaptionEs;
      this.isFilm = false;
      this.loading = false;

    } else {
      this.resetAttributes();
      this.loading = false;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#checkForTrial
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Fetches and then sets the hadFreeTrial status from user.freeTrial
   */
  checkForTrial() {
    this.CollectionUpsellService.checkForTrial()
      .then(response => {
        this.hadFreeTrial = response;
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#fetchDefaultUpsell
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Fetches default upsell collection
   */
  fetchDefaultUpsell() {
    this.CollectionUpsellService.loadDefaultUpsell()
      .then(response => {
        this.default = response;
      })
      .catch(error => {
        this.default = {
          metadata: {
            upsellCaption: 'Start watching!',
            upsellCta: 'Start Free 7-Day Trial!',
            upsellPricing: 'After 1 week, plan starts at $4.99/month plus taxes.',
            upsellSubtitleLong: 'Get loads of classic cartoons and movies, including Scooby, Looney Tunes, and Tom & Jerry!',
            upsellSubtitleShort: ''
          }
        };
      });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#resetAttributes
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Resets attributes using default upsell collection params
   */
  resetAttributes() {
    let response = this.default;
    this.upsellTitle = (this.isEn) ? response.metadata.upsellCaption : response.metadata.upsellCaptionEs;
    this.upsellCta = (this.isEn) ? response.metadata.upsellCta : response.metadata.upsellCtaEs;
    this.upsellPricing = (this.isEn) ? response.metadata.upsellPricing : response.metadata.upsellPricingEs;
    this.upsellSubtitleLong = (this.isEn) ? response.metadata.upsellSubtitleLong : response.metadata.upsellSubtitleLongEs;
    this.upsellSubtitleShort = (this.isEn) ? response.metadata.upsellSubtitleShort : response.metadata.upsellSubtitleShortEs;
    this.isFilm = false;
    this.upsellCaption = '';
    this.imageUrl = 'bugsBunny';
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#closeModal
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Closes Modal
   */
  closeModal() {
    this.modalOpen = false;
    this.CollectionUpsellService.toggleBanner(this.modalOpen);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomCollectionUpsellController#openModal
   *  @methodOf Boom.Controllers:BoomCollectionUpsellController
   *  @description
   *    Closes Modal
   */
  openModal() {
    this.modalOpen = true;
    this.CollectionUpsellService.toggleBanner(this.modalOpen);
  }

  /**
  *  @ngdoc method
  *  @name Boom.Controllers:BoomCollectionUpsellController#getCta
  *  @methodOf Boom.Controllers:BoomCollectionUpsellController
  *  @param {object} profile The user's premium profile
  *  @description
  *    Sets Modal CTA
  */
  getCta() {
    return this.hadFreeTrial ? 'Subscribe' : this.upsellCta;
  }
}

export default BoomUpsellModalController;
