import ConfigLanguagesFunction from 'config-languages.module';

/* Import Seed Views */

import Error404State from 'views/404/boom-error-404-state';
import PlaylistsState from 'views/playlists/playlists-state';
import PlaylistDetailState from 'views/playlist/playlist-state';
import ShowsState from 'views/shows/shows-state';
import MoviesState from 'views/movies/movies-state';
import ForgotPasswordState from 'views/forgot-password/boom-forgot-password-state';
import HistoryState from 'views/history/boom-history-state';
import HomeState from 'views/home/boom-home-state';
import LanguageState from 'views/language/language-state';
import LoginState from 'views/login/login-state';
import WatchState from 'views/watch/boom-watch-state';

import AppApplicationController from 'controllers/app-application-controller';

/* Guide states */
import GuideState from 'views/guide/guide-state';
import ComponentsState from 'views/guide/components/components-state';
import GeneralState from 'views/guide/general/general-state';
import OverviewState from 'views/guide/overview/overview-state';

/* Import Custom Views */
import HelpRedirectState from 'views/help/help-redirect-state';
import FranchiseState from 'views/franchise/franchise-state';
import TermsState from 'views/terms/terms-state';
import PrivacyState from 'views/privacy/privacy-state';
import PrivacyOldState from 'views/privacy-old/privacy-old-state';
import SearchState from 'views/search/boom-search-state';
import HomeRedirectState from 'views/home/home-redirect-state';
import RedemptionState from 'views/redemption/boom-redemption-state';
import RedemptionWelcomeState from 'views/redemption/boom-welcome-state';
import RedirectedStates from 'views/redirected-states/redirects';
import WatchHomepageState from 'views/watch-homepage/watch-homepage-state';

//Import AnalyticsModule
import AnalyticsModule from 'modules/analytics/analytics';

const ViewsModule = angular.module('boom-watch.views', [AnalyticsModule.name])
  .config(ConfigLanguagesFunction)

  /* Register Seed Views */

  .config(Error404State)
  .config(MoviesState)
  .config(ForgotPasswordState)
  .config(HistoryState)
  .config(HomeState)
  .config(LanguageState)
  .config(LoginState)
  .config(WatchState)
  .config(PlaylistsState)
  .config(PlaylistDetailState)
  .config(ShowsState)

  /* add the Guide states */
  .config(GuideState)
  .config(ComponentsState)
  .config(GeneralState)
  .config(OverviewState)

  /* Register Custom Views */
  .config(FranchiseState)
  .config(HelpRedirectState)
  .config(TermsState)
  .config(PrivacyState)
  .config(PrivacyOldState)
  .config(SearchState)
  .config(HomeRedirectState)
  .config(RedemptionState)
  .config(RedemptionWelcomeState)
  .config(WatchHomepageState)
  .config(RedirectedStates)

  .controller('AppApplicationController', AppApplicationController);

export default ViewsModule;
