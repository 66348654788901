import BoomCollectionUpsellController from './boom-upsell-modal-controller';

const BoomUpsellModalComponent = {
  bindings: {
    series: '<',
    episode: '<',
    collection: '<',
    isCollectionPlaylist: '<'
  },
  controllerAs: 'vm',
  controller: BoomCollectionUpsellController,
  templateUrl: 'components/boom-upsell-modal/boom-upsell-modal.html'
};
export default BoomUpsellModalComponent;
