/**
 *  @ngdoc controller
 *  @name Boom.Controllers:SeriesViewController
 *  @module Boom
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @requires platformjs.collections
 *  @requires ProgressBarService
 *  @requires platformjs.series
 *  @description
 *    Responsible for displaying seasons for single series franchise.
 */
class SeriesViewController {

  static get $inject() {
    /*istanbul ignore next*/
    return [
      '$state',
      '$stateParams',
      'platformjs.series',
      'platformjs.collection',
      'ProgressBarService',
      'CollectionUpsellService',
      '$q'
    ];
  }

  /*istanbul ignore next*/
  constructor(
    $state,
    $stateParams,
    SeriesService,
    CollectionService,
    ProgressBarService,
    CollectionUpsellService,
    $q
  ) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.SeriesService = SeriesService;
    this.CollectionService = CollectionService;
    this.ProgressBarService = ProgressBarService;
    this.CollectionUpsellService = CollectionUpsellService;
    this.$q = $q;
    this.collection = null;
    this.loading = false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#$onInit
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Makes initial request for a collection object to display.
   */
  $onInit() {
    this.loading = true;
    this.ProgressBarService.start();
    this.activeSeasonNumber = Number(this.$stateParams.seasonNumber);
    this.page = this.$stateParams.page || 1;

    this.CollectionUpsellService.checkEnableUpsell().then(response => {
      this.upsell = response;
      this.loadCollection();
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#verifySeasonRange
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Verify the season is within the available range for the series
   */
  verifySeasonRange() {
    if (this.activeSeasonNumber > this.seasons.length || this.activeSeasonNumber < 1) {
      this.$state.go('404');
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadCollection
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Called on success of the initial request for collection using the $stateParams.collectionSlug
   *    Will call showCollection to load collection item for SeriesId
   */
  loadCollection() {
    var params = {
      type: 'curated',
      slug: this.$stateParams.collectionSlug
    };
    return this.CollectionService.getItems(params)
      .then(this.showCollection.bind(this))
      .catch(error => this.loadFailure(error))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#showCollection
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {object} collection Collection object
   *  @description
   *    Called on the success of the initial request for a loadCollection.
   *    Calls loadSeries.
   */
  showCollection(collection) {
    this.collectionItem = collection.collectionItems[0].item;
    if (this.$stateParams.seriesId) {
      this.collectionItem.id = this.$stateParams.seriesId;
    }
    this.loadSeries();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadSeries
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Called on showCollection.
   *    Will get Series to load series information.
   */
  loadSeries() {
    this.seriesId = this.collectionItem.id;

    return this.SeriesService.get(this.seriesId)
      .then(this.showSeries.bind(this))
      .catch(error => this.loadFailure(error, 'Failed loading a series.'))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#showSeries
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {object} series Series object
   *  @description
   *    Called on success of loadSeries
   *    Will set series object.
   *    Call loadEpisodes and loadSeasons
   */
  showSeries(series) {
    this.series = series;
    this.loadEpisodes();
    this.loadSeasons();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadEpisodes
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Called on showSeries.
   *    Will call getSeason to load episodes with season number.
   */
  loadEpisodes() {
    let params = {
      id: this.series.id,
      seasonNumber: this.activeSeasonNumber
    };

    return this.SeriesService.getSeason(params)
      .then(this.showEpisodes.bind(this))
      .catch(error => this.loadFailure(error, 'Failed loading episodes.'))
      .finally(() => this.ProgressBarService.complete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#showEpisodes
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {object} response Episode object
   *  @description
   *    Called on success of loadEpisodes.
   *    Sets the response object to the episode information and loads more episodes when needed.
   */
  showEpisodes(response) {
    this.episodesInSeason = [];

    this.appendEpisodes(response.episodes);

    if (response.numPages > 1) {
      this.loadMoreEpisodes(response.numPages);
    }
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadMoreEpisodes
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {Number} numPages The number of pages of episodes in the current season
   *  @description
   *    Called by showEpisodes.
   *    Will load pages 2 to numPages of episodes for the current season
   */

  loadMoreEpisodes(numPages) {
    let promises = [];
    let params = {
      id: this.series.id,
      seasonNumber: this.activeSeasonNumber
    };

    for (let page = 2; page <= numPages; page++) {
      promises.push(this.SeriesService.getSeason(
        Object.assign({}, params, {
          page
        })
      ));
    }

    if (promises.length > 0) {
      this.$q.all(promises).then((responses) => {
        responses.forEach((response) => {
          this.appendEpisodes(response.episodes);
        });
      });
    }
  }


  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#appendEpisodes
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {Array} episodes A list of episodes
   *  @description
   *    Called by showEpisodes and loadMoreEpisodes.
   *    Appends more episodes to episodesInSeason
   */
  appendEpisodes(episodes) {
    this.episodesInSeason = [...this.episodesInSeason, ...episodes];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadSeasons
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Called on showSeries.
   *    Will call getSeasons to load season information.
   */
  loadSeasons() {
    let config = {
      id: this.series.id,
      page: this.page
    };

    return this.SeriesService.getSeasons(config)
      .then(this.loadSeasonsSuccess.bind(this))
      .catch(errors => this.loadFailure(errors))
      .finally(() => this.loadSeasonsComplete());

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadSeasonsSuccess
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {object} response Season object
   *  @description
   *    Called on success of loadSeasons
   *    Sets response object to page, pages, and seasons.
   */
  loadSeasonsSuccess(response) {
    this.seasons = response.seasons;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#seasonChange
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {int} newActiveSeason integer
   *  @description
   *    Called onSeasonChange.
   *    Sets the current activeSeasonNumber to new activeSeasonNumber when onSeasonChange.
   */
  seasonChange(newActiveSeason) {
    this.activeSeasonNumber = newActiveSeason.seasonNumber;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadFailure
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @param {object} errors Error object
   *  @description
   *    Display errors
   */
  loadFailure(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SeriesViewController#loadSeasonsComplete
   *  @methodOf Boom.Controllers:SeriesViewController
   *  @description
   *    Called on
   */
  loadSeasonsComplete() {
    this.verifySeasonRange();
    this.loading = false;
    this.ProgressBarService.complete();
  }
}

export default SeriesViewController;
