class SwiftypeService {

  static get $inject() {
    return ['$http'];
  }

  constructor($http) {

    this.endpoint = 'https://api.swiftype.com/api/v1/public/engines/search.json';
    this.$http = $http;

    // more detail about search options, please go to https://swiftype.com/documentation/searching
    this.options = {
      engine_key: undefined,
      page: 1,
      per_page: 21,
      fetch_fields: undefined,
      highlight_fields: undefined,
      search_fields: undefined,
      functional_boosts: undefined,
      document_types: undefined,
      filters: undefined,
      precise_expiration: undefined,
      sort_field: undefined,
      sort_direction: undefined,
      facets: undefined,
      spelling: undefined,
      q: undefined
    };
  }

  query(config) {

    let param = angular.copy(this.options);
    param = angular.merge(param, config);

    return this.$http({
      method: 'POST',
      url: this.endpoint,
      data: param,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      transformRequest: function(data) {
        var result = JSON.stringify(data);
        return result;
      }
    });
  }
}

export default SwiftypeService;
