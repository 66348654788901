export function modifyUrlWithLocalization(image, activeLanguage) {
  if (!activeLanguage || activeLanguage === 'en' || typeof image !== 'string') {
    return image;
  }
  const index = image.lastIndexOf('.');
  const start = image.substring(0, index);
  const extension = image.substring(index);
  return `${start}_${activeLanguage}${extension}`;
}

export default {
  modifyUrlWithLocalization
};
