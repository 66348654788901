/**
 *  @ngdoc controller
 *  @name Seed.Controllers:HomeView
 *  @module Seed
 *  @description
 *    Responsible for managing the home view.
 */
class HomeViewController {

  static get $inject() {
    return ['platformjs.user.history'];
  }

  constructor(UserHistoryService) {

    this.items = [0, 1, 2, 3, 4, 5, 6];

    this.carouselItems = [
      {
        item: {
          title: 'title',
          slug: 'awesome-slug'
        },
        type: 'Series',
        metadata: {}
      },
      {
        item: {
          title: 'title',
          slug: 'awesome-slug'
        },
        type: 'Collection',
        metadata: {}
      },
      {
        item: {
          title: 'title',
          slug: 'awesome-slug'
        },
        type: 'Episode',
        metadata: {}
      },
      {
        item: {
          title: 'title',
          slug: 'awesome-slug'
        },
        type: 'Series',
        metadata: {}
      },
      {
        item: {
          title: 'title',
          slug: 'awesome-slug'
        },
        type: 'Collection',
        metadata: {}
      },
      {
        item: {
          title: 'title',
          slug: 'awesome-slug'
        },
        type: 'Episode',
        metadata: {}
      }
    ];

    this.UserHistoryService = UserHistoryService;
    this.historyItems = null;
  }

  complete() {
    this.loading = false;
  }

  showErrors(errors) {
    this.errors = errors;
  }

  showSuccess(history) {
    this.historyItems = history.recentlyWatched;
  }

  $onInit() {
    this.loading = true;
    this.UserHistoryService.recentlyWatched()
      .then((history) => this.showSuccess(history))
      .catch((errors) => this.showErrors(errors))
      .finally(() => this.complete());
  }
}

export default HomeViewController;
