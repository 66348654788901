/**
*  @ngdoc controller
*  @name Seed.Controllers:HistoryItem
*  @module Seed
*  @description
*    Responsible for managing the history view.
*/
class HistoryItemController {
  static get $inject() {
    return ['platformjs.episode', 'platformjs.images'];
  }

  constructor(EpisodeService, ImagesService) {
    this.EpisodeService = EpisodeService;
    this.ImagesService = ImagesService;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:HistoryItem
     *  @description
     *  @returns {array} Current history errors.
     */
    this.errors = [];

    /**
     *  @ngdoc property
     *  @name loading
     *  @propertyOf Seed.Controllers:HistoryItem
     *  @description
     *  @returns {boolean} Current loading state.
     */
    this.loading = false;

    /**
     *  @ngdoc property
     *  @name episodeNumber
     *  @propertyOf Seed.Controllers:HistoryItem
     *  @description
     *  @returns {integer} Episode number for history item.
     */
    this.episodeNumber = this.episodeNumber || null;

    /**
     *  @ngdoc property
     *  @name seriesId
     *  @propertyOf Seed.Controllers:HistoryItem
     *  @description
     *  @returns {integer} Series id for history item.
     */
    this.seriesId = this.seriesId || null;

    /**
     *  @ngdoc property
     *  @name seriesTitle
     *  @propertyOf Seed.Controllers:HistoryItem
     *  @description
     *  @returns {integer} Series title for history item.
     */
    this.seriesTitle = this.seriesTitle || null;

    /**
     *  @ngdoc property
     *  @name seriesArt
     *  @propertyOf Seed.Controllers:HistoryItem
     *  @description
     *  @returns {object} object containing series specific art assets.
     */
    this.seriesArt = {};
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryItem#complete
   *  @methodOf Seed.Controllers:HistoryItem
   *  @description
   *    Called on history complete.
   *    Sets {@link Seed.Controllers:HistoryItem#properties_loading loading} to false.
   */
  complete() {

    this.loading = false;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryItem#initialize
   *  @methodOf Seed.Controllers:HistoryItem
   *  @description
   *    Called on history load.
   *    Sets {@link Seed.Controllers:HistoryItem#properties_loading loading} to true and fetches the list of content.
   */
  $onInit() {

    this.loading = true;

    this.EpisodeService.get(this.seriesId, this.episodeNumber)
      .then(response => this.showSuccess(response))
      .catch(errors => this.showErrors(errors))
      .finally(() => this.complete());

    this.seriesArt = this.ImagesService.getAll({
      id: this.seriesId,
      assetType: 'series'
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryItem#failure
   *  @methodOf Seed.Controllers:HistoryItem
   *  @param {array} errors An array of errors.
   *  @description
   *    Called on history failure.
   *    Sets {@link Seed.Controllers:HistoryItem#properties_errors errors} to the passed errors parameter.
   */
  showErrors(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:HistoryItem#success
   *  @methodOf Seed.Controllers:HistoryItem
   *  @description
   *    Called on history success.
   *    Sets list of history items.
   */
  showSuccess(response) {

    this.episode = response;

  }
}

export default HistoryItemController;
