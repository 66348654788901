import BoomWatchMastheadController from './boom-watch-masthead-controller';

const WatchMastheadComponent = {
  bindings: {
    series: '<',
    collection: '<',
    state: '<',
    nextEpisodeNumber: '='
  },
  controller: BoomWatchMastheadController,
  controllerAs: 'vm',
  templateUrl: 'components/watch-masthead/watch-masthead.html',
  transclude: true
};

export default WatchMastheadComponent;
