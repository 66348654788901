
/**
 *  @ngdoc controller
 *  @name Boom.Controllers:MoviesView
 *  @module Boom
 *  @requires angular.$state
 *  @requires platformjs.collection
 *  @requires ProgressBarService
 *  @description
 *    Responsible for displaying Movies information.
 */
class MoviesViewController {

  static get $inject() {
    /* istanbul ignore next */
    return ['$state', '$stateParams', 'platformjs.collection', 'ProgressBarService', 'CollectionUpsellService'];
  }

  /* istanbul ignore next */
  constructor($state, $stateParams, CollectionService, ProgressBarService, CollectionUpsellService) {
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.ProgressBarService = ProgressBarService;
    this.CollectionService = CollectionService;
    this.CollectionUpsellService = CollectionUpsellService;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MoviesView#$onInit
   *  @methodOf Boom.Controllers:MoviesView
   *  @description
   *    Makes initial request.
   */
  $onInit() {
    this.page = this.$stateParams.page || 1;
    this.loading = true;
    this.ProgressBarService.start();

    this.CollectionUpsellService.checkEnableUpsell().then(response => {
      this.upsell = response;
      this.loadContent();
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MoviesView#$loadContent
   *  @methodOf Boom.Controllers:MoviesView
   *  @description
   *    Requests movies list.
   */
  loadContent() {

    this.loading = true;

    let config = {
      id: 'c196',
      slug: 'all-movies',
      page: this.page
    };

    this.CollectionService.getItems(config)
      .then((response) => this.showLoadSuccess(response))
      .catch((error) => this.showLoadErrors(error))
      .finally(() => this.showLoadComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MoviesView#$showLoadSuccess
   *  @methodOf Boom.Controllers:MoviesView
   *  @description
   *    Assign success response to the view.
   */
  showLoadSuccess(response) {
    this.movies = response.collectionItems;
    this.page = response.page;
    this.pages = response.numberOfPages;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MoviesView#$showLoadErrors
   *  @methodOf Boom.Controllers:MoviesView
   *  @description
   *    Assign error response to the view.
   */
  showLoadErrors(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MoviesView#$showLoadComplete
   *  @methodOf Boom.Controllers:MoviesView
   *  @description
   *    Requests complete, render on the page.
   */
  showLoadComplete() {
    this.loading = false;
    this.ProgressBarService.complete();
    this.CollectionUpsellService.listenForUpsellTrigger();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:MoviesView#$changePage
   *  @methodOf Boom.Controllers:MoviesView
   *  @description
   *    Requests the content by specified page number.
   */
  changePage(page) {
    this.page = page;
    this.loadContent();
  }

}

export default MoviesViewController;
