export default class PrivacyOldController {
  static get $inject() {
    return ['platformjs.agreements', '$sce', '$stateParams', 'platformjs.user'];
  }

  constructor(AgreementsService, $sce, $stateParams, User) {
    this.AgreementsService = AgreementsService;
    this.$sce = $sce;
    this.$stateParams = $stateParams;
    this.User = User;
    this.loading = false;
  }

  $onInit() {
    if (!this.User.profile) {
      this.User.setProfile({});
    }
    // Use the lang param from the language state to determine if we want to load a localized version
    const currentLang = this.User.profile.language;
    //platform will check the user profile, so we want to set it before making the call
    this.User.profile.language = this.$stateParams.lang;

    this.loading = true;
    this.AgreementsService.getPrivacy({
      flavor: 'watch-legacy'
    })
      .then(this.showPrivacyPolicy.bind(this))
      .catch(this.showError.bind(this))
      .finally(this.onLoaded.bind(this))
      .finally(() => {
        //set the language back after making the call so we dont mess with any other requests
        this.User.profile.language = currentLang;
      });
  }

  showPrivacyPolicy(pp) {
    if (pp.contentType === 'plain') {
      pp.text = pp.text.replace(new RegExp('\r?\n', 'g'), '<br />');
    }
    this.privacyPolicy = this.$sce.trustAsHtml(pp.text);
  }

  showError(err) {
    this.errors = err;
  }

  onLoaded() {
    this.loading = false;
  }
}
