/**
 *  @ngdoc controller
 *  @name Seed.Controllers:LoginView
 *  @module Seed
 *  @requires angular.$state
 *  @description
 *    Responsible for managing the login view.
 */
class LoginViewController {
  static get $inject() {
    return ['$state'];
  }
  constructor($state) {
    this.$state = $state;

    /**
     *  @ngdoc property
     *  @name errors
     *  @propertyOf Seed.Controllers:LoginView
     *  @description
     *  @returns {array} Current login errors.
     */
    this.errors = [];
  }
  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LoginView#showLoginFailure
   *  @methodOf Seed.Controllers:LoginView
   *  @param {array} errors An array of errors. 
   *  @description
   *    Sets {@link Seed.Controllers:LoginView#properties_errors errors} to the passed errors parameter.
   */
  showLoginFailure(errors) {

    this.errors = errors;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:LoginView#showLoginSuccess
   *  @methodOf Seed.Controllers:LoginView
   *  @description
   *    Redirects to the value of `$state.params.destinationState` with the value
   *    of `$state.params.destinationParams`.
   *
   */
  showLoginSuccess() {

    this.$state.go(this.$state.params.destinationState, this.$state.params.destinationParams);

  }
}

export default LoginViewController;
