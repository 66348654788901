import ShowsViewController from './shows-view-controller';

function ShowsState($stateProvider) {

  $stateProvider.state('shows', {
    parent: 'language',
    url: '/shows?page',
    controller: ShowsViewController,
    controllerAs: 'vm',
    templateUrl: 'views/shows/shows-view.html',
    segment: {
      name: 'All Shows Page'
    }
  });

}

ShowsState.$inject = ['$stateProvider'];

export default ShowsState;
