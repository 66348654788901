import SwiftypeTopSearchComponentController from './swiftype-top-search-controller';

const SwiftypeTopSearchComponent = {
  controller: SwiftypeTopSearchComponentController,
  bindings: {
    query: '=',
    loading: '=',
    onSearch: '&'
  },
  controllerAs: 'vm',
  templateUrl: 'components/swiftype/top-search/swiftype-top-search.html'
};

export default SwiftypeTopSearchComponent;
