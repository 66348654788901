/**
 *  @ngdoc controller
 *  @name Seed.Controllers:WatchMasthead
 *  @module Seed
 *  @description
 *    Responsible for managing watch masthead behavior
 */
class WatchMastheadController {
  static get $inject() {
    return ['$state', '$stateParams'];
  }

  constructor($state, $stateParams) {
    this.$state = $state;
    this.$stateParams = $stateParams;

    /**
     *  @ngdoc property
     *  @name series
     *  @propertyOf Seed.Controllers:WatchMasthead
     *  @description
     *  @returns {object} Current series object.
     */
    this.series = this.series || {};
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchMasthead#goToNextEpisode
   *  @propertyOf Seed.Controllers:WatchMasthead
   *  @param {integer} episodeNumber the id of the episode to change state to
   *  @description
   *    Redirects to play the next series/episode.
   *
   *  ***Usage***
   *  ```js
   *    goToNextEpisode();
   *  ```
   *
   */
  goToNextEpisode(episodeNumber) {
    if (!this.series.id || !episodeNumber) {
      return;
    }
    this.$state.go('watch.episode', {
      seriesId: this.series.id,
      episodeId: episodeNumber
    });
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchMasthead#play
   *  @propertyOf Seed.Controllers:WatchMasthead
   *  @description
   *    gets the next episode and calls to redirect to that state
   *
   *  ***Usage***
   *  ```js
   *    play();
   *  ```
   *
   */
  play() {
    if (!this.series.id) {
      return;
    }
    //if there is a specific episode, dont call to check the users history
    if (this.$stateParams.episodeId) {
      return this.goToNextEpisode(this.$stateParams.episodeId);
    }
    this.getNextEpisode(this.series.id).then(episodeNumber => this.goToNextEpisode(episodeNumber));
  }

}

export default WatchMastheadController;
