/**
 * This is the controller with dummy data for the guide component page.
 * Doesn't need to have tests.  It's literally only for styleguide.
*/

/* istanbul ignore next */
class ComponentGuideController {
  constructor() {
    this.seasons = [
      {
        seriesId: 1,
        seasonNumber: 1,
        approvedEpisodes: 13,
      },
      {
        seriesId: 1,
        seasonNumber: 2,
        approvedEpisodes: 13,
      },
      {
        seriesId: 1,
        seasonNumber: 3,
        approvedEpisodes: 13,
      },
      {
        seriesId: 1,
        seasonNumber: 4,
        approvedEpisodes: 13,
      },
      {
        seriesId: 1,
        seasonNumber: 5,
        approvedEpisodes: 13,
      },
      {
        seriesId: 6,
        seasonNumber: 6,
        approvedEpisodes: 13,
      },
    ];
    this.activeSeasonNumber = 1;
  }
  seasonChange(newActiveSeason) {
    this.activeSeasonNumber = newActiveSeason.seasonNumber;
  }
}

function ComponentsState($stateProvider) {

  $stateProvider.state('guide.components', {
    parent: 'language',
    url: '/components',
    templateUrl: 'views/guide/components/components.html',
    controller: ComponentGuideController,
    controllerAs: 'vm'
  });

}

ComponentsState.$inject = ['$stateProvider'];

export default ComponentsState;
