/**
 *  @ngdoc controller
 *  @name Seed.Controllers:CloseOverlay
 *  @module Seed
 *  @description
 *    Component that can set an overlay's state to closed
 */
class CloseOverlayController {
  static get $inject() {
    return ['OverlayService'];
  }

  constructor(OverlayService) {
    this.OverlayService = OverlayService;
  }

  closeOverlay() {
    if (!this.name) {
      return;
    }
    this.OverlayService.close(this.name);
  }
}

export default CloseOverlayController;
