class SwiftypeAutocompleteComponentController {
  constructor() {
    this.minLength = this.minLength || 2;
    this.onSearch = this.onSearch || angular.noop;
    this.onReset = this.onReset || angular.noop;
  }

  onKeyup(event) {
    const keycode = event.keyCode;
    // ignore shift key, arrow keys
    if (((keycode > 36) && (keycode < 41)) || (keycode == 16)) {
      return false;
    } else if (this.query.length > this.minLength) {
      this.onSearch();
    } else {
      this.onReset();
    }
  }
}


export default SwiftypeAutocompleteComponentController;
