import CollectionViewController from 'views/collection/collection-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:PlaylistsView
 *  @module Boom
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @requires platformjs.collection
 *  @requires ProgressBarService
 *  @description
 *    Responsible for displaying show information.
 */
class ShowsViewController extends CollectionViewController {

  static get $inject() {
    /* istanbul ignore next */
    return ['$stateParams', '$state', 'platformjs.collection', 'ProgressBarService', 'CollectionUpsellService'];
  }

  /* istanbul ignore next */
  constructor($stateParams, $state, CollectionService, ProgressBarService, CollectionUpsellService) {
    super($stateParams, $state, CollectionService, ProgressBarService);
    this.CollectionUpsellService = CollectionUpsellService;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistsView#$onInit
   *  @methodOf Boom.Controllers:PlaylistsView
   *  @description
   *    Makes initial request.
   */
  $onInit() {
    this.page = this.$stateParams.page || 1;
    this.loading = true;
    this.ProgressBarService.start();
    this.loadContent();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistsView#loadContent
   *  @methodOf Boom.Controllers:PlaylistsView
   *  @description
   *    Requests franchise list.
   */
  loadContent() {

    this.loading = true;

    let config = {
      id: 'c197',
      slug: 'all-playlists',
      page: this.page
    };

    this.CollectionService.getItems(config)
      .then((response) => this.showLoadSuccess(response))
      .catch((error) => this.showLoadErrors(error))
      .finally(() => this.showLoadComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistsView#showLoadSuccess
   *  @methodOf Boom.Controllers:PlaylistsView
   *  @param {object} response object
   *  @description
   *    Assign success response to the view.
   */
  showLoadSuccess(response) {
    this.playlists = response.collectionItems;
    this.page = response.page;
    this.pages = response.numberOfPages;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistsView#showLoadErrors
   *  @methodOf Boom.Controllers:PlaylistsView
   *  @param {object} error object
   *  @description
   *    Assign error response to the view.
   */
  showLoadErrors(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistsView#showLoadComplete
   *  @methodOf Boom.Controllers:PlaylistsView
   *  @description
   *    Requests complete, render on the page.
   */
  showLoadComplete() {
    this.loading = false;
    this.ProgressBarService.complete();
    this.CollectionUpsellService.listenForUpsellTrigger();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:PlaylistsView#changePage
   *  @methodOf Boom.Controllers:PlaylistsView
   *  @param {Number} page number
   *  @description
   *    Requests the content by specified page number.
   */
  changePage(page) {
    this.page = page;
    this.loadContent();
  }

}

export default ShowsViewController;
