import BoomWatchViewController from './boom-watch-view-controller';

function BoomWatchState($stateProvider) {

  $stateProvider
    .state('watch', {
      parent: 'language',
      url: '/watch/{seriesId:int}',
      controller: BoomWatchViewController,
      controllerAs: 'vm',
      templateUrl: 'views/watch/watch-view.html',
      params: {
        slug: {
          value: '',
          squash: true
        }
      }
    })
    .state('watch.episode', {
      url: '^/{lang:string}/watch/{seriesId:int}/{episodeNumber:int}',
      params: {
        lang: {
          value: 'en',
          squash: true
        },
        slug: {
          value: '',
          squash: true
        },
        collectionPage: {
          value: 1,
          squash: true
        }
      }
    });

}

BoomWatchState.$inject = ['$stateProvider'];

export default BoomWatchState;
