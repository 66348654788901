import BoomBrowseContentController from './boom-browse-content-controller';

let BoomBrowseComponent = {
  bindings: {
    slug: '<',
    type: '<',
    page: '<',
    onStart: '&',
    onError: '&',
    onSuccess: '&',
    onComplete: '&',
    paginationReload: '<',
    showThumbnailTag: '<'
  },
  controller: BoomBrowseContentController,
  controllerAs: 'vm',
  templateUrl: 'components/boom-browse-content/boom-browse-content.html'
};

export default BoomBrowseComponent;
