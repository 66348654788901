/**
*  @ngdoc controller
*  @name Boom.Controllers:TosPPBannerController
*  @module Boom
*  @description
*    Responsible for showing/hiding the tos/pp banner.
*/
export default class TosPPBannerController {
  static get $inject() {
    return [
      'AgreementModalService',
      'platformjs.user.account',
      '$state',
      '$window',
      'video.videoplayer',
      '$timeout'
    ];
  }

  constructor(AgreementModalService, UserAccountService, $state, $window, VideoPlayer, $timeout) {
    this.AgreementModalService = AgreementModalService;
    this.UserAccountService = UserAccountService;
    this.$state = $state;
    this.$window = $window;
    this.VideoPlayer = VideoPlayer;
    this.$timeout = $timeout;
    this.safeRoutes = [
      'terms',
      'privacy',
      'login'
    ];
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#shouldShow
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Shows if agreements are not agreed
   */
  shouldShow() {
    if (this.UserAccountService.isAnonymous() ||
      !this.AgreementModalService.agreementsReady ||
      this.safeRoutes.includes(this.$state.current.name)) {
      return false;
    }

    const hasAgreed = this.AgreementModalService.isAgreedTerms();
    this.onAgreedTo = !hasAgreed && hasAgreed !== null;
    if (this.onAgreedTo) {
      this.lockBody();
    }
    return this.onAgreedTo;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#lockBody
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Locks the body, prevents scrolling
   */
  lockBody() {
    this.$window.document.body.style.height = '100vh';
    this.$window.document.body.style.overflowY = 'hidden';
    this.$window.document.body.style.overflow = 'hidden';
    this.VideoPlayer.stop();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#unlockBody
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Unlocks the body, allows scrolling
   */
  unlockBody() {
    this.$window.document.body.style.height = '';
    this.$window.document.body.style.overflowY = '';
    this.$window.document.body.style.overflow = '';

    if (this.$state.current.name.includes('watch')) {
      this.$timeout(this.refreshLocation.bind(this), 500);
    }
  }

  refreshLocation() {
    this.$window.location.reload();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:TosPPBannerController#agreeTerms
   *  @methodOf Boom.Controllers:TosPPBannerController
   *  @description
   *    Agrees to terms, unlocks web app
   */
  agreeTerms($event) {
    if ($event) {
      $event.preventDefault();
    }

    this.AgreementModalService.agreeTerms();
    this.unlockBody();
  }
}
