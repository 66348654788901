/**
 *  @ngdoc controller
 *  @name Seed.Controllers:OpenOverlay
 *  @module Seed
 *  @description
 *    Component that can set an overlay's state to closed
 */
class OpenOverlayController {
  static get $inject() {
    return ['OverlayService'];
  }

  constructor(OverlayService) {
    this.OverlayService = OverlayService;
  }

  openOverlay() {
    if (!this.name) {
      return;
    }
    this.OverlayService.open(this.name);
  }
}

export default OpenOverlayController;
