import WatchMastheadController from 'components/watch-masthead/watch-masthead-controller';
/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomWatchMastheadController
 *  @module Boom
 *  @description
 *    Responsible for managing the watch masthead component
 */
class BoomWatchMastheadController extends WatchMastheadController {
  static get $inject() {
    return ['$state', '$stateParams', '$rootScope', 'platformjs.episode', '$location', '$anchorScroll'];
  }

  /* istanbul ignore next */
  constructor($state, $stateParams, $rootScope, EpisodeService, $location, $anchorScroll) {
    super($state, $stateParams);

    this.$rootScope = $rootScope;
    this.EpisodeService = EpisodeService;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;

    this.$rootScope.$watch(() => this.$state.params.episodeNumber, () => {
      this.$onChanges();
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomWatchMastheadController#$onChanges
   *  @methodOf Boom.Controllers:BoomWatchMastheadController
   *  @description
   *   Loads episode data when the state changes.
   */
  $onChanges() {
    //if we have both a seriesId and an episode Number
    if (this.$stateParams.seriesId && this.$stateParams.episodeNumber) {
      //and we haven't fetched an episode
      if (!this.activeEpisode ||
        //or we have fetched an episode but it no longer matches the series/episode in the url
        (this.activeEpisode && (this.activeEpisode.seriesId !== this.$stateParams.seriesId ||
        this.activeEpisode.number !== this.$stateParams.episodeNumber))) {

        //mock out the activeEpisode object so we dont fire multiple load calls
        //if onChanges triggers multiple times before the EpisodeService finishes getting the episode
        this.activeEpisode = {
          seriesId: this.$stateParams.seriesId,
          number: this.$stateParams.episodeNumber
        };
        //then we need to get the active episode
        this.EpisodeService.get(this.$stateParams.seriesId, this.$stateParams.episodeNumber, false)
          .then(episode => this.updateActiveEpisode(episode));
      }
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomWatchMastheadController#updateActiveEpisode
   *  @methodOf Boom.Controllers:BoomWatchMastheadController
   *  @param {object} episode response from EpisodeService.get
   *  @description
   *   Updates the mocked activeEpisode object with the actual data sent by the API.
   */
  updateActiveEpisode(episode) {
    //don't set the episode if it doesn't match what we already mocked.  This can happen if multiple calls
    //come back from EpisodeService.get out of order (user clicks to switch episodes too fast)
    if (this.activeEpisode && episode) {
      if (episode.seriesId !== this.activeEpisode.seriesId || episode.number !== this.activeEpisode.number) {
        return;
      }
    }
    this.activeEpisode = episode;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomWatchMastheadController#gotoEpisodes
   *  @methodOf Boom.Controllers:BoomWatchMastheadController
   *  @description
   *   Scrolls to the episode listing.
   */
  gotoEpisodes() {
    const old = this.$location.hash();
    this.$anchorScroll.yOffset = angular.element(document.querySelectorAll('.site-header')[0]);
    this.$location.hash('episodes-list');
    this.$anchorScroll();
    this.$location.hash(old);
  }

}

export default BoomWatchMastheadController;
