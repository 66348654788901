class SwiftypeTopSearchComponentController {
  static get $inject() {
    return ['$timeout'];
  }

  constructor($timeout) {
    this.$timeout = $timeout;
    this.query = this.query || '';
    this.loading = this.loading || false;
    this.onSearch = this.onSearch || angular.noop;
  }

  search(event) {
    this.query = event.target.innerText;
    this.loading = true;
    this.$timeout(this.onSearch, 500);
  }

}

export default SwiftypeTopSearchComponentController;
