import Error404ViewController from 'views/404/boom-error-404-view-controller';

function BoomError404State($stateProvider) {
  $stateProvider.state('404', {
    controller: Error404ViewController,
    controllerAs: 'vm',
    templateUrl: 'views/404/boom-error-404-view.html'
  });
}

BoomError404State.$inject = ['$stateProvider'];

export default BoomError404State;
