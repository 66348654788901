import CollectionViewController from 'views/collection/collection-view-controller';
import { modifyUrlWithLocalization } from '../../localized-images';

//first in array has highest priority
const collectionPriority = ['series', 'movies', 'playlists'];

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:FranchiseViewController
 *  @module Boom
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @requires platformjs.collections
 *  @requires ProgressBarService
 *  @description
 *    Responsible for managing the franchise view.
 */
class FranchiseViewController extends CollectionViewController {
  static get $inject() {
    return ['$stateParams', '$state', 'platformjs.collection', 'ProgressBarService', 'UrlLanguage', 'CollectionUpsellService'];
  }
  /* istanbul ignore next */
  constructor($stateParams, $state, CollectionService, ProgressBarService, UrlLanguage, CollectionUpsellService) {
    super($stateParams, $state, CollectionService, ProgressBarService);
    this.UrlLanguage = UrlLanguage;
    this.CollectionUpsellService = CollectionUpsellService;
    this.collection = null;
    this.collectionItems = [];
    this.volumeGuid = this.$stateParams.volumeGuid;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#getVolumeInfoFromVolumeGuid
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {string} volumeGuid A guid of 'seriesId.seasonNumber'
   *  @description
   *    Parses out the volume info from the volumeGuid
   */
  getVolumeInfoFromVolumeGuid(volumeGuid) {
    const volumeGuidParts = volumeGuid.split('.');
    if (volumeGuidParts.length !== 2) {
      return;
    }

    return {
      seriesId: volumeGuidParts[0],
      seasonNumber: volumeGuidParts[1]
    };
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#showCollection
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {object} collection Collection object
   *  @description
   *    Called on the success of the initial request for a collection.
   */
  showCollection(collection) {
    this.collection = collection;
    this.$state.get('franchise.subcollection').data.parentCollection = this.collection;
    this.CollectionUpsellService.listenForUpsellTrigger();
    return this.CollectionService.getItems({
      slug: this.collection.slug
    }).then((collectionItems) => this.showItems(collectionItems));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#showItems
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {object} response Response object from CollectionService.getItems
   *  @description
   *    Called on the success of the initial request for a collection.  Sets the collectionItems
   *    on the controller and calls to redirect if we need to change the child state. Otherwise just sets
   *    the active sub collection.
   */
  showItems(response) {
    if (!response || !response.collectionItems || !response.collectionItems.length) {
      return this.showCollectionFailure();
    }

    this.collectionItems = response.collectionItems;
    this.$state.get('franchise.subcollection').data.parentCollectionItems = this.collectionItems;

    //if we don't have a collection type and collection slug, we want to redirect to.. something
    if (!this.$stateParams.collectionType && !this.$stateParams.collectionSlug) {
      this.determineRedirect();
    } else {
      this.setActiveSubCollectionType(this.$stateParams.collectionType);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#getHighestPrioritySubCollection
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {array} subCollections Array of collections
   *  @returns {object|Boolean} The highest priority collection object or false if there is no valid collection
   *  @description
   *    Uses the collectionPriority to determine which collection in the array of subCollections
   *    has the highest priority.
   */
  getHighestPrioritySubCollection(subCollections) {
    if (!subCollections || !subCollections.length) {
      return false;
    }

    let highestPrioritySubCollectionIndex = null;

    subCollections.forEach((subCollection, i) => {
      if (!subCollection.item || !subCollection.item.metadata || !subCollection.item.metadata.container) {
        return false;
      }
      //check the priority in the collectionPriority array
      let subCollectionPriority = collectionPriority.indexOf(subCollection.item.metadata.container);
      //if it isn't there (for some reason) drop it to the lowest
      if (subCollectionPriority === -1) {
        subCollectionPriority = collectionPriority.length + 1;
      }
      //see if its higher priority (lower index) than the one we have, if so, set it to be the new highest priority
      if (highestPrioritySubCollectionIndex === null ||
        subCollectionPriority < collectionPriority.indexOf(subCollections[highestPrioritySubCollectionIndex].item.metadata.container)) {
        highestPrioritySubCollectionIndex = i;
      }
    });

    const highestPrioritySubCollection = subCollections[highestPrioritySubCollectionIndex];

    return highestPrioritySubCollection || false;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#redirectToSubCollection
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {object} targetCollcetion Target collection to redirect to
   *  @description
   *    Redirects the state to a subcollection view passing params from the targetCollection
   */
  redirectToSubCollection(targetCollection) {
    let subCollectionInfo = {
      collectionType: targetCollection.item.metadata.container,
      collectionSlug: targetCollection.item.slug,
      page: 1
    };
    if (this.volumeInfo) {
      subCollectionInfo.seasonNumber = this.volumeInfo.seasonNumber;
      subCollectionInfo.seriesId = this.volumeInfo.seriesId;
    }
    this.$state.go('.subcollection', subCollectionInfo, {
      location: 'replace'
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#getSeriesCollection
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {object} subCollections List of collections in the franchise
   *  @description
   *    Goes through franchise collections and return the series collection
   */
  getSeriesCollection(subCollections) {
    return subCollections.find(
      item => item.item.metadata.type === 'series'
    );
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#redirectToMultiSeriesVolume
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @description
   *    Builds and fires off a redirect to the franchise multiseries view
   */
  redirectToMultiSeriesVolume() {
    this.volumeInfo.seriesSlug = this.getSeriesCollection(this.collectionItems).item.slug;

    this.CollectionService.getItems({
      slug: this.volumeInfo.seriesSlug
    }).then((items) => {
      this.volumeInfo.subFranchiseSlug = items.collectionItems.find(item => item.item.id === parseInt(this.volumeInfo.seriesId)).item.slug;
      this.$state.go('franchise.multiSeries',
        {
          collectionSlug: this.volumeInfo.seriesSlug,
          seasonNumber: this.volumeInfo.seasonNumber,
          subFranchise: this.volumeInfo.subFranchiseSlug,
          seriesId: this.volumeInfo.seriesId
        },
        {
          location: 'replace'
        });

    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#determineRedirect
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @description
   *    Determines the target collection from the collectionItems on the controller
   *    that we want to redirect to.  Validates the necessary properties on that collection
   *    and calls to redirect and set the collection type.
   */
  determineRedirect() {
    if (this.volumeGuid) {
      this.volumeInfo = this.getVolumeInfoFromVolumeGuid(this.volumeGuid);
    }

    let targetCollection;
    //If it contains only one Collection
    if (this.collectionItems.length === 1) {
      //And that Collection is a playlist or Series Collection
      //Forward to Franchise Series/Playlist page:
      // /shows/{franchise slug}/{collection type}/{collection id}

      //And that Collection is a Movies Collection
      //Forward to the Movie Collection page
      // /shows/{franchise slug}/{collection type}/{collection id}
      targetCollection = this.collectionItems[0];
    } else {
      if (this.volumeInfo) {
        return this.redirectToMultiSeriesVolume();
      }
      //If there is more than one collection
      //Display tabs (special treatment for tabs that don’t exist???)
      //Forward to first sub collection page ( series, movies, in that order):
      //    /shows/{franchise slug}/{collection type}/{collection id}
      targetCollection = this.getHighestPrioritySubCollection(this.collectionItems);
    }

    if (!targetCollection ||
      !targetCollection.item ||
      !targetCollection.item.metadata ||
      !targetCollection.item.slug ||
      !targetCollection.item.metadata.container) {
      return this.showCollectionFailure();
    }

    this.redirectToSubCollection(targetCollection);
    this.setActiveSubCollectionType(targetCollection.item.metadata.container);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#setActiveSubCollectionType
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @param {string} type The type of the active sub collection
   *  @description
   *    Sets the activeSubCollectionType on the controller to whatever is passed.
   */
  setActiveSubCollectionType(type) {
    this.activeSubCollectionType = type;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:FranchiseViewController#getLocalizedImage
   *  @methodOf Boom.Controllers:FranchiseViewController
   *  @description
   *    Localizes an image url
   */
  getLocalizedImage(image) {
    return modifyUrlWithLocalization(image, this.UrlLanguage.activeLanguage);
  }
}

export default FranchiseViewController;
