/* @ngInject */
FranchiseFactory.$inject = ['platformjs.collections2'];

function FranchiseFactory(CollectionsFactory) {

  const Franchise = () => {
    const franchise = new CollectionsFactory({
      tag: 'type',
      value: 'franchise'
    });

    return {
      list: franchise.list
    };
  };

  return Franchise;

}

export default FranchiseFactory;
