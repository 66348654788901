import CarouselController from './boom-carousel-controller';

const BoomCarouselComponent = {
  bindings: {
    options: '<',
    items: '<',
    collection: '<',
    template: '='
  },
  controller: CarouselController,
  controllerAs: 'vm',
  templateUrl: 'components/carousel/carousel.html',
  transclude: true
};

export default BoomCarouselComponent;
