import ThumbnailController from '../../thumbnail/thumbnail-controller';

const SwiftypeThumbnailComponent = {
  bindings: {
    series: '<',
    collection: '<',
    episode: '<',
    movie: '<',
    slug: '<',
    page: '<',
    replace: '<'
  },
  controller: ThumbnailController,
  controllerAs: 'vm',
  templateUrl: ['$element', '$attrs', function($element, $attrs) {
    if ($attrs.collection) {
      return 'components/thumbnail/collection-thumbnail.html';
    } else if ($attrs.episode) {
      return 'components/swiftype/thumbnail/swiftype-episode-thumbnail.html';
    } else if ($attrs.movie) {
      return 'components/swiftype/thumbnail/swiftype-movie-thumbnail.html';
    } else {
      return 'components/thumbnail/series-thumbnail.html';
    }
  }]
};

export default SwiftypeThumbnailComponent;
