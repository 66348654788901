/* Import Seed Filters */

import BuildPathFilter from 'filters/build-path/build-path-filter';
import OnlyDecimalFilter from 'filters/only-decimal/only-decimal-filter';
import OnlyDollarFilter from 'filters/only-dollar/only-dollar-filter';
import JpgToPng from 'filters/jpg-to-png/jpg-to-png';
import TimecodeFilter from 'filters/timecode/timecode';

/* Import Custom Filters */


const FiltersModule = angular.module('boom-watch.filters', [])

  /* Register Seed Filters */

  .filter('buildPath', BuildPathFilter)
  .filter('jpgToPng', JpgToPng)
  .filter('onlyDecimal', OnlyDecimalFilter)
  .filter('onlyDollar', OnlyDollarFilter)
  .filter('timecode', TimecodeFilter);

/* Register Custom Filters */


export default FiltersModule;
