/**
 *  @ngdoc controller
 *  @name Seed.Controllers:CollectionView
 *  @module Seed
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @requires platformjs.collections
 *  @requires ProgressBarService
 *  @description
 *    Responsible for managing the single collection view.
 */
class CollectionViewController {
  static get $inject() {
    return ['$stateParams', '$state', 'platformjs.collection', 'ProgressBarService'];
  }

  constructor($stateParams, $state, CollectionService, ProgressBarService) {
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.CollectionService = CollectionService;
    this.ProgressBarService = ProgressBarService;

    /**
     *  @ngdoc property
     *  @name collection
     *  @propertyOf Seed.Controllers:CollectionView
     *  @returns {object} Collection
     *  @description
     *    Currently viewed collection object.
     */
    this.collection = null;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:CollectionView#$onInit
   *  @methodOf Seed.Controllers:CollectionView
   *  @description
   *    Makes initial request for a collection object to display.
   */
  $onInit() {

    this.ProgressBarService.start();

    this.loadCollection(this.$stateParams.type, this.$stateParams.slug)
      .then(collection => this.showCollection(collection))
      .catch(error => this.showCollectionFailure(error))
      .finally(() => this.ProgressBarService.complete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:CollectionView#loadCollection
   *  @methodOf Seed.Controllers:CollectionView
   *  @param {string} type Collection type
   *  @param {string} slug Collection slug
   *  @description
   *    Request collection with matching slug and type.
   */
  loadCollection(type, slug) {

    var params = {
      type: type,
      slug: slug
    };

    return this.CollectionService.get(params);

  }


  /**
   *  @ngdoc method
   *  @name Seed.Controllers:CollectionView#showCollection
   *  @methodOf Seed.Controllers:CollectionView
   *  @param {object} collection Collection object
   *  @description
   *    Called on the success of the initial request for a collection.
   */
  showCollection(collection) {

    this.collection = collection;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:CollectionView#showCollectionFailure
   *  @methodOf Seed.Controllers:CollectionView
   *  @description
   *    Redirects to 404 page.
   */
  showCollectionFailure(error) {

    this.$state.go('404');

  }
}

export default CollectionViewController;
