import HistoryItemController from 'components/history-item/history-item-controller';
import { modifyUrlWithLocalization } from '../../localized-images';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomHistoryItemController
 *  @module Boom
 *  @description
 *    Responsible for managing a history item component
 */
export default class BoomHistoryItemController extends HistoryItemController {
  static get $inject() {
    return ['platformjs.episode', 'platformjs.images', 'UrlLanguage'];
  }
  /* istanbul ignore next */
  constructor(EpisodeService, ImagesService, UrlLanguage) {
    super(EpisodeService, ImagesService);
    this.UrlLanguage = UrlLanguage;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomHistoryItemController#getLocalizedImage
   *  @methodOf Boom.Controllers:BoomHistoryItemController
   *  @param {string} image The url for the image to localize
   *  @description
   *    Localizes an image url
   */
  getLocalizedImage(image) {
    return modifyUrlWithLocalization(image, this.UrlLanguage.activeLanguage);
  }
}
