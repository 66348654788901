/**
 *  @ngdoc controller
 *  @name Seed.Controllers:WatchView
 *  @module Seed
 *  @requires angular.$rootScope
 *  @requires angular.$scope
 *  @requires angular.$state
 *  @requires angular.$q
 *  @requires angular.$window
 *  @requires moment
 *  @requires platformjs.collection
 *  @requires platformjs.series
 *  @requires platformjs.user.history
 *  @requires video.videoplayer
 *  @requires ProgressBarService
 *  @description
 *    Responsible for displaying watch information.
 */
class WatchViewController {
  static get $inject() {
    return [
      '$rootScope',
      '$scope',
      '$state',
      '$q',
      '$window',
      'moment',
      'platformjs.collection',
      'platformjs.series',
      'platformjs.user.history',
      'video.videoplayer',
      'ProgressBarService'];
  }
  constructor(
    $rootScope,
    $scope,
    $state,
    $q,
    $window,
    moment,
    CollectionService,
    SeriesService,
    UserHistoryService,
    VideoPlayer,
    ProgressBarService) {

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this.$window = $window;
    this.moment = moment;
    this.CollectionService = CollectionService;
    this.SeriesService = SeriesService;
    this.UserHistoryService = UserHistoryService;
    this.VideoPlayer = VideoPlayer;
    this.ProgressBarService = ProgressBarService;


    /**
     *  @ngdoc property
     *  @name series
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {object} Series
     *  @description
     *    Currently viewed series object.
     */
    this.series = null;

    /**
     *  @ngdoc property
     *  @name seriesPage
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {Boolean} Show series page
     *  @description
     *    Determines whether or not to show series page or to load episode to watch
     */
    this.seriesPage = true;

    /**
     *  @ngdoc property
     *  @name videoId
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    The id of the video element.
     */
    this.videoId = 'video-holder';

    /**
     *  @ngdoc property
     *  @name collection
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {object} Collection
     *  @description
     *    Currently viewed collection object.
     */
    this.collection = null;

    /**
     *  @ngdoc property
     *  @name originalEpisodes
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {object} Original Episodes
     *  @description
     *    Original episodes displayed for view
     */
    this.originalEpisodes = null;

    /**
     *  @ngdoc property
     *  @name autoplayEpisodes
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {object} Autoplay Episodes
     *  @description
     *    Episodes use to determine autoplay and next/previous episodes
     */
    this.autoplayEpisodes = null;

    /**
     *  @ngdoc property
     *  @name videoInit
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {boolean} videoInit
     *  @description
     *    Determines whether video has been initialized or not (only set to true when video player
     *    has been initialized)
     */
    this.videoInit = false;

    /**
     *  @ngdoc property
     *  @name episodeSort
     *  @propertyOf Seed.Controllers:WatchView
     *  @returns {string} episodeSort
     *  @description
     *    Determines whether video has been initialized or not (only set to true when video player
     *    has been initialized)
     *
     *    Values:
     *      asc = ascending
     *      desc = descending
     *
     *    ** Note - More sorting options will be added in the future
     */
    this.episodeSort = 'asc';

    /**
     *  @ngdoc property
     *  @name config
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    The config object of the video.
     */
    this.config = {
      useHistory: true,
      exitFullscreenOnStop: false,
      overlays: [
        {
          id: 'paywall',
          start: 'paywall',
          end: 'play',
          align: 'all'
        },
        {
          id: 'regwall',
          start: 'regwall',
          end: 'play',
          align: 'all'
        },
        {
          id: 'play-button-overlay',
          start: 'pause',
          end: 'play',
          align: 'all'
        },
        {
          id: 'play-button-load-overlay',
          start: 'mobilepreplay',
          end: 'play',
          align: 'all'
        }
      ],
      events: {
        load: () => this.resetUseHistory(),
        ended: () => this.autoplay()
      }
    };

    /**
     *  @ngdoc property
     *  @name loadLimit
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    We want to see 6 episodes per view unless collection
     */
    this.loadLimit = this.getSlug() ? 20 : 6;

    /**
     *  @ngdoc property
     *  @name page
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Current episode page
     */
    this.page = 1;

    /**
     *  @ngdoc property
     *  @name pages
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Number of episode pages
     */
    this.pages = 1;

    // number of video clips to show per page
    /**
     *  @ngdoc property
     *  @name pages
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Number of episode pages
     */
    this.clipLimit = 3;

    /**
     *  @ngdoc property
     *  @name clipPage
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Current clip page
     */
    this.clipPage = 1;

    /**
     *  @ngdoc property
     *  @name clipPages
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Number of clip pages
     */
    this.clipPages = 1;

    /**
     *  @ngdoc property
     *  @name hasClips
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Determines whether or not to show clips
     */
    this.hasClips = false;

    /**
     *  @ngdoc property
     *  @name nextEpisode
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Next episode available - will be null if none available
     */
    this.nextEpisode = null;

    /**
     *  @ngdoc property
     *  @name nextEpisode
     *  @propertyOf Seed.Controllers:WatchView
     *  @description
     *    Previous episode available - will be null if none available
     */
    this.previousEpisode = null;

    // when go back to watch state, we want to stop the video
    this.$scope.$watch(() => this.$state.params.episodeNumber, () => this.watch());
    this.$scope.$watch(() => this.$state.params.clipId, () => this.watchClipId());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#watch
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Watch if episode number has changed
   */
  watch() {

    if (this.$state.current.name === 'watch.clip') {
      return;
    }

    if (!this.getEpisodeNumber()) {
      this.VideoPlayer.stop();
      return;
    } else if (this.videoInit) {
      this.$onChanges();
    } else {
      this.videoInit = true;
    }

    this.setPreviousAndNext();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#watchClipId
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Watch if clip id has changes
   */
  watchClipId() {

    if (this.$state.current.name !== 'watch.clip') {
      return;
    }

    if (!this.getClipId()) {
      this.VideoPlayer.stop();
    } else {
      this.videoInit = true;
    }
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#$onInit
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Makes initial request for a series object to display.
   */
  $onInit() {

    this.ProgressBarService.start();

    const nextEpisodeSuccess = (episodeNumber) => {
      this.nextEpisodeNumber = episodeNumber;
    };

    if (!this.getEpisodeNumber()) {
      // we want to redirect to the episode view asap
      this.getNextEpisode(this.getSeriesId())
        .then(nextEpisodeSuccess)
        .finally(() => this.initSeries());
    } else {
      this.videoInit = true;
    }
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#$onChange
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Makes change request for a series or collection object to display.
   */
  $onChanges() {

    if (!this.getCollectionPage() || this.reloadEpisodes(this.getCollectionPage())) {

      this.ProgressBarService.start();

      if (this.getSlug()) {
        this.initCollection();
      } else {
        this.initSeries();
      }

    }

    this.originalEpisodes = null;
    this.originalPage = this.page;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#initSeries
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Makes initial request for a series object to display.
   */
  initSeries() {

    this.loadSeries(this.getSeriesId())
      .then(series => this.showSeries(series))
      .catch(() => this.showSeriesFailure())
      .finally(() => this.ProgressBarService.complete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#initCollection
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Makes initial request for a collection object to display.
   */
  initCollection() {

    this.loadCollection('', this.getSlug())
      .then(collection => this.showCollection(collection))
      .catch(() => this.showCollectionFailure())
      .finally(() => this.ProgressBarService.complete());

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#episodeId
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {number} `$state.params.episodeNumber`
   *  @description
   *    Returns the current `episodeNumber` state parameter
   *
   *    //TODO: Deprecate in 2.0.x.  Remove in 3.0
   */
  getEpisodeId() {

    return this.$state.params.episodeNumber;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#episodeNumber
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {number} `$state.params.episodeNumber`
   *  @description
   *    Returns the current `episodeNumber` state parameter
   */
  getEpisodeNumber() {

    return this.$state.params.episodeNumber;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#getClipId
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {number} `$state.params.clipId`
   *  @description
   *    Returns the current `clipId` state parameter
   */
  getClipId() {
    return this.$state.params.clipId;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#getSeriesId
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {number} `$state.params.seriesId`
   *  @description
   *    Returns the current `seriesId` state parameter
   */
  getSeriesId() {

    return this.$state.params.seriesId;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#getSeriesId
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {number} `$state.params.slug`
   *  @description
   *    Returns the current `slug` state parameter
   */
  getSlug() {

    return this.$state.params.slug;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#getPage
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {number} `$state.params.page`
   *  @description
   *    Returns the current `page` state parameter
   */
  getCollectionPage() {

    return this.$state.params.collectionPage;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#loadSeries
   *  @methodOf Seed.Controllers:WatchView
   *  @param {number} seriesId Series ID
   *  @returns {object} Promise will resolve to series.
   *  @description
   *    Request series object with passed id.
   */
  loadSeries(seriesId) {

    return this.SeriesService.get(seriesId);

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showSeries
   *  @methodOf Seed.Controllers:WatchView
   *  @param {object} series Series object
   *  @description
   *    Assigns passed object to series property.
   */
  showSeries(series) {

    this.series = series;
    this.loadEpisodes();
    if (this.hasClips) {
      this.loadClips();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#loadCollection
   *  @methodOf Seed.Controllers:WatchView
   *  @param {string} type Collection type
   *  @param {string} slug Collection slug
   *  @description
   *    Request collection with matching slug and type.
   */
  loadCollection(type, slug) {

    return this.CollectionService.get({
      type,
      slug
    });

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showCollection
   *  @methodOf Seed.Controllers:WatchView
   *  @param {object} collection Collection object
   *  @description
   *    Assigns passed object to collection property.
   */
  showCollection(collection) {

    this.collection = collection;
    this.loadCollectionItems();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#loadEpisodes
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Used to query all of the series' episodes
   */
  loadEpisodes() {

    this.series.fetchEpisodes()
      .then(episodes => this.showEpisodes(episodes))
      .catch(() => this.loadEpisodesFailure())
      .finally(() => this.episodesFinally());;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#loadEpisodesFailure
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Redirects to 404 page.
   */
  loadEpisodesFailure() {

    this.$state.go('404');

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#episodesFinally
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Turn off progress bar and go to next episode if applicable
   */
  episodesFinally() {
    if (this.nextEpisodeNumber && this.skipSeriesPage()) {
      this.goToNextEpisode();
    }
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showEpisodes
   *  @methodOf Seed.Controllers:WatchView
   *  @param {object} episodes Episodes object
   *  @description
   *    Assigns episodes from passed object to episodes array.
   */
  showEpisodes(episodes) {
    this.autoplayEpisodes = episodes;

    if (this.episodeSort === 'desc') {
      this.episodes = episodes.slice().reverse();
    } else {
      this.episodes = episodes;
    }

    let currentEpisodeNumber = this.getEpisodeNumber();

    let index = 1;
    // find current episode in episodes array
    for (let i = 0; i < this.episodes.length; i++) {
      if (currentEpisodeNumber === this.episodes[i].number) {
        index = i + 1;
        break;
      }
    }

    // figure out next episode number if no user history
    if (!this.nextEpisodeNumber && !currentEpisodeNumber) {
      this.nextEpisodeNumber = this.autoplayEpisodes[0].number;
    }

    // Figure out previous and next episodes if we are showing a series page
    this.setPreviousAndNext();

    // initial page
    this.page = Math.ceil(index / this.loadLimit);

    // total number of pages
    this.pages = Math.ceil(episodes.length / this.loadLimit);

    this.changePage(this.page);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#loadClips
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Loads array of clip objects associated to the series
   */
  loadClips() {
    this.SeriesService.getClips(this.series.id)
      .then(clips => this.showClips(clips));
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showClips
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Assigns clips from passed object to clips array, and calculate clips pagination
   */
  showClips(clips) {

    this.clips = clips.clips;

    let currentClipId = this.getClipId();
    let index = 1;
    // find current clip in clips array
    for (let i = 0; i < this.clips.length; i++) {
      if (currentClipId === this.clips[i].id) {
        index = i + 1;
        break;
      }
    }

    // initial page
    this.clipPage = Math.ceil(index / this.clipLimit);

    // total number of clip pages
    this.clipPages = Math.ceil(this.clips.length / this.clipLimit);

    this.changeClipPage(this.clipPage);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#loadCollectionItems
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Calls `CollectionService.getItems`.
   */
  loadCollectionItems() {

    const params = {
      page: this.page,
      slug: this.collection.slug
    };

    this.CollectionService.getItems(params)
      .then(collection => this.showCollectionItems(collection));

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showCollectionItems
   *  @methodOf Seed.Controllers:WatchView
   *  @param {object} collection Collection object
   *  @description
   *    Assigns collection from passed object to episodes array.
   */
  showCollectionItems(collection) {
    this.episodes = collection.collectionItems;
    this.pages = collection.numberOfPages;
  }


  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#changePage
   *  @methodOf Seed.Controllers:WatchView
   *  @param {number} page Page number
   *  @description
   *   Called when new page is needed, calls loadEpisodes() or loadCollectionItems() to load next set of episodes
   */
  changePage(page) {

    if (this.videoInit) {
      this.saveOriginalEpisodes();
    }

    this.page = page;
    if (this.getSlug()) {
      this.loadCollectionItems();
    } else {
      let startIndex = (page - 1) * this.loadLimit;

      this.page = page;

      // Get clips according to page number
      this.episodesToDisplay = this.episodes.slice(startIndex, startIndex + this.loadLimit);
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#changeClipPage
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Gets clips according to page number upon clip page change
   */
  changeClipPage(page) {
    let startIndex = (page - 1) * this.clipLimit;

    this.clipPage = page;

    // Get clips according to page number
    this.clipsToDisplay = this.clips.slice(startIndex, startIndex + this.clipLimit);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#changeState
   *  @methodOf Seed.Controllers:WatchView
   *  @param {object} params State params
   *  @param {number} params.seriesId Series ID of video to play
   *  @param {number} params.episodeNumber Episode number of video to play
   *  @param {string=} params.slug Slug of collection to display
   *  @param {number=} params.collectionPage Page the collection needs to be loaded with
   *  @description
   *   Change state to a different episode
   */
  changeState(params) {
    this.$state.go('watch.episode', params, {
      location: 'replace'
    });
  }

  changeClipState(params) {
    this.$state.go('watch.clip', params, {
      location: 'replace'
    });
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showSeriesFailure
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Redirects to 404 page.
   */
  showSeriesFailure() {

    this.$state.go('404');

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#showCollectionFailure
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Redirects to 404 page.
   */
  showCollectionFailure() {

    this.$state.go('404');

  }


  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#getNextEpisode
   *  @methodOf Seed.Controllers:WatchView
   *  @param {integer} seriesId the id of the series to fetch the recently watched episode
   *  @returns {promise} resolved promise will contain the ecently watched episode number
   *  @description
   *    Returns the next episode number to watch.
   *
   *  ***Usage***
   *  ```js
   *    getNextEpisode(1);
   *  ```
   *
   */
  getNextEpisode(seriesId) {

    var deferred = this.$q.defer();

    var success = ({episodes}) => {

      var lastWatchedDate;
      var lastWatchIndex;
      var nextEpisodeNumber;

      if (episodes.length > 0) {

        var dateArray = [];

        episodes.forEach((element) => {
          dateArray.push(this.moment(element.viewDate));
        });

        dateArray.forEach((element, index) => {

          if (!lastWatchedDate) {
            lastWatchedDate = element;
            lastWatchIndex = index;
          } else if (lastWatchedDate.isBefore(element)) {
            lastWatchedDate = element;
            lastWatchIndex = index;
          }
        });

        // find the last watched episode
        nextEpisodeNumber = episodes[lastWatchIndex].number;
      }

      return deferred.resolve(nextEpisodeNumber);
    };

    this.UserHistoryService.getSeriesHistory(seriesId).then(success).catch((err) => {
      return deferred.reject(err);
    });

    return deferred.promise;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#autoplay
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Used to autoplay through the series or collection
   */
  autoplay() {

    // during autoplay, start video from the beginning
    this.config.useHistory = false;

    this.saveOriginalEpisodes();

    if (this.getClipId()) {
      this.autoplayClips();
    } else if (this.getSlug()) {
      this.autoplayCollection();
    } else {
      this.autoplaySeries();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#autoplayClips
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Used to autoplay through clips
   */
  autoplayClips() {

    let currentClipId = this.getClipId();

    let index = 0;

    // find current clip in clips array
    // increment index by one greater since we want to find the next clip
    for (let i = 0; i < this.clips.length; i++) {
      if (currentClipId == this.clips[i].id) {
        index = i + 1;
        break;
      }
    }

    if (index < this.clips.length &&
      index !== 0) {
      this.clipPage = Math.ceil((index + 1) / this.clipLimit);
      this.changeClipPage(this.clipPage);
      this.changeClipState({
        clipId: this.clips[index].id
      });
    } else if (this.$rootScope.previousStateName) {
      this.watchEnd();
    }

  }

  /**
  *  @ngdoc method
  *  @name Seed.Controllers:WatchView#autoplaySeries
  *  @methodOf Seed.Controllers:WatchView
  *  @description
  *   Used to autoplay through a series
  */
  autoplaySeries() {

    let currentEpisodeNumber = this.getEpisodeNumber();
    //let page = this.page;

    let index = 0;

    // find current episode in autoplayEpisodes array
    // increment index by one greater since we want to find the next episode
    for (let i = 0; i < this.autoplayEpisodes.length; i++) {
      if (currentEpisodeNumber === this.autoplayEpisodes[i].number) {
        index = i + 1;
        break;
      }
    }

    // if last episode in current page, need to fetch next episode
    if (index < this.autoplayEpisodes.length &&
      index !== 0) {

      let position;
      if (this.episodeSort === 'desc') {
        position = this.autoplayEpisodes.length - index;
      } else {
        position = index + 1;
      }
      this.page = Math.ceil(position / this.loadLimit);

      this.changePage(this.page);
      this.changeState({
        seriesId: this.getSeriesId(),
        episodeNumber: this.autoplayEpisodes[index].number,
        slug: this.getSlug()
      });
    } else if (this.$rootScope.previousStateName) {
      this.watchEnd();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#autoplayCollection
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Used to autoplay through a collection
   */
  autoplayCollection() {

    let index = 0;
    let collectionPage = this.getCollectionPage();

    const success = (episodes) => {
      this.changeState({
        seriesId: episodes[index].item.seriesId,
        episodeNumber: episodes[index].item.number,
        slug: this.getSlug(),
        collectionPage: collectionPage
      });
    };

    // find current episode in episodes array
    // increment index by one greater since we want to find the next episode
    for (let i = 0; i < this.originalEpisodes.length; i++) {
      if (this.originalEpisodes[i].item.seriesId === parseInt(this.getSeriesId()) &&
        this.originalEpisodes[i].item.number === parseInt(this.getEpisodeNumber())) {
        index = i + 1;
        break;
      }
    }

    // if last episode in current page, need to fetch next episode
    if (index >= this.loadLimit &&
      collectionPage < this.pages) {

      index = 0;
      collectionPage += 1;

      const params = {
        page: collectionPage,
        slug: this.getSlug()
      };

      this.CollectionService.getItems(params)
        .then(collection => success(collection));

    } else if (collectionPage < this.pages ||
      index < this.originalEpisodes.length) {
      success(this.originalEpisodes);
    } else if (this.$rootScope.previousStateName) {
      this.watchEnd();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#setOriginalEpisodes
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Save original episodes list when page was loaded
   */
  saveOriginalEpisodes() {
    if (!this.originalEpisodes) {
      this.originalEpisodes = this.episodes;
    }
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#reloadEpisodes
   *  @methodOf Seed.Controllers:WatchView
   *  @param {number} page Current page number
   *  @description
   *   Determine if episodes should be loaded based on page number
   */
  reloadEpisodes(page) {

    if (!this.episodes || this.page !== page) {
      this.page = page;
      return true;
    } else {
      return false;
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#goToNextEpisode
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Redirect to the default/next episode to watch.
   */
  goToNextEpisode() {
    this.$state.go('watch.episode', {
      seriesId: this.getSeriesId(),
      episodeNumber: this.nextEpisodeNumber,
      slug: this.getSlug()
    }, {
      location: 'replace'
    });
    delete this.nextEpisodeNumber;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#resetUseHistory
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Resets useHistory flag in video player back to true
   */
  resetUseHistory() {
    this.config.useHistory = true;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#watchEnd
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *   Determines what should happen when the watch view should end
   */
  watchEnd() {
    this.$window.history.back();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#skipSeriesPage
   *  @methodOf Seed.Controllers:WatchView
   *  @returns {boolean} Skip series page
   *  @description
   *   Determines whether or not to skip the series page
   */
  skipSeriesPage() {
    return !this.seriesPage;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:WatchView#setPreviousAndNext
   *  @methodOf Seed.Controllers:WatchView
   *  @description
   *    Set the values for previous and next episodes.  This will be called each time the episode number changes
   */
  setPreviousAndNext() {

    let index = 1;
    let currentEpisodeNumber = this.getEpisodeNumber();
    this.previousEpisode = null;
    this.nextEpisode = null;

    // need both episode number and autoplayEpisodes array to figure out what previous/next episodes are
    if (!currentEpisodeNumber || !this.autoplayEpisodes) {
      return;
    }

    // find current episode in episodes array
    for (let i = 0; i < this.autoplayEpisodes.length; i++) {
      if (currentEpisodeNumber === this.autoplayEpisodes[i].number) {
        index = i + 1;

        // set previous episode if available
        if (i > 0) {
          this.previousEpisode = this.autoplayEpisodes[i - 1];
        }

        // set next episode if available
        if (index < this.autoplayEpisodes.length) {
          this.nextEpisode = this.autoplayEpisodes[index];
        }

        break;
      }
    }

  }

}

export default WatchViewController;
