import SwiftypeComponentController from './swiftype-component-controller';

const SwiftypeComponent = {
  bindings: {
    config: '<',
    queryString: '<',
    loading: '=',
    activeLanguage: '<'
  },
  controller: SwiftypeComponentController,
  controllerAs: 'vm',
  templateUrl: 'components/swiftype/swiftype-container.html'
};

export default SwiftypeComponent;
