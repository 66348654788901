import HomeViewController from 'views/home/home-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:BoomHomeViewController
 *  @module Boom
 *  @requires platformjs.collection
 *  @requires platformjs.user.history
 *  @requires platformjs.metadata
 *  @requires platformjs.user.account
 *  @requires platformjs.images
 *  @requires angular.$translate
 *  @description
 *    Responsible for managing the home view.
 */
class BoomHomeViewController extends HomeViewController {
  static get $inject() {
    return [
      'platformjs.collection',
      'platformjs.user.history',
      'platformjs.metadata',
      'platformjs.user.account',
      'platformjs.images',
      'platformjs.claims',
      '$translate',
      'CollectionUpsellService'
    ];
  }

  /* istanbul ignore next */
  constructor(
    CollectionService,
    UserHistoryService,
    MetaDataService,
    UserAccountService,
    ImageService,
    ClaimsService,
    $translate,
    CollectionUpsellService
  ) {

    super();
    this.CollectionService = CollectionService;
    this.UserHistoryService = UserHistoryService;
    this.MetaDataService = MetaDataService;
    this.UserAccountService = UserAccountService;
    this.ImageService = ImageService;
    this.ClaimsService = ClaimsService;
    this.$translate = $translate;
    this.CollectionUpsellService = CollectionUpsellService;

    this.errors = [];

    this.collections = [];
    this.mappedCollections = {};

    this.history = [];
    this.loading = true;

    this.carouselOptions = {
      itemsGutter: 10,
      breakpoints: {
        0: {
          itemsPerIndex: 1,
          itemsPerView: 1
        },
        640: {
          itemsPerIndex: 3,
          itemsPerView: 3
        },
        1024: {
          itemsPerIndex: 4,
          itemsPerView: 4
        }
      }
    };

    this.carouselBannerOptions = {
      autoscroll: 6000, //ms
      itemsGutter: 10,
      breakpoints: {
        0: {
          itemsPerIndex: 1,
          itemsPerView: 1
        },
        640: {
          itemsPerIndex: 1,
          itemsPerView: 1
        },
        1024: {
          itemsPerIndex: 1,
          itemsPerView: 1
        }
      }
    };
  }

  $onInit() {
    this.loadHomeCollection();
    this.initialize();
    this.CollectionUpsellService.listenForUpsellTrigger();
  }

  loadHomeCollection() {
    var config = {
      slug: 'homepage-v2'
    };
    this.ClaimsService.get().then(() => this.CollectionService.getItems(config).then(({collectionItems}) => {
      this.collections = collectionItems;
      this.loadCollections();
    })
    );

  }

  loadCollections() {
    this.collections = this.collections.map((collection) => {
      const config = {
        slug: collection.item.slug
      };
      this.mappedCollections[collection.item.slug] = collection;
      this.CollectionService.getItems(config).then(({collectionItems}) => {

        if (collection.item && collection.item.slug === 'homepage-banners') {
          this.setBanners(collection, collectionItems);
        }

        collection.items = collectionItems;
      }).finally(() => this.complete());

      return collection;
    }).filter((collection) => {
      return collection && collection.item && collection.item.slug !== 'homepage-banners';
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomHomeViewController#setBanners
   *  @methodOf Boom.Controllers:BoomHomeViewController
   *  @param {array} banner collection list
   *  @param {array} banner items list
   *  @description
   *    Sets the banner collection and banner collection items
   *    for the carousel.
   */
  setBanners(collection, collectionItems) {
    collectionItems = this._getActiveBanners(collectionItems);
    this.addBannerDataToCollectionItems(collectionItems);
    this.bannerCollection = collection;
    this.bannerCollectionItems = collectionItems;
  }

  initialize() {

    if (this.UserAccountService.isRegistered()) {
      this.UserHistoryService.recentlyWatched()
        .then((history) => this.showSuccess(history))
        .catch((errors) => this.showErrors(errors))
        .finally(() => this.complete());
    }

    this.$translate('META_TITLE').then((titleTranslate) => {
      this.$translate('META_DESCRIPTION').then((descTranslate) => {

        this.$translate('META_KEYWORDS').then((keywordTranslate) => {
          let newMetadata = {
            title: titleTranslate,
            description: descTranslate,
            keywords: keywordTranslate
          };

          this.MetaDataService.setData(newMetadata);
        });
      });
    });
  }

  showSuccess(history) {
    this.history = history.recentlyWatched;
  }

  showErrors(errors) {
    this.errors = errors;
  }

  complete() {
    this.loading = false;
  }

  displayType(collectionIndex) {
    const containerValue = this.collections[collectionIndex].item.metadata.container || '';

    return this._parseCollectionString(containerValue);
  }

  getRoute(collectionIndex) {
    const collection = this.collections[collectionIndex].item;

    return this._parseCollectionString(collection.slug);
  }

  _parseCollectionString(value) {
    switch (value) {
      case 'top-playlists':
        return 'playlists';
      case 'top-shows':
        return 'shows';
      case 'popular-movies':
        return 'movies';
      default:
        return '';
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomHomeViewController#_parseDate
   *  @methodOf Boom.Controllers:BoomHomeViewController
   *  @param {string} start date
   *  @param {string} end date
   *  @param {object} current date
   *  @description
   *    Determines whether the current date is after the start date
   *    but before the end date.
   */
  _parseDate(start, end, currentDate = new Date()) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return currentDate > startDate && currentDate < endDate;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomHomeViewController#addBannerDataToCollectionItems
   *  @methodOf Boom.Controllers:BoomHomeViewController
   *  @param {array} banner items list
   *  @description
   *    Takes in an array of banner items and determines the banner
   *    and takeover image to display based on which banner items
   *    start and end date is surrounds the current date.
   */
  addBannerDataToCollectionItems(bannerItems) {
    bannerItems.forEach((bannerItem) => {
      if (bannerItem.item) {
        const {item} = this._trimBannerURLData(bannerItem);
        if (item && item.content_type === 'episode') {
          const contentIdentifiers = item.content_id.split('.');
          bannerItem.episode = {
            seriesId: contentIdentifiers[0],
            episodeId: contentIdentifiers[1]
          };
        }

        bannerItem.takeover = this.ImageService.get({
          id: item.collectionId,
          assetType: 'banners',
          imageType: 'md'
        }).url;
      }
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomHomeViewController#_trimBannerURLData
   *  @methodOf Boom.Controllers:BoomHomeViewController
   *  @param {object} banner item
   *  @description
   *    Trims white space from data passed to ui-sref
   */
  _trimBannerURLData(banner) {

    if (banner.item && banner.item.collectionSlug) {
      banner.item.collectionSlug = banner.item.collectionSlug.trim();
    }

    if (banner.episode && banner.episode.seriesId) {
      banner.episode.seriesId = banner.episode.seriesId.trim();
    }

    if (banner.episode && banner.episode.episodeId) {
      banner.episode.episodeId = banner.episode.episodeId.trim();
    }

    return banner;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomHomeViewController#_getActiveBanners
   *  @methodOf Boom.Controllers:BoomHomeViewController
   *  @param {array} banner items list
   *  @description
   *    Filters for current banners based on their start and end dates.
   */
  _getActiveBanners(bannerItems) {
    return bannerItems.filter((bannerItem) => {
      const item = bannerItem.item;
      let shouldDisplay = true;
      if (item && item.display && item.display.indexOf('web') === -1) {
        shouldDisplay = false;
      }
      if (shouldDisplay && item && item.user_type) {
        const profileClaim = this.ClaimsService.getClaim('profile');
        shouldDisplay = false;
        if (!profileClaim) {
          shouldDisplay = item.user_type.toLowerCase() === 'anonymous';
        } else if (profileClaim.premiumStatus) {
          shouldDisplay = item.user_type === profileClaim.premiumStatus;
        }
      }
      return item && shouldDisplay && this._parseDate(item.start, item.end);
    });
  }

}

export default BoomHomeViewController;
