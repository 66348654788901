import ProductSelectionController from 'components/product-selection/product-selection-controller';

/**
*  @ngdoc controller
*  @name Boom.Controllers:BoomProductSelectionController
*  @module Boom
*  @description
*    Responsible for subscribing a user.
*/
class BoomProductSelectionController extends ProductSelectionController {
  static get $inject() {
    return [
      '$state',
      'platformjs.catalog',
      '$attrs',
      'AnalyticsService',
      'platformjs.premiumProfile',
      '$timeout'
    ];
  }

  /* istanbul ignore next */
  constructor($state, CatalogService, $attrs, AnalyticsService, PremiumProfileService, $timeout) {
    super($state, CatalogService);

    this.AnalyticsService = AnalyticsService;
    this.PremiumProfileService = PremiumProfileService;
    this.$attrs = $attrs;
    this.$timeout = $timeout;

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#$onInit
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Loads catalog.
   */
  $onInit() {
    //If the catalog is not being passed in, then make a fetch
    if (!this.$attrs.catalog) {
      const catalogPromise = this.CatalogService.list()
        .then(catalog => this.showReverseCatalog(catalog));

      if (this.allowPromocodeEntry) {
        catalogPromise.then(this.loadPremiumProfile.bind(this));
        this.showPromoCodeField = true;
      }

      catalogPromise.catch(errors => this.showFailure(errors));
    } else {
      this.onReady();
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#loadPremiumProfile
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Loads the users premium profile
   */
  loadPremiumProfile() {
    this.PremiumProfileService.get()
      .then(this.setPremiumProfile.bind(this))
      .catch(this.showFailure.bind(this));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#setPremiumProfile
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {object} profile The user's premium profile
   *  @description
   *    Exposes the user's premium profile and calls to apply any promo code if the user has one
   */
  setPremiumProfile(profile) {
    this.userPremiumProfile = profile;
    if (this.userPremiumProfile && this.userPremiumProfile.promos && this.userPremiumProfile.promos[0]) {
      //we want to delay so we make sure the current products get removed from the DOM
      //before we render the new ones, otherwise we get a flash with 4 products
      this.products = null;
      this.$timeout(() => {
        this.updateProductsWithPromo(this.userPremiumProfile.promos[0]);
      }, 500);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#$onChanges
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {object} catalog Catalog object.
   *  @description
   *    Checks for changes in the catalog and calls to massage/set if it was updated
   */
  $onChanges(changes) {
    if (changes && changes.catalog && changes.catalog.currentValue) {
      this.showReverseCatalog(changes.catalog.currentValue);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#showReverseCatalog
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {object} catalog Catalog object.
   *  @description
   *    Displays products and selects initial product.
   */
  showReverseCatalog(catalog) {

    this.showEmptyProductsMessage = !this.isPopulatedProductCatalog(catalog);

    const originalProducts = catalog.productsLookup();

    this.products = Object.keys(originalProducts).map((key) => originalProducts[key]);
    this.products = this.products.reverse();
    this.selectProduct(originalProducts[this.suggestedProductId || catalog.defaultProductId]);

    this.originalProducts = angular.copy(this.products);
    this.onReady();

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#trackEvent
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {object} product Product object.
   *  @description
   *    Logs Plan Selected event to Segment.
   */
  trackEvent(product) {
    if (this.$state.current.name !== 'subscribe') {
      const term = product.premiumLevel == 12 ? 'Annual' : 'Monthly';
      const properties = {
        plan_type: term.toLocaleLowerCase()
      };

      if (this.series && this.series.isFilm) {
        properties.referral_movie_id = this.series.id;
        properties.referral_movie_name = this.series.title;
      } else if (this.series && !this.series.isFilm) {
        properties.referral_series_id = this.series.id;
        properties.referral_series_name = this.series.title;
        properties.referral_episode_id = this.episode.number;
        properties.referral_episode_name = this.episode.title;
      }

      this.AnalyticsService.track(`Plan Selected ${term}`, properties);
    }

  }

  /**
   *  @name Boom.Controllers:BoomProductSelectionController#isPopulatedProductCatalog
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {object} catalog Catalog object.
   *  @description
   *    Determines whether the product catalog is populated.
   */
  isPopulatedProductCatalog({plans}) {
    return !!(plans && plans.length && hasProducts(plans));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#applyPromoCode
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Call PremiumProfileService.applyPromo method to apply the promocode.
   *
   */
  applyPromoCode(promo) {
    this.products = null;

    let config = {
      promoCode: promo.code
    };

    return this.PremiumProfileService.applyPromo(config)
      .then((response) => this.applySuccess(response))
      .catch((errors) => this.showFailure(errors));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#applySuccess
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {Object} response response object from PremiumProfileService.applyPromo
   *  @description
   *    Apply promocode success, redirect to the welcome view if fully comped or
   *    update the displayed products with the new information
   *
   */
  applySuccess({promos} = {}) {
    if (!promos || !promos[0]) {
      return;
    }

    // Comped Access will subscribe the user from the server side
    if (promos[0].requiresPaymentMethod === false) {
      this.$state.go('welcome');
    } else {
      this.updateProductsWithPromo(promos[0]);
    }

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#updateProductsWithPromo
   *  @param {object} promo The promo that is applied to the products
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Updates products with an applied promo code.
   *    Reset to the original products if a matching product is not found for the code
   */
  updateProductsWithPromo(promo) {
    this.appliedPromo = promo;

    this.resetOriginalProducts();
    let promoProductFound = false;
    this.products.forEach((product) => {
      //see if when the promo code is applied we need to change to use a different product
      let aliasId = null;
      if (product.descriptors.aliased_product_id) {
        aliasId = Number(product.descriptors.aliased_product_id);
      }

      if ((product.id === this.appliedPromo.productId || aliasId === this.appliedPromo.productId) &&
        product.descriptors.hidden !== 'true') {

        if (this.appliedPromo.marketingDescription) {
          product.amountOverride = this.appliedPromo.marketingDescription;
        }

        //if the product has an aliased id we should be using, change it so we submit the right id
        if (aliasId) {
          product.id = aliasId;
        }
        product.descriptors.badge = 'SALE';
        product.descriptors.nameAddon = 'SALE PRICE';
        product.hasPromo = true;
        promoProductFound = true;
        this.showPromoCodeField = false;
        this.selectProduct(product);
        this.appliedPromo.product = product;
      }
    });

    if (!promoProductFound) {
      delete this.appliedPromo;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#showFailure
   *  @param {object} errors Any encountered error
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Resets the original products and exposes the errors to the view
   */
  showFailure(errors) {
    this.resetOriginalProducts();
    /* istanbul ignore next */
    super.showFailure(errors);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#resetOriginalProducts
   *  @param {object} errors Any encountered error
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Resets any products that may have been updated with promo codes
   */
  resetOriginalProducts() {
    if (this.originalProducts) {
      this.products = angular.copy(this.originalProducts);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#clearPromoCode
   *  @param {object} errors Any encountered error
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @description
   *    Clears products of any promo codes that were applied and allows the user to enter a new one
   */
  clearPromoCode() {
    this.products = null;
    //we want to delay so we make sure the current products get removed from the DOM
    //before we render the new ones, otherwise we get a flash with 4 products
    this.$timeout(() => {
      this.resetOriginalProducts();
      this.showPromoCodeField = true;
      if (this.products && this.products[0]) {
        this.selectProduct(this.products[0]);
      }
    }, 500);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#getBadge
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @param {string} descriptor Product descriptor
   *  @returns {array} parsed string
   *  @description
   *    Parses descriptor for badge and savings
   */
  getBadge(descriptor) {
    return descriptor.split(" ");
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:BoomProductSelectionController#getProductTerms
   *  @methodOf Boom.Controllers:BoomProductSelectionController
   *  @returns {string} Product Terms
   *  @description
   *    Returns string for premiumLevel
   */
  getProductTerms(premiumLevel) {
    return (premiumLevel == 12) ? '1 Year' : 'Monthly';
  }

}

export default BoomProductSelectionController;

function hasProducts(plans) {
  const filteredProducts = plans.filter(({products}) => {
    return products && products.length;
  });

  return filteredProducts.length > 0;
}
