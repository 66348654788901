function HomeRedirectState($urlRouterProvider) {

  $urlRouterProvider.when(/^\/HBDVD\/?$/i, ['$state', 'AnalyticsService', function($state, AnalyticsService) {
    AnalyticsService.page('/HBDVD');
    $state.go('home');
  }]);

}

HomeRedirectState.$inject = ['$urlRouterProvider'];

export default HomeRedirectState;
