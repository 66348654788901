import BoomHomeViewController from 'views/home/boom-home-view-controller';
import DefaultRedirect from './default-redirect';

function HomeState($stateProvider, $urlRouterProvider) {
  $stateProvider.state('home', {
    parent: 'language',
    url: '',
    controller: BoomHomeViewController,
    controllerAs: 'vm',
    templateUrl: 'views/home/home-view.html',
    segment: {
      name: 'Homepage'
    }
  });

  $urlRouterProvider.otherwise(DefaultRedirect);
}

HomeState.$inject = ['$stateProvider', '$urlRouterProvider'];

export default HomeState;
