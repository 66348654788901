import PlaylistsViewController from './playlists-view-controller';

function PlaylistsState($stateProvider) {

  $stateProvider.state('playlists', {
    parent: 'language',
    url: '/playlists?page',
    controller: PlaylistsViewController,
    controllerAs: 'vm',
    templateUrl: 'views/playlists/playlists-view.html',
    segment: {
      name: 'All Playlists Page'
    }
  });

}

PlaylistsState.$inject = ['$stateProvider'];

export default PlaylistsState;
