/**
 *  @ngdoc controller
 *  @name Boom.Analytics:AnalyticsService
 *  @module Boom
 *  @requires $transitions
 *  @requires $state
 *  @requires segment analytics library
 *  @description
 *    Responsible making explicit analytics calls and automatic analytics calls.
 *    This code is mostly a wrapper for the segment library, but it also provides some state parsing.
 */
class AnalyticsService {
  static get $inject() {
    return [
      '$transitions',
      '$rootScope',
      '$stateParams',
      'platformjs.user',
      'platformjs.collection',
      'platformjs.claims',
      'platformjs.episode',
      'platformjs.series',
      'platformjs.subscriptionsV2',
      '$q',
      '$window',
      '$location'
    ];
  }

  constructor(
    $transitions,
    $rootScope,
    $stateParams,
    UserService,
    CollectionService,
    ClaimsService,
    EpisodeService,
    SeriesService,
    SubscriptionsServiceV2,
    $q,
    $window,
    $location
  ) {
    this.transitions = $transitions;
    this.$rootScope = $rootScope;
    this.$stateParams = $stateParams;
    this.UserService = UserService;
    this.CollectionService = CollectionService;
    this.ClaimsService = ClaimsService;
    this.EpisodeService = EpisodeService;
    this.SeriesService = SeriesService;
    this.SubscriptionsServiceV2 = SubscriptionsServiceV2;
    this.$q = $q;
    this.$window = $window;
    this.$location = $location;
    this.analyticsIsDefined = !!this.$window.analytics;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#checkState
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @param {object} transition the ui router transition object
   *  @description
   *    parses the state definition and preforms analytics that are required.
   *    Analytics spec document {@link https://docs.google.com/spreadsheets/d/1wep3T6JFapXIBNFE64wRbaA1RgWQWCERVDtGu0a9Eqg/edit#gid=1332922012 here}
   */
  checkState(transition) {
    const to = transition.to();
    const statesToIgnore = ['terms', 'privacy'];

    if (statesToIgnore.includes(to.name)) {
      return;
    }
    const path = stripParams(transition.to().url);
    const fromUrl = stripParams(transition.from().url);
    const protocol = this.$location.protocol();
    const host = this.$location.host();
    const url = `${protocol}://${host}${stripParams(path)}`;
    const referrer = fromUrl === '^' ? `${protocol}://${host}` : `${protocol}://${host}${fromUrl}`;
    let name = to.segment && to.segment.name ? to.segment.name : '';
    let config = {
      url,
      path,
      referrer
    };

    if (to.segment && to.segment.doNotReport) {
      return;
    }

    switch (to.name) {
      case 'gifting':
        name = 'Gift Landing';
        this.page(name, config);
        break;
      case 'welcome':
        name = 'Subscription Welcome Page';
        this.page(name, config);
        break;
      case 'login':
        name = 'Login Page';
        this.page(name, config);
        break;
      case 'subscribe':
        this.identify();
        this.page(name, config);
        break;
      case 'collections':
        this.CollectionService.get({
          slug: transition.params().slug
        })
          .then((collection) => {
            this.page('Playlist Page', {
              playlist_id: collection.id,
              playlist_name: collection.title
            });
          });
        break;
      case 'franchise':
        //do nothing here
        break;
      case 'franchise.subcollection':
        this.CollectionService.get({
          slug: transition.params().slug
        })
          .then((collection) => {
            this.page('Show Page', {
              filter_type: transition.params().collectionType,
              show_name: collection.title,
              show_id: collection.id
            });
          });
        break;
      case 'watch':
        this.SeriesService.get(transition.params().seriesId)
          .then((series) => {
            if (series.isFilm) {
              this.page('Movie Page', {
                movie_name: series.title,
                movie_id: series.id
              });
            } else {
              this.page('Series Page', {
                series_id: series.id,
                series_name: series.title
              });
            }
          });
        break;
      case 'watch.episode':
        this.$q.all([
          this.SeriesService.get(transition.params().seriesId),
          this.EpisodeService.get(transition.params().seriesId, transition.params().episodeNumber, false)
        ]).then((data) => {
          const [series, episode] = data;
          if (series.isFilm) {
            this.page('Movie View Page', {
              movie_name: series.title,
              movie_id: series.id
            });
          } else {
            this.page('Episode View Page', {
              episode_name: episode.title,
              episode_id: episode.number,
              series_id: series.id,
              series_name: series.title
            });
          }
        });
        break;
      default:
        //If something isn't categorized, just log it as the url
        if (name === '') {
          name = this.$window.location.pathname;
        }
        this.page(name, config);
    }

  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#addTransitionHook
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @description
   *    Adds checkState to the `onFinish` ui-router hook
   */
  addTransitionHook() {
    this.transitions.onFinish({}, (transition) => this.checkState(transition));
  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#page
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @param {string} name the name/title of the page
   *  @param {object} properties the additional properties to pass to segment
   *  @description
   *    Wraps segment `page` call
   */
  page(name, properties) {
    if (this.analyticsIsDefined) {
      this.$window.analytics.page(name, properties);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#identify
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @description
   *    Wraps segment `identify` call.  Will only make call if user is logged in.
   */
  identify() {
    const deferred = this.$q.defer();

    if (this.analyticsIsDefined) {
      const premiumClaim = this.ClaimsService.getClaim('premium');

      if (this.UserService.user.guid && premiumClaim) {
        const traits = {
          App_Language: this.UserService.profile.language
        }

        this.checkSubscriberStatus();

        if (this.subscriberStatus) {
          traits.Subscriber_State = this.subscriberStatus.state,
          traits.Last_Pay_Date = this.subscriberStatus.lastPayDate,
          traits.Product_Id = this.subscriberStatus.id
        }

        if (
          premiumClaim.dsrRequestDoNotSell ||
          premiumClaim.userRequestDoNotSell
        ) {
          traits.do_not_sell = 1;
        } else {
          traits.do_not_sell = 0;
        }

        this.$window.analytics.identify(this.UserService.user.guid, traits);
        deferred.resolve();
      }
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#track
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @param {string} name the name/title of the event
   *  @param {object} properties the additional properties to pass to segment
   *  @description
   *    Wraps segment `page` call
   */
  track(name, properties) {
    if (this.analyticsIsDefined) {
      this.$window.analytics.track(name, properties);
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#bindToGateEvents
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @description
   *    Binds analytics calls to age/baby gate events.
   */
  bindToGateEvents() {

    //Generic method to process Gate events
    const processEvent = (event, eventParams = {}) => {
      const params = eventParams.params;
      const age = eventParams.age;
      let eventType = event.name.match('agegate') ? 'Age' : 'Baby';
      let eventTerm = '';
      if (params) {
        if (params.premiumLevel == 1) {
          eventTerm = ' Monthly';
        } else if (params.premiumLevel == 12) {
          eventTerm = ' Annual';
        }
      }
      let eventName = `${eventType} Gate Submitted${eventTerm}`;
      const eventConfig = {
        success: /success/.test(event.name)
      };
      if (age) {
        eventConfig.age = age;
      }
      this.track(eventName, eventConfig)
    };
    return [
      this.$rootScope.$on('agegate-success', processEvent),
      this.$rootScope.$on('agegate-failed', processEvent),
      this.$rootScope.$on('babygate-success', processEvent),
      this.$rootScope.$on('babygate-failed', processEvent)
    ];

  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#checkSubscriberStatus
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @description
   *    Returns subscriber status for the identify call
   */
  checkSubscriberStatus() {
    this.SubscriptionsServiceV2.list()
      .then(this.setSubscriberStatus.bind(this))
      .catch((errors) => this.errors = errors);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Analytics:AnalyticsService#setSubscriberStatus
   *  @methodOf Boom.Analytics:AnalyticsService
   *  @description
   *    Sets subscriber status from return object
   */
  setSubscriberStatus(allSubscriptions) {
    let status = {
      state: allSubscriptions[0].premium.state,
      lastPayDate: allSubscriptions[0].premium.lastPayDate,
      id: allSubscriptions[0].premium.id
    }
    this.subscriberStatus = status;
  }
}

export default AnalyticsService;

function stripParams(path) {
  return path ? path.replace(/\?[^=]*$/, '') : '';
}
