import CollectionViewController from 'views/collection/collection-view-controller';

/**
 *  @ngdoc controller
 *  @name Boom.Controllers:ShowsView
 *  @module Boom
 *  @requires angular.$state
 *  @requires angular.$stateParams
 *  @requires platformjs.collection
 *  @requires ProgressBarService
 *  @requires FranchiseFactory
 *  @description
 *    Responsible for displaying show information.
 */
class ShowsViewController extends CollectionViewController {

  static get $inject() {
    /* istanbul ignore next */
    return [
      '$stateParams',
      '$state',
      'platformjs.collection',
      'ProgressBarService',
      'platformjs.metadata',
      'CollectionUpsellService'
    ];
  }

  /* istanbul ignore next */
  constructor(
    $stateParams,
    $state,
    CollectionService,
    ProgressBarService,
    MetaDataService,
    CollectionUpsellService
  ) {
    super($stateParams, $state, CollectionService, ProgressBarService);

    this.MetaDataService = MetaDataService;
    this.CollectionUpsellService = CollectionUpsellService;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ShowsView#$onInit
   *  @methodOf Boom.Controllers:ShowsView
   *  @description
   *    Makes initial request.
   */
  $onInit() {
    this.page = this.$stateParams.page || 1;
    this.loading = true;
    this.ProgressBarService.start();
    this.loadContent();
    this.MetaDataService.setData({
      title: 'All Shows | Boomerang.com'
    });
    this.CollectionUpsellService.listenForUpsellTrigger();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ShowsView#$loadContent
   *  @methodOf Boom.Controllers:ShowsView
   *  @description
   *    Requests franchise list.
   */
  loadContent() {

    this.loading = true;

    let config = {
      id: 'c195',
      slug: 'all-shows',
      page: this.page
    };

    this.CollectionService.getItems(config)
      .then((response) => this.showLoadSuccess(response))
      .catch((error) => this.showLoadErrors(error))
      .finally(() => this.showLoadComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ShowsView#$showLoadSuccess
   *  @methodOf Boom.Controllers:ShowsView
   *  @description
   *    Assign success response to the view.
   */
  showLoadSuccess(response) {
    this.shows = response.collectionItems;
    this.page = response.page;
    this.pages = response.numberOfPages;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ShowsView#$showLoadErrors
   *  @methodOf Boom.Controllers:ShowsView
   *  @description
   *    Assign error response to the view.
   */
  showLoadErrors(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ShowsView#$showLoadComplete
   *  @methodOf Boom.Controllers:ShowsView
   *  @description
   *    Requests complete, render on the page.
   */
  showLoadComplete() {
    this.loading = false;
    this.ProgressBarService.complete();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:ShowsView#$changePage
   *  @methodOf Boom.Controllers:ShowsView
   *  @description
   *    Requests the content by specified page number.
   */
  changePage(page) {
    this.page = page;
    this.loadContent();
  }
}

export default ShowsViewController;
