/**
 *  @ngdoc controller
 *  @name Boom.Controllers:SwiftypeComponent
 *  @module Boom
 *  @description
 *    Responsible for Swiftype search
 */
class SwiftypeComponentController {

  static get $inject() {
    return ['$q', 'SwiftypeService', 'platformjs.images'];
  }

  constructor($q, SwiftypeService, ImagesService) {
    this.$q = $q;
    this.SwiftypeService = SwiftypeService;
    this.ImagesService = ImagesService;

    /**
     *  @ngdoc property
     *  @name queryString
     *  @type String
     *  @propertyOf Boom.Controllers:SwiftypeComponent
     *  @description
     *    The string to search
     */
    this.queryString = this.queryString || '';

    /**
     *  @ngdoc property
     *  @name config
     *  @type Object
     *  @propertyOf Boom.Controllers:SwiftypeComponent
     *  @description
     *    The configuration object for the controller logic
     */
    this.config = this.config || {};

    /**
     *  @ngdoc property
     *  @name results
     *  @type Array
     *  @propertyOf Boom.Controllers:SwiftypeComponent
     *  @description
     *    To store/display the search results
     */
    this.results = [];

    /**
     *  @ngdoc property
     *  @name loading
     *  @type Boolean
     *  @propertyOf Boom.Controllers:SwiftypeComponent
     *  @description
     *    To perform the loading states
     */
    this.loading = this.loading || false;

    /**
     *  @ngdoc property
     *  @name targetIndex
     *  @type Number
     *  @propertyOf Boom.Controllers:SwiftypeComponent
     *  @description
     *    To store which document is the target when operating the pagination
     */
    this.targetIndex = undefined;

    /**
     *  @ngdoc property
     *  @name activeIndex
     *  @type Number
     *  @propertyOf Boom.Controllers:SwiftypeComponent
     *  @description
     *    For showing/hiding search results in the template, default to 0
     */
    this.activeIndex = 0;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#onInit
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @description
   *    Initialize
   */
  $onInit() {
    // execute search if query string meets the criteria
    if (this.queryString.length > this.config.min_length) {
      this.search();
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#search
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @description
   *    Makes Swiftype search queries based on document types
   */
  search() {
    this.loading = true;
    this.config.q = this.queryString;

    // construct an array of promises for each document type
    // and resolve them altogether
    const promises = this.config.document_types.map(element => {
      const param = this.buildSearchParam(element);
      return this.SwiftypeService.query(this.localizeSwifttypeQuery(param));
    });

    this.$q.all(promises)
      .then(response => this.searchSuccess(response))
      .catch(errors => this.showErrors(errors))
      .finally(() => this.searchComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#buildSearchParam
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {string} documentType Document Type
   *  @returns {Object} param Query paramaters
   *  @description
   *    Construct and return a query param object based on different document types
   */
  buildSearchParam(documentType) {
    const param = angular.copy(this.config);

    // rebuild the filter based on different document types
    let filters = {};
    if (this.config.filters && this.config.filters[documentType]) {
      filters[this.convertDocumentType(documentType)] = this.config.filters[documentType];
    }
    param.filters = filters;

    // rebuild document type
    param.document_types = [this.convertDocumentType(documentType)];

    // delete invalid options
    if (param.min_length) {
      delete param.min_length;
    }

    return param;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#searchSuccess
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Object} object Promise object
   *  @description
   *    Success callback of search, assign search results to the view
   */
  searchSuccess(response) {
    let results = [];

    results = response.map(promise => {
      this.delocalizeResponse(promise.data);
      return this.attachThumbnailInfo(promise.data);
    });

    this.results = results;

    this.checkActiveIndex();
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#delocalizeResponse
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Object} object Promise object
   *  @description
   *    Reverts the new type to old type in records.
   */
  delocalizeResponse(data) {
    const fields = ['records', 'info'];
    fields.forEach(field => {
      Object.keys(data[field]).forEach(type => {
        const newType = type.split('-')[0];
        if (newType !== type) {
          data[field][newType] = data[field][type];
          delete data[field][type];
        }
      });
    });
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#checkActiveIndex
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @description
   *    Get the activeIndex based on the search result
   */
  checkActiveIndex() {
    let displayIndex = 0;

    for (let i = 0; i < this.results.length; i++) {
      if (this.results[i].record_count > 0) {
        displayIndex = i;
        break;
      }
    }

    this.activateDocument(displayIndex);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#attachThumbnailInfo
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Object} data the search results data
   *  @returns {Object} data the modified search results data
   *  @description
   *    Modifiy the search results data with thumbnail rendering info and return it.
   */
  attachThumbnailInfo(data) {
    data.document_type = this.getDocumentType(data);

    // attach the document type and thumbnail URLs for rendering thumbnails
    data.records[Object.keys(data.records)[0]] = data.records[Object.keys(data.records)[0]].map(item => {

      if (data.document_type === 'episode') {
        const ids = item.external_id.split('.');
        item.external_id = item.seriesId = +ids[0];
        item.number = +ids[1];
      }

      const config = {
        id: item.external_id,
        assetType: (data.document_type === 'movies' ? 'series' : data.document_type),
        seriesId: item.seriesId,
        episodeNumber: item.number
      };

      item.images = this.ImagesService.getAll(config);
      item.thumbnail_type = data.document_type;
      return item;
    });

    return data;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#getDocumentType
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Object} data the search results data
   *  @returns {string} A UI document type
   *  @description
   *    Returns frontend's document type based on the data.records
   */
  getDocumentType(data) {
    const key = Object.keys(data.records)[0];
    if (key === 'series' && data.records[key].length > 0) {
      return data.records[key][0].is_episodic === 'True' ? key : 'movies';
    } else {
      return key;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#convertDocumentType
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {string} type Document Type
   *  @returns {string} Document type
   *  @description
   *    Returns backend's document type
   */
  convertDocumentType(type) {
    if (type === 'movies') {
      return 'series';
    } else {
      return type;
    }
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#changePage
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Number} page The page number to change
   *  @param {Number} index The index of the current document type
   *  @description
   *    Makes a swiftype search based on the requested page number.
   */
  changePage(page, index) {
    this.targetIndex = index;
    this.loading = true;

    const param = this.buildSearchParam(this.config.document_types[index]);


    param.page = page;

    this.SwiftypeService.query(this.localizeSwifttypeQuery(param))
      .then((response) => this.changePageSuccess(response))
      .catch(errors => this.showErrors(errors))
      .finally(() => this.searchComplete());
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#changePageSuccess
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Object} object Promise object
   *  @description
   *    Success callback of changePage method, replace the targeting search result.
   */
  changePageSuccess(promise) {

    this.delocalizeResponse(promise.data);
    this.results[this.targetIndex] = this.attachThumbnailInfo(promise.data);

  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#activateDocument
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Number} index Index number
   *  @description
   *    Update the "activeIndex" value
   */
  activateDocument(index) {
    this.activeIndex = index;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#totalResultCount
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @returns {Number} The total result count
   *  @description
   *    Returns the total result count from the scope results
   */
  totalResultCount() {
    const resultCount = this.results.map(result => {
      const key = Object.keys(result.info)[0];
      return result.info[key].total_result_count;
    });

    return resultCount.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#reset
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @description
   *    Reset the search result
   */
  reset() {
    this.results = [];
    this.loading = false;
    this.noResult = undefined;
    this.activateDocument(0);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#showErrors
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @description
   *    Display the search errors
   */
  showErrors(errors) {
    this.errors = errors;
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#searchComplete
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @description
   *    Complete callback of search method
   */
  searchComplete() {
    this.loading = false;
    this.targetIndex = undefined;

    // display no search result UI
    this.noResult = (this.totalResultCount() === 0);
  }

  /**
   *  @ngdoc method
   *  @name Boom.Controllers:SwiftypeComponent#formatNoun
   *  @methodOf Boom.Controllers:SwiftypeComponent
   *  @param {Number} The index of the object within the results
   *  @param {string} The document type
   *  @returns {string} The document type formatted to display in the UI
   *  @description
   *    Returns a singular noun for document types with a single result and a plural noun when there are multiple results
   */
  formatNoun(index, document) {
    const total = this.results[index].info[this.convertDocumentType(document)].total_result_count;
    if (this.activeLanguage === 'es') {
      if (total === 1) {
        return document === 'episode' ? 'episodio' : 'película';
      } else {
        return document === 'episode' ? 'episodios' : 'películas';
      }
    } else {
      if (total === 1) {
        return document === 'episode' ? 'episode' : 'movie';
      } else {
        return document === 'episode' ? 'episodes' : 'movies';
      }
    }
  }

  /**
     *  @ngdoc method
     *  @name Boom.Controllers:SwiftypeComponent#localizeSwifttypeQuery
     *  @methodOf Boom.Controllers:SwiftypeComponent
     *  @param {object} swifttype configuration
     *  @returns {object} The swifttype configuration object.
     *  @description
     *    Returns swifttypeConfig object with updated document type and filter when active langauage is not English.
     */
  localizeSwifttypeQuery(swifttypeConfig) {
    if (this.activeLanguage && this.activeLanguage != 'en') {
      swifttypeConfig.document_types = swifttypeConfig.document_types.map(type => {
        return `${type}-${this.activeLanguage}`;
      });
      Object.keys(swifttypeConfig.filters).forEach(type => {
        swifttypeConfig.filters[`${type}-${this.activeLanguage}`] = swifttypeConfig.filters[type];
        delete swifttypeConfig.filters[type];
      });
    }
    return swifttypeConfig;
  }
}

export default SwiftypeComponentController;
