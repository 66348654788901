import { ResizeTimeout } from 'utilities/resize-timeout/resize-timeout';

/**
 *  @ngdoc controller
 *  @name Seed.Controllers:Carousel
 *  @module Seed
 *  @requires $window
 *  @requires $timeout
 *  @requires $element
 *  @requires $rootScope
 *  @description
 *    Responsible for managing carousel methods and carousel item population
 */
class CarouselController {
  static get $inject() {
    return [
      '$window',
      '$timeout',
      '$element',
      '$rootScope'
    ];
  }

  constructor($window, $timeout, $element, $rootScope) {
    this.$window = $window;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;

    this.defaults = {
      itemsPerIndex: 1,
      itemsPerView: 1,
      itemsGutter: 2,
      breakpoints: {
        0: {
          itemsPerIndex: 1,
          itemsPerView: 1
        },
        640: {
          itemsPerIndex: 3,
          itemsPerView: 3
        },
        1024: {
          itemsPerIndex: 4,
          itemsPerView: 4
        },
        1440: {
          itemsPerIndex: 5,
          itemsPerView: 5
        },
        1920: {
          itemsPerIndex: 6,
          itemsPerView: 6
        }
      }
    };

    /**
     *  @ngdoc property
     *  @name element
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {object} Carousel element.
     */
    this.element = $element;

    /**
     *  @ngdoc property
     *  @name index
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {number} Current slide index.
     */
    this.index = 0;

    /**
     *  @ngdoc property
     *  @name indexLimit
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {number} Max value of index
     */
    this.indexLimit = 0;

    /**
     *  @ngdoc property
     *  @name isMax
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {boolean} Returns `true` if index is equal to 0.
     */
    this.isMax = false;

    /**
     *  @ngdoc property
     *  @name isMin
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {boolean} Returns `true` if index is equal to indexLimit.
     */
    this.isMin = false;

    /**
     *  @ngdoc property
     *  @name items
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {object} An array containing all items for the slider.
     */
    this.items = this.items || [];

    /**
     *  @ngdoc property
     *  @name settings
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {object} An object containing all settings for the slider.
     */
    this.settings = {};

    /**
     *  @ngdoc property
     *  @name settingsActive
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {object} An object containing the current settings for the slider based off the viewport.
     */
    this.settingsActive = {};

    /**
     *  @ngdoc property
     *  @name itemWidth
     *  @propertyOf Seed.Controllers:Carousel
     *  @description
     *  @returns {number} Calculated carousel item width (in pixels).
     */
    this.itemWidth = 0;
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#addItem
   *  @param {object} item A carousel item object.
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Adds an item to {@link Seed.Controllers:Carousel#properties_items items} property.
   *
   */
  addItem(item) {

    this.items.push(item);

    this.refresh();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#onInit
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Initializes the carousel directive using passed value as {@link Seed.Controllers:Carousel#properties_element element} property.
   *
   */
  $onInit() {
    //need to save a reference to the anonymous function so it can be unbound
    this.boundResize = () => {
      this.onWindowResize();
    }
    ResizeTimeout.addListener(this.boundResize);

    this.settings = angular.extend({}, this.defaults, this.options);

    this.resize(this.$window.innerWidth);

    if (this.options && this.options.autoscroll) {
      this.autoscrollEnabled = true;
      this.setAutoscrollTimeout();
    }

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#onChanges
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Refresh the carousel if totally item count is changed.
   *
   */
  $onChanges(changeObject) {
    if (changeObject.items) {
      this.refresh();
    }
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#$onDestroy
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Remove the event binding on controller destruction
   *
   */
  $onDestroy() {
    ResizeTimeout.removeListener(this.boundResize);
    this.clearAutoscrollTimeout();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#next
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Increments {@link Seed.Controllers:Carousel#properties_index index} to next highest possible value.
   *
   */
  next() {

    if (this.index === this.indexLimit) {
      return false;
    }

    this.index = (this.index < this.indexLimit - 1) ? this.index + 1 : this.indexLimit;

    this.updateBounds();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#prev
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Decrements {@link Seed.Controllers:Carousel#properties_index index} to next lowest possible value.
   *
   */
  prev() {

    if (this.index === 0) {
      return false;
    }

    this.index = (this.index % 1) ? Math.floor(this.index) : this.index - 1;

    this.updateBounds();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#refresh
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Recalculates {@link Seed.Controllers:Carousel#properties_itemWidth itemWidth} and {@link Seed.Controllers:Carousel#properties_indexLimit indexLimit} based off of current settings.
   *
   */
  refresh() {

    if (!this.element) {
      return false;
    }

    this.itemWidth = (this.element[0].children[0].clientWidth - (this.settingsActive.itemsGutter * (this.settingsActive.itemsPerView - 1))) / this.settingsActive.itemsPerView;
    this.indexLimit = Math.max(0, (this.items.length - this.settingsActive.itemsPerView)) / this.settingsActive.itemsPerIndex;

    if (this.index > this.indexLimit) {
      this.index = this.indexLimit;
    }

    this.updateBounds();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#removeItem
   *  @param {object} item A item object.
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Removes a item from {@link Seed.Controllers:Carousel#properties_items items} property.
   *
   */
  removeItem(item) {

    for (var i = 0; i < this.items.length; i++) {
      if (item === this.items[i]) {
        this.items.splice(i, 1);
        break;
      }
    }

    this.refresh();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#resize
   *  @methodOf Seed.Controllers:Carousel
   *  @param {number} windowWidth Width of window.
   *  @description
   *
   *    Assigns appropriate settings based off of window width to {@link Seed.Controllers:Carousel#properties_settingsActive settingsActive} property.
   *
   */
  resize(windowWidth) {
    var settings = {
      itemsPerIndex: this.settings.itemsPerIndex,
      itemsPerView: this.settings.itemsPerView,
      itemsGutter: this.settings.itemsGutter
    };

    for (var breakpoint in this.settings.breakpoints) {

      if (breakpoint > windowWidth) {
        break;
      }

      settings = angular.extend(settings, this.settings.breakpoints[breakpoint]);

    }

    this.settingsActive = settings;

    this.refresh();

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#updateBounds
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Sets {@link Seed.Controllers:Carousel#properties_isMin isMin} and {@link Seed.Controllers:Carousel#properties_isMax isMax} properties to their correct current value.
   *
   */
  updateBounds() {
    this.isMin = (this.index === 0);
    this.isMax = (this.index === this.indexLimit);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#itemStyles
   *  @methodOf Seed.Controllers:Carousel
   *  @returns {object} Object containing styles for carousel item.
   *  @description
   *
   *    Gets style object for carousel item width and position based off of passed carousel value.
   *
   */
  itemStyles() {
    var styles = {};

    if (this.settingsActive.itemsGutter) {

      styles.width = this.itemWidth + 'px';
      styles.marginRight = this.settingsActive.itemsGutter + 'px';

    }

    return styles;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#wrapperStyles
   *  @methodOf Seed.Controllers:Carousel
   *  @returns {object} Object containing styles for slider wrapper.
   *  @description
   *
   *    Gets style object for slider wrapper position.
   *
   */
  wrapperStyles() {

    var styles = {};

    if (this.settingsActive.itemsPerIndex && this.settingsActive.itemsGutter) {

      var translateX = this.index * this.settingsActive.itemsPerIndex * (this.itemWidth + this.settingsActive.itemsGutter);
      var transform = `translate3d(-${translateX}px, 0, 0)`;

      styles.transform = transform;

    }

    return styles;

  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#onWindowResize
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *
   *    Detects window resize.
   *
   */
  onWindowResize() {
    this.resize(this.$window.innerWidth);
    //AngularJS doesn't trigger the digest cycle automatically b/c of the external event.
    this.$rootScope.$apply();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#goToPage
   *  @methodOf Seed.Controllers:Carousel
   *  @param {number} page page index number
   *  @description
   *    Goes to page based on its index number, which starts from 0.
   *
   */
  goToPage(page) {
    // to keep next/previous buttons in sync with pagination
    this.page = page;

    if (page > this.indexLimit) {
      this.index = this.indexLimit;
    } else {
      this.index = page;
    }

    this.updateBounds();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#setAutoscrollTimeout
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *    Sets the timer to autoscroll the carousel.
   *    Will use options.autoscroll for delay length (in ms)
   *
   */
  setAutoscrollTimeout() {
    this.autoscrollTimeout = this.$timeout(() => this.autoscroll(), this.options.autoscroll);
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#setAutoscrollTimeout
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *    Removes the autoscroll timer if one is set
   *
   */
  clearAutoscrollTimeout() {
    if (this.autoscrollTimeout) {
      this.$timeout.cancel(this.autoscrollTimeout);
      delete this.autoscrollTimeout;
    }
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#autoscroll
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *    Scrolls to the next slide.  If at the end, wraps to the beginning.
   *    Then sets up the next scroll timeout.  Will stop if autoscrollEnabled is not true.
   *
   */
  autoscroll() {
    if (!this.autoscrollEnabled) {
      this.clearAutoscrollTimeout();
      return;
    }
    if (Math.ceil(this.index) === this.indexLimit) {
      this.goToPage(0);
    } else {
      this.next();
    }
    this.setAutoscrollTimeout();
  }

  /**
   *  @ngdoc method
   *  @name Seed.Controllers:Carousel#disableAutoscroll
   *  @methodOf Seed.Controllers:Carousel
   *  @description
   *    Sets autoscrollEnabled to false to disable autoscroll behavior.
   *
   */
  disableAutoscroll() {
    this.autoscrollEnabled = false;
    this.clearAutoscrollTimeout();
  }

}

export default CarouselController;

