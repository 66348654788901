import VolumeDropdownController from './volume-dropdown-controller';

const VolumeDropdownComponent = {
  controllerAs: 'vm',
  controller: VolumeDropdownController,
  bindings: {
    seasons: '<',
    onSeasonChange: '&',
    activeSeasonNumber: '<',
    stateInfo: '<'
  },
  templateUrl: 'components/volume-dropdown/volume-dropdown.html'
};

export default VolumeDropdownComponent;
